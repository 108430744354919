import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';

export default {
  updateData: {
    name: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      required,
    },
    configuration: {
      serverKey: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      serviceAccount: {
        minLength: minLength(1),
        required,
      },
    },
    uiConfiguration: {
      apiKey: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      projectId: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      authDomain: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      storageBucket: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      messagingSenderId: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
    },
  },
};

<template>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <a class="truncate-id" :style="additionalStyles" style="text-decoration: underline; color: #000;" v-clipboard:copy="text"
           v-clipboard:success="clipboardCopiedSuccess" v-bind="attrs" v-on="on">
          {{ text }}
        </a>
      </template>
     {{ text }}
    </v-tooltip>
</template>

<script>
export default {
  name: 'StringCopyTooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    truncate: {
      type: Boolean,
      required: false,
    },
    additionalStyles: {
      type: Object,
      required: false,
    },
  },
  methods: {
    clipboardCopiedSuccess() {
      this.$store.dispatch(
        'globalMessages/addSuccess',
        { message: 'Copied to clipboard' },
      );
    },
  },
};
</script>

<style scoped>

</style>

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';

const defaultState = {
  onboardingApiServiceClient: null,
};

const getters = {
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    // eslint-disable-next-line no-unused-vars
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async getGlobalAppConfig(context) {
    try {
      const { data: config } = await context.getters.onboardingApiServiceClient(context)
        .get('/configurations/global-app-config');
      return config;
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const e = error.response.data.errors[0];
        if (e.code === 'CUSTOMER-CONFIGURATION-SERVICE-18') {
          return null;
        }
      }
      await context.dispatch(
        'globalMessages/addError',
        { message: 'common.failedToLoadPage', error },
        { root: true },
      );
      throw error;
    }
  },

  async updateGlobalAppConfig(context, payload) {
    try {
      const data = await context.getters.onboardingApiServiceClient(context)
        .put('/configurations/global-app-config', payload);

      await context.dispatch(
        'globalMessages/addSuccess',
        { message: 'common.changesSaved' },
        { root: true },
      );

      return data;
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'common.changesFailedToSave', error },
        { root: true },
      );

      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
};

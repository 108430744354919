<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :multi-sort="false"
      :items-per-page="10"
      :footer-props="{showFirstLastPage: true}"
      sort-by="startDate"
      :sort-desc="true"
      class="ic-table elevation-1 ic-table--heavy-headers"
      show-expand
      single-expand
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="space-between">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-btn
                  style="margin-right: 1rem;"
                  color="success"
                  class="header-list-button"
                  @click="createClick"
                >
                  <span class="hidden-sm-and-down">Add Financial Setting</span>
                  <v-icon class="hidden-md-and-up">$icons_system_plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:item.startDate="{ item }">
        <span>{{ formatDate(item.startDate) }}</span>
      </template>
      <template v-slot:item.endDate="{ item }">
        <span>{{ formatDate(item.endDate) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0" style="background: #EFEFEF;">
          <div class="ma-8">
            <v-data-table
              :headers="bookingFeesHeaders"
              :items="item.fees.booking"
              hide-default-footer
              :multi-sort="false"
              :must-sort="true"
              :footer-props="{ showFirstLastPage: true }"
            >
            </v-data-table>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs';

export default {
  name: 'FleetToNetworkFinancialSettingsList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    viewClick(fleet) {
      this.$emit('viewClick', fleet);
    },
    async load() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$emit('loadData', {
        page: page - 1,
        maxPerPage: itemsPerPage,
      });
    },
    formatDate(date) {
      if (!date) return null;
      return dayjs(date).utc().format('YYYY-MM-DD HH:mm');
    },
    createClick() {
      this.$emit('createClick');
    },
  },
  data() {
    return {
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('financialSettings.uid'),
          align: 'left',
          sortable: true,
          value: 'uid',
        },
        {
          text: this.$t('financialSettings.currency'),
          align: 'left',
          sortable: true,
          value: 'currency',
        },
        {
          text: this.$t('financialSettings.startDate'),
          align: 'left',
          sortable: true,
          value: 'startDate',
        },
        {
          text: this.$t('financialSettings.endDate'),
          align: 'left',
          sortable: true,
          value: 'endDate',
        },
        {
          text: this.$t('financialSettings.businessBookingAmount'),
          align: 'left',
          sortable: true,
          value: 'fees.businessBookingAmount',
        },
        { text: '', value: 'data-table-expand' },
      ];
    },
    bookingFeesHeaders() {
      return [
        {
          text: this.$t('financialSettings.from'),
          align: 'left',
          sortable: true,
          value: 'minimumCount',
        },
        {
          text: this.$t('financialSettings.to'),
          align: 'left',
          sortable: true,
          value: 'maximumCount',
        },
        {
          text: this.$t('financialSettings.cost'),
          align: 'left',
          sortable: true,
          value: 'amount',
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container>
    <fleet-management>
    </fleet-management>
  </v-container>
</template>

<script>
import FleetManagement from '@/components/fleetManagement/FleetManagement.vue';

export default {
  name: 'fleet-management-view',
  components: {
    FleetManagement,
  },
};
</script>

<style scoped>

</style>

import uuid from 'uuid';
import _ from 'lodash';

const defaultState = {
  errors: [],
  successes: [],
  authError: null,
};

const getters = {
  errors: state => state.errors,
  successes: state => state.successes,
  authError: state => state.authError,
};
const actions = {
  addError(context, { message, error }) {
    const { code, details } = _.get(error, 'response.data.errors[0]', {});
    const newError = {
      message, code, details, id: uuid(),
    };
    const errors = [...context.state.errors, newError];
    context.commit('setErrors', errors);
  },
  popError(context, { id }) {
    const errors = context.state.errors.filter(error => error.id !== id);
    context.commit('setErrors', errors);
  },
  setAuthError(context, { message }) {
    context.commit('setAuthError', { message });
  },
  addSuccess(context, { message, timeout = 3000 }) {
    const newSuccess = { message, id: uuid(), timeout };
    const successes = [...context.state.successes, newSuccess];
    context.commit('setSuccesses', successes);
  },
  popSuccess(context, { id }) {
    const successes = context.state.successes.filter(success => success.id !== id);
    context.commit('setSuccesses', successes);
  },
};
const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },
  setSuccesses(state, successes) {
    state.successes = successes;
  },
  setAuthError(state, authError) {
    state.authError = authError;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

<template>
  <div class="booking-channel-view">
    <div v-if="bootstrapped">
      <div class="topbar d-flex justify-space-between align-center">
        <div class="topbar__left">
          <h2 class="bch">
            {{ remoteState.bookingChannel.name }} - {{ remoteState.bookingChannel.humanReadableId }}
          </h2>
        </div>
        <div class="topbar__right">
          <v-btn small color="blue" class="white--text" @click="refresh">
            Refresh
          </v-btn>
          <v-btn small color="primary" class="white--text ml-4" @click="openJsonDialog">
            JSON
          </v-btn>
          <v-btn small color="primary" class="white--text ml-4" v-if="isCustomerFormAvailable" @click="showCustomerForm">
            Jot Form
          </v-btn>
        </div>
      </div>
      <booking-channel-jot-form-sheet v-if="isCustomerFormVisible" :jot-form="remoteState.customerForm" @closeClick="isCustomerFormVisible=false"></booking-channel-jot-form-sheet>
      <v-row>
        <v-col cols="3">
          <booking-channel-settings-menu v-if="bootstrapped" :remote-state="remoteState" @itemClick="setCurrentForm($event)"></booking-channel-settings-menu>
        </v-col>
        <v-col cols="9">
          <div v-if="currentForm">
            <v-card
              class="mx-auto"
            >
              <v-toolbar
                :color="'grey darken-4'"
                dark
              >
                <v-toolbar-title>
                  {{ currentForm.display }}
                </v-toolbar-title>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <form-resolver v-if="bootstrapped" :code="currentForm.code" :remote-state="remoteState"></form-resolver>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <json-dialog @onDismiss="closeJsonDialog" :display="isJsonDialogShown" :title="remoteState.bookingChannel.name" :object="remoteState.bookingChannel"></json-dialog>
    </div>
    <div v-if="!bootstrapped || isLoaderShown">
      <v-overlay :value="isLoaderShown" z-index="1" opacity="0.1">
        <v-progress-circular
          indeterminate
          size="64"
          color="blue"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingChannelJotFormSheet from '@/components/bookingChannel/BookingChannelJotFormSheet.vue';
import BookingChannelSettingsMenu from '@/components/bookingChannel/BookingChannelSettingsMenu.vue';
import JsonDialog from '@/components/JsonDialog.vue';
import FormResolver from '@/components/bookingChannel/forms/FormResolver.vue';

export default {
  name: 'BookingChannelView',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    BookingChannelSettingsMenu,
    FormResolver,
    JsonDialog,
    BookingChannelJotFormSheet,
  },
  data() {
    return {
      currentForm: null,
      bootstrapped: false,
      isJsonDialogShown: false,
      isCustomerFormVisible: false,
    };
  },
  async created() {
    await this.refresh();
    this.bootstrapped = true;
  },
  methods: {
    async refresh() {
      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.$route.params.id,
      });
    },
    setCurrentForm(item) {
      this.currentForm = item;
      window.setTimeout(() => {
        this.$vuetify.goTo(0);
      }, 100);
    },
    closeJsonDialog() {
      this.isJsonDialogShown = false;
    },
    openJsonDialog() {
      this.isJsonDialogShown = true;
    },
    showCustomerForm() {
      this.isCustomerFormVisible = true;
    },
  },
  computed: {
    ...mapGetters({
      configurationState: 'onboarding/configurationState',
      remoteState: 'bookingChannelView/remoteState',
      isLoaderShown: 'bookingChannelView/isLoaderShown',
    }),
    isCustomerFormAvailable() {
      return this.remoteState.customerForm;
    },
  },
};
</script>

<style scoped lang="scss">
.booking-channel-view {
  .topbar {
    margin-bottom: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #efefef;
  }
}
</style>

<template>
  <v-dialog :value="type" persistent width="80%">
    <v-card>
        <v-card-title>
          <span class="headline">{{ $t(`customerDataForm.${type}Title`)}}</span>
        </v-card-title>
        <v-card-text>
          <!-- current -->
          <v-row>
            <v-col  cols=12 md="6">
              <h2>{{ $t(`customerDataForm.subtitles.current`)}}</h2>
              <v-col cols="12">
                <validated-component
                  v-model="customerData.name"
                  >
                  <v-text-field
                    v-model="customerData.name"
                    :label="$t('customerDataForm.name')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  >
                  </v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="customerData.companyId"
                  >
                  <v-text-field
                    v-model="customerData.companyId"
                    :label="$t('customerDataForm.companyId')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  >
                  </v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="customerData.currency"
                  >
                  <v-select
                    v-model="customerData.currency"
                    :items="defaultValues.currencies"
                    :label="$t('customerDataForm.currency')"
                    hide-details
                    no-filter
                    outlined
                    readonly
                    disabled
                  ></v-select>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="customerData.latitude"
                >
                  <v-text-field
                    v-model="customerData.latitude"
                    :label="$t('customerDataForm.latitude')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  >
                  </v-text-field>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="customerData.longitude"
                  >
                  <v-text-field
                    v-model="customerData.longitude"
                    :label="$t('customerDataForm.longitude')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  >
                  </v-text-field>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="customerData.defaultCountry"
                  >
                  <v-text-field
                    v-model="customerData.defaultCountry"
                    :label="$t('customerDataForm.defaultCountry')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  ></v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="customerData.defaultTipBase"
                >
                  <v-select
                    v-model="customerData.defaultTipBase"
                    :items="defaultValues.tipBase"
                    :label="$t('customerDataForm.defaultTipBase')"
                    hide-details
                    no-filter
                    outlined
                    readonly
                    disabled
                  ></v-select>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="customerData.defaultTipValue"
                  >
                  <v-text-field
                    v-model="customerData.defaultTipValue"
                    :label="$t('customerDataForm.defaultTipValue')"
                    outlined
                    hide-details
                    no-filter
                    readonly
                    disabled
                  ></v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
              <validated-component
                v-model="customerData.iosBundleId"
                >
                <v-text-field
                  v-model="customerData.iosBundleId"
                  :label="$t('customerDataForm.iosBundleId')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.androidApplicationId"
                >
                <v-text-field
                  v-model="customerData.androidApplicationId"
                  :label="$t('customerDataForm.androidApplicationId')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12">
              <validated-component
                v-model="customerData.currencySymbol"
                >
                <v-select
                  v-model="customerData.currencySymbol"
                  :items="defaultValues.currencySymbols"
                  :label="$t('customerDataForm.currencySymbol')"
                  hide-details
                  no-filter
                  outlined
                  readonly
                  disabled
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.storeUrlIos"
                >
                <v-text-field
                  v-model="customerData.storeUrlIos"
                  :label="$t('customerDataForm.storeUrlIos')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.communicationLanguage"
                >
                <v-select
                  v-model="customerData.communicationLanguage"
                  :items="defaultValues.languages"
                  :label="$t('customerDataForm.communicationLanguage')"
                  hide-details
                  no-filter
                  outlined
                  readonly
                  disabled
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.teamId"
                >
                <v-text-field
                  v-model="customerData.teamId"
                  :label="$t('customerDataForm.teamId')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.supportPhoneNumber"
                >
                <v-text-field
                  v-model="customerData.supportPhoneNumber"
                  :label="$t('customerDataForm.supportPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.callDriverDirectPhoneNumber"
                >
                <v-text-field
                  v-model="customerData.callDriverDirectPhoneNumber"
                  :label="$t('customerDataForm.callDriverDirectPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.stripeAdmin"
                >
                <v-text-field
                  v-model="customerData.stripeAdmin"
                  :label="$t('customerDataForm.stripeAdmin')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.appleAccountName"
                >
                <v-text-field
                  v-model="customerData.appleAccountName"
                  :label="$t('customerDataForm.appleAccountName')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.googleAccountName"
                >
                <v-text-field
                  v-model="customerData.googleAccountName"
                  :label="$t('customerDataForm.googleAccountName')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.businessAdminFullName"
                >
                <v-text-field
                  v-model="customerData.businessAdminFullName"
                  :label="$t('customerDataForm.businessAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.businessAdminEmail"
                >
                <v-text-field
                  v-model="customerData.businessAdminEmail"
                  :label="$t('customerDataForm.businessAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.customerConsoleAdminFullName"
                >
                <v-text-field
                  v-model="customerData.customerConsoleAdminFullName"
                  :label="$t('customerDataForm.customerConsoleAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.customerConsoleAdminEmail"
                >
                <v-text-field
                  v-model="customerData.customerConsoleAdminEmail"
                  :label="$t('customerDataForm.customerConsoleAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.salesTaxInfo"
                >
                 <v-text-field
                  v-model="customerData.salesTaxInfo"
                  :label="$t('customerDataForm.salesTaxInfo')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.paymentType"
                >
                <v-text-field
                  v-model="customerData.paymentType"
                  :label="$t('customerDataForm.paymentType')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                  disabled
                ></v-text-field>
              </validated-component>
            </v-col>
            </v-col>


          <!-- IMPORT -->

            <v-col  cols=12 md="6">
              <h2>{{ $t(`customerDataForm.subtitles.toImport`)}}</h2>
              <v-col cols="12">
                <validated-component
                  v-model="importData.name"
                  :validator="$v.importData.name">
                  <v-text-field
                    v-model="importData.name"
                    :label="$t('customerDataForm.name')"
                    outlined
                    hide-details
                    no-filter
                  >
                  </v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="importData.companyId"
                  :validator="$v.importData.companyId">
                  <v-text-field
                    v-model="importData.companyId"
                    :label="$t('customerDataForm.companyId')"
                    outlined
                    hide-details
                    no-filter
                  >
                  </v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="importData.currency"
                  :validator="$v.importData.currency">
                  <v-select
                    v-model="importData.currency"
                    :items="defaultValues.currencies"
                    :label="$t('customerDataForm.currency')"
                    hide-details
                    no-filter
                    outlined
                  ></v-select>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="importData.latitude"
                  :validator="$v.importData.latitude">
                  <v-text-field
                    v-model="importData.latitude"
                    :label="$t('customerDataForm.latitude')"
                    outlined
                    hide-details
                    no-filter
                  >
                  </v-text-field>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="importData.longitude"
                  :validator="$v.importData.longitude">
                  <v-text-field
                    v-model="importData.longitude"
                    :label="$t('customerDataForm.longitude')"
                    outlined
                    hide-details
                    no-filter
                  >
                  </v-text-field>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="importData.defaultCountry"
                  :validator="$v.importData.defaultCountry">
                  <v-text-field
                    v-model="importData.defaultCountry"
                    :label="$t('customerDataForm.defaultCountry')"
                    outlined
                    hide-details
                    no-filter
                  ></v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
                <validated-component
                  v-model="importData.defaultTipBase"
                  :validator="$v.importData.defaultTipBase">
                  <v-select
                    v-model="importData.defaultTipBase"
                    :items="defaultValues.tipBase"
                    :label="$t('customerDataForm.defaultTipBase')"
                    hide-details
                    no-filter
                    outlined
                  ></v-select>
                </validated-component>
              </v-col>
              <v-col cols="12">
                <validated-component
                  v-model="importData.defaultTipValue"
                  :validator="$v.importData.defaultTipValue">
                  <v-text-field
                    v-model="importData.defaultTipValue"
                    :label="$t('customerDataForm.defaultTipValue')"
                    outlined
                    hide-details
                    no-filter
                  ></v-text-field>
                </validated-component>
              </v-col>

              <v-col cols="12">
              <validated-component
                v-model="importData.iosBundleId"
                :validator="$v.importData.iosBundleId">
                <v-text-field
                  v-model="importData.iosBundleId"
                  :label="$t('customerDataForm.iosBundleId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.androidApplicationId"
                :validator="$v.importData.androidApplicationId">
                <v-text-field
                  v-model="importData.androidApplicationId"
                  :label="$t('customerDataForm.androidApplicationId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12">
              <validated-component
                v-model="importData.currencySymbol"
                :validator="$v.importData.currencySymbol">
                <v-select
                  v-model="importData.currencySymbol"
                  :items="defaultValues.currencySymbols"
                  :label="$t('customerDataForm.currencySymbol')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.storeUrlIos"
                :validator="$v.importData.storeUrlIos">
                <v-text-field
                  v-model="importData.storeUrlIos"
                  :label="$t('customerDataForm.storeUrlIos')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.communicationLanguage"
                :validator="$v.importData.communicationLanguage">
                <v-select
                  v-model="importData.communicationLanguage"
                  :items="defaultValues.languages"
                  :label="$t('customerDataForm.communicationLanguage')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.teamId"
                :validator="$v.importData.teamId">
                <v-text-field
                  v-model="importData.teamId"
                  :label="$t('customerDataForm.teamId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.supportPhoneNumber"
                :validator="$v.importData.supportPhoneNumber">
                <v-text-field
                  v-model="importData.supportPhoneNumber"
                  :label="$t('customerDataForm.supportPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.callDriverDirectPhoneNumber"
                :validator="$v.importData.callDriverDirectPhoneNumber">
                <v-text-field
                  v-model="importData.callDriverDirectPhoneNumber"
                  :label="$t('customerDataForm.callDriverDirectPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.stripeAdmin"
                :validator="$v.importData.stripeAdmin">
                <v-text-field
                  v-model="importData.stripeAdmin"
                  :label="$t('customerDataForm.stripeAdmin')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.appleAccountName"
                :validator="$v.importData.appleAccountName">
                <v-text-field
                  v-model="importData.appleAccountName"
                  :label="$t('customerDataForm.appleAccountName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.googleAccountName"
                :validator="$v.importData.googleAccountName">
                <v-text-field
                  v-model="importData.googleAccountName"
                  :label="$t('customerDataForm.googleAccountName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.businessAdminFullName"
                :validator="$v.importData.businessAdminFullName">
                <v-text-field
                  v-model="importData.businessAdminFullName"
                  :label="$t('customerDataForm.businessAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.businessAdminEmail"
                :validator="$v.importData.businessAdminEmail">
                <v-text-field
                  v-model="importData.businessAdminEmail"
                  :label="$t('customerDataForm.businessAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.customerConsoleAdminFullName"
                :validator="$v.importData.customerConsoleAdminFullName">
                <v-text-field
                  v-model="importData.customerConsoleAdminFullName"
                  :label="$t('customerDataForm.customerConsoleAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.customerConsoleAdminEmail"
                :validator="$v.importData.customerConsoleAdminEmail">
                <v-text-field
                  v-model="importData.customerConsoleAdminEmail"
                  :label="$t('customerDataForm.customerConsoleAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.salesTaxInfo"
                :validator="$v.importData.salesTaxInfo">
                 <v-text-field
                  v-model="importData.salesTaxInfo"
                  :label="$t('customerDataForm.salesTaxInfo')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="importData.paymentType"
                :validator="$v.importData.paymentType">
                <v-text-field
                  v-model="importData.paymentType"
                  :label="$t('customerDataForm.paymentType')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="close()">
            {{$t('customerDataForm.close')}}
          </v-btn>
          <v-btn
            color="success"
            :disabled="disabled"
            @click="saveImport()"
          >
            {{$t('customerDataForm.import')}}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex';
import customerDataValidation from '../validationDatas/customerData';

export default {
  name: 'customer-data-mutation',
  props: [],
  data: () => ({
    defaultValues: {
      countries: ['CA', 'US', 'IE', 'UK', 'GB', 'AU', 'SE', 'FI', 'NZ', 'GY', 'ES', 'BE'],
      currencies: ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SEK', 'NZD'],
      currencySymbols: ['$', '€', '£', 'kr'],
      languages: ['en', 'fr'],
      booleans: [false, true],
      tipBase: ['currency', 'percentage'],
    },
    type: 'import',
  }),
  computed: {
    ...mapGetters({
      customerData: 'customerData/customer',
      importData: 'customerData/importData',
    }),
    disabled() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  validations() {
    return { importData: customerDataValidation.importData };
  },
  methods: {
    async saveImport() {
      await this.$store.dispatch('customerData/import', { id: this.customerData.id, importData: this.importData });
      this.$emit('updateParent');
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

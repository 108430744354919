<template>
  <loader :is-loading="loading" color="primary">
  </loader>
</template>
<script>
import { Authentication } from '@icabbi/vue-authentication';
import { mapGetters } from 'vuex';

export default {
  name: 'OAuthLogoutView',
  data() {
    return {
      loading: false,
      clientId: process.env.VUE_APP_CLIENT_ID,
      postLogoutUrl: process.env.VUE_APP_POST_LOGOUT_URL,
    };
  },
  async created() {
    await this.$store.dispatch('authentication/revokeToken', { token: this.token, client_id: this.clientId });
    await Authentication(this.$store).logout(this.idToken, this.postLogoutUrl);
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
      idToken: 'authentication/idToken',
    }),
  },
};
</script>

<style scoped>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"ic-table elevation-0 ic-table--heavy-headers",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"hide-default-footer":"","multi-sort":false,"page":_vm.page,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"footer-props":{showFirstLastPage: true}},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"white header-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","dense":"","justify":"center"}},[_c('v-col',{staticClass:"v-toolbar__title",attrs:{"cols":"12","lg":"8"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Fleets ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"d-flex justify-end"},[(_vm.selectedFleets.length > 0)?_c('v-btn',{staticClass:"header-list-button",staticStyle:{"margin-right":"1rem"},attrs:{"dark":"","color":"red"},on:{"click":function($event){_vm.displayConfirmDialog = true}}},[_vm._v(" Remove ")]):_vm._e(),_c('v-btn',{staticClass:"header-list-button",staticStyle:{"margin-right":"1rem"},attrs:{"color":"success"},on:{"click":_vm.addFleetClick}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Add Fleet")]),_c('v-icon',{staticClass:"hidden-md-and-up"},[_vm._v("$icons_system_plus")])],1),_c('v-text-field',{attrs:{"append-icon":"$icons_system_search","label":'Search',"single-line":"","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.dynamicSearch),callback:function ($$v) {_vm.dynamicSearch=$$v},expression:"dynamicSearch"}})],1)])],1)],1),_c('div',{staticClass:"header-separator"})]},proxy:true},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-black black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('string-copy-tooltip',{attrs:{"text":item.id}})]}},{key:"item.actionViewFleet",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewFleetClick(item)}}},[_vm._v(" View ")])]}},{key:"item.actionSelectFleet",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selectedFleets),callback:function ($$v) {_vm.selectedFleets=$$v},expression:"selectedFleets"}})]}}])}),_c('div',{staticClass:"text-center pt-8"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),(_vm.displayConfirmDialog)?_c('v-dialog',{attrs:{"max-width":"500","value":true,"persistent":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Confirm")])]),_c('v-card-text',{staticStyle:{"height":"100%","overflow-y":"hidden"}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Remove the fleet(s) from the network ?")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.displayConfirmDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.removeFleets}},[_vm._v(" Confirm ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
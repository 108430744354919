<template>
  <div class="financial-settings-view">
    <div class="topbar d-flex justify-space-between align-center">
      <div class="topbar__left">
        <h2>
          {{ $t('common.financialSettings') }}
        </h2>
      </div>
      <div class="topbar__right">
        <v-select
          class="mb-0"
          v-model="selectedSubView"
          :items="subViewSelectOptions"
          item-text="display"
          item-value="code"
          label="Settings mode"
          return-object
          single-line
          hide-details
          outlined>
        </v-select>
      </div>
    </div>
    <template v-if="selectedSubView.code === 'fleetToNetworkFinancialSettings'">
      <fleet-to-network-financial-settings-view></fleet-to-network-financial-settings-view>
    </template>
    <template v-if="selectedSubView.code === 'fleetBchFinancialSettings'">
      <fleet-bch-financial-settings-view></fleet-bch-financial-settings-view>
    </template>
  </div>
</template>

<script>
import FleetToNetworkFinancialSettingsView from './FleetToNetworkFinancialSettingsView.vue';
import FleetBchFinancialSettingsView from './FleetBchFinancialSettingsView.vue';

const FLEET_TO_NETWORK_FINANCIAL_SETTINGS_CODE = 'fleetToNetworkFinancialSettings';
const FLEET_BCH_FINANCIAL_SETTINGS_CODE = 'fleetBchFinancialSettings';

export default {
  name: 'FinancialSettingsView',
  components: {
    FleetToNetworkFinancialSettingsView,
    FleetBchFinancialSettingsView,
  },
  data() {
    return {
      selectedSubView: null,
    };
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedSubView = this.subViewSelectOptions[0];
  },
  computed: {
    subViewSelectOptions() {
      return [{
        code: FLEET_TO_NETWORK_FINANCIAL_SETTINGS_CODE,
        display: 'Fleet to Network Financial Settings',
      }, {
        code: FLEET_BCH_FINANCIAL_SETTINGS_CODE,
        display: 'Booking Channel & Fleet Financial Settings',
      }];
    },
  },
};
</script>

<style scoped lang="scss">
.financial-settings-view {
  .topbar {
    margin-bottom: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #efefef;
  }
}
</style>

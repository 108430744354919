import forge from 'node-forge';
import iconv from 'iconv-lite';

// eslint-disable-next-line import/prefer-default-export
export async function decodeCertificate(file, password) {
  const arrayBuffer = await file.arrayBuffer();
  const binary = iconv.decode(Buffer.from(arrayBuffer), 'ISO-8859-1');
  const p12Asn1 = forge.asn1.fromDer(binary);
  const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);
  const certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
  const pkeyBags = p12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
  const certBag = certBags[forge.pki.oids.certBag][0];
  const keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
  const privateKey = forge.pki.privateKeyToPem(keybag.key);
  const certificate = forge.pki.certificateToPem(certBag.cert);
  return { privateKey, certificate };
}

export async function decodeP8Certificate(file) {
  const arrayBuffer = await file.arrayBuffer();
  const binary = iconv.decode(Buffer.from(arrayBuffer), 'ISO-8859-1');
  return binary;
}

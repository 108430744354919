import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';

export default {
  driverName: {
    minLength: minLength(1),
    maxLength: maxLength(255),
    required,
  },
  configuration: {
    key: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      required,
    },
  },
  updateData: {
    name: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      required,
    },
    driverConfigurations: {
      $each: {
        driverName: {
          minLength: minLength(1),
          maxLength: maxLength(255),
          required,
        },
        configuration: {
          key: {
            minLength: minLength(1),
            maxLength: maxLength(255),
            required,
          },
        },
      },
    },
    uiConfiguration: {
      accessToken: {
        minLength: minLength(1),
        required,
      },
      mapStyle: {
        minLength: minLength(1),
        required,
      },
    },
  },
};

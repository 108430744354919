<template>
  <div class="booking-channel-jot-form-sheet">
    <v-card elevation="2">
      <v-toolbar dark color="success" height="">
        <v-toolbar-title>
          Jot Form Data
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 create-booking-channel-card-body" id="create-booking-channel-card-text">
        <jot-form :jot-form="jotForm" :raw-jot-form="jotForm.rawData"></jot-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import JotForm from '@/components/customerData/JotForm.vue';

export default {
  name: 'BookingChannelJotFormSheet',
  components: { JotForm },
  props: {
    jotForm: {
      tyoe: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('closeClick');
    },
  },
};
</script>

<style scoped lang="scss">
.booking-channel-jot-form-sheet {
  margin-bottom: 1.5rem;

  .create-booking-channel-card-body {
    height: 500px;
    overflow-y: scroll;
    padding-bottom: 1rem;
  }
}
</style>

<template>
  <div>
    <v-row>
      <v-col>
        <v-list
          align-start>
          <v-list-item
            align-start
            v-for="(detail, key) in oidcClientsStateCheck.details"
            :key="key">
            <v-list-item-icon>
              <v-icon :color="getColor(detail.completed)">
                {{ getIcon(detail.completed) }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                align="start">
                {{ key }}
              </v-list-item-title>
              <v-list-item-subtitle
                align="start"
                v-if="!detail.completed">
                {{ detail.detail }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div style="height: 2rem;"></div>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="refreshState">Onboard OIDC Clients</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';

export default {
  name: 'OidcClientsForm',
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  computed: {
    oidcClientsStateCheck() {
      return this.remoteState.checkState.oidcClients;
    },
  },
  methods: {
    async refreshState() {
      await this.$store.dispatch('bookingChannelView/onboardSystemComponents', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <l-map style="height: 300px; z-index: 0;" :zoom="zoom" :center="center" ref="map">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker @update:latLng="updateMarkerLatlong" :draggable="true" :lat-lng="markerLatLng"></l-marker>
    <l-circle
      ref="circleMarker"
      v-bind="circle"
    />
  </l-map>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'network-polygon-map',
  props: {
    latitude: {
      type: Number,
    },
    longitude: {
      type: Number,
    },
    radius: {
      type: Number,
    },
  },
  data() {
    return {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      zoom: 9,
      center: [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      markerLatLng: [0, 0],
    };
  },
  computed: {
    circle() {
      return {
        'lat-lng': this.markerLatLng,
        radius: this.radius || 0,
        color: 'grey',
        weight: 1,
        fillColor: '#000000',
        stroke: true,
        padding: [10, 100, 10, 100],
      };
    },
  },
  beforeMount() {
    if (this.latitude) {
      this.center[0] = this.latitude;
      this.markerLatLng[0] = this.latitude;
    }
    if (this.longitude) {
      this.center[1] = this.longitude;
      this.markerLatLng[1] = this.longitude;
    }
  },
  watch: {
    radius(newValue) {
      const map = _.get(this.$refs, 'map.mapObject');
      const circle = _.get(this.$refs, 'circleMarker.mapObject');

      if (map && circle) {
        circle.setRadius(newValue);
        map.fitBounds(circle.getBounds());
      }
    },
  },
  methods: {
    updateMarkerLatlong(event) {
      this.markerLatLng = [event.lat, event.lng];
      this.center = [event.lat, event.lng];
      this.$emit('updateNetworkLatLong', { latitude: event.lat, longitude: event.lng });
    },
  },
};
</script>

<template>
  <onboarding-step :step="step">
    <loader color="primary" :is-loading="paymentMethodsSaveInProgress">
      <v-container>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('cash')"
              label="Cash"
              value="cash"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('creditCard')"
              label="Card"
              value="creditCard"
              input-value="true"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('applePay')"
              label="Apple Pay"
              value="applePay"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('applePayGuest')"
              label="Apple Pay Guest"
              value="applePayGuest"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('googlePay')"
              label="Google Pay"
              value="googlePay"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled=" allowedPaymentMethods.includes('googlePayGuest')"
              label="Google Pay Guest"
              value="googlePayGuest"
            ></v-checkbox>
            <h3>Business</h3>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled="allowedPaymentMethods.includes('IC2Account')"
              label="IC2 Account"
              value="IC2Account"
            ></v-checkbox>
            <v-checkbox
              v-model="allowedPaymentMethods"
              :disabled="allowedPaymentMethods.includes('businessAccount')"
              label="Business Account"
              value="businessAccount"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn
              block
              large
              class="header-button"
              color="primary"
              @click="savePaymentMethods">
              {{ $t(`save`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </loader>
  </onboarding-step>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'payment-methods-step',
  components: { OnboardingStep },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
    humanReadableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allowedPaymentMethods: [],
    };
  },
  computed: {
    ...mapGetters({
      paymentMethodsSaveInProgress: 'paymentMethods/paymentMethodsSaveInProgress',
    }),

  },
  created() {
    const allowedPaymentMethods = _.cloneDeep(this.statePart.values);
    const self = this;
    allowedPaymentMethods.forEach((allowedPaymentMethod) => {
      self.allowedPaymentMethods.push(allowedPaymentMethod.type);
    });
  },
  methods: {
    async savePaymentMethods() {
      const res = [];
      this.allowedPaymentMethods.forEach((paymentMethod) => {
        res.push({ type: paymentMethod });
      });
      await this.$store.dispatch('paymentMethods/save', {
        allowedPaymentMethods: res,
        bookingChannelId: this.bookingChannelId,
      });
    },
  },

};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

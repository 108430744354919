import Vue from 'vue';
import Vuex from 'vuex';
import { AuthenticationVuexModule } from '@icabbi/vue-authentication';

import fleetNetworkApi from '@/store/modules/fleetNetworkApi';
import onboarding from './modules/onboarding';
import bookingChannels from './modules/bookingChannels';
import bookingChannel from './modules/bookingChannel';
import globalMessages from './modules/globalMessages';
import language from './modules/language';
import onboardingSteps from './modules/onboardingSteps';
import fleets from './modules/fleets';
import appSigning from './modules/appSigning';
import builds from './modules/builds';
import customerData from './modules/customerData';
import waitingTimes from './modules/waitingTimes';
import trackmytaxi from './modules/trackmytaxi';
import paymentMethods from './modules/paymentMethods';
import bookingChannelView from './modules/bookingChannelView';
import appConfigApi from './modules/appConfigApi';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication: AuthenticationVuexModule,
    appConfigApi,
    fleetNetworkApi,
    onboarding,
    globalMessages,
    language,
    onboardingSteps,
    fleets,
    builds,
    bookingChannels,
    bookingChannel,
    appSigning,
    customerData,
    waitingTimes,
    trackmytaxi,
    paymentMethods,
    bookingChannelView,
  },
});

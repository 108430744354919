<template>
  <v-btn
    depressed
    color="primary"
    class="element-button primaryFont--text"
    max-width="330px"
    @click="onclick()"
    width="100%"
    height="50px"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    onclick: {
      type: Function,
      required: true,
    },
  },
};
</script>

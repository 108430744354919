<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>
    <v-alert type="warning" v-if="isNoFleetsWarningShown" class="form-error-alert">
      The current booking channel has no fleets associated with it. It is recommended to associate the fleets in the <strong>Fleet Association</strong> section
      before filling out this form as some settings will not be allowed if no fleets are associated (eg. Promo Codes enabled).
    </v-alert>
    <v-alert type="error" v-if="isNoPaymentMethodsWarningShown" class="form-error-alert">
      The current booking channel has no payment methods set. Please set the payment methods in the <strong>Profile</strong> section before filling out
      this section.
    </v-alert>
    <v-row>
      <v-col>
        <h3>
          {{ $t('appBuildSettings.deprecation.title')}}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.isDeprecated"
          :validator="$v.appConfig.isDeprecated">
          <v-checkbox
            autocomplete="off"
            v-model="appConfig.isDeprecated"
            :label="$t('appBuildSettings.deprecation.isDeprecated')"
            outlined
            hide-details
            no-filter
          >
          </v-checkbox>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosReplacementBundleId"
          :validator="$v.appConfig.iosReplacementBundleId">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosReplacementBundleId"
            :label="$t('appBuildSettings.deprecation.iosReplacementBundleId')"
            :disabled="!appConfig.isDeprecated"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidReplacementApplicationId"
          :validator="$v.appConfig.androidReplacementApplicationId">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidReplacementApplicationId"
            :label="$t('appBuildSettings.deprecation.androidReplacementApplicationId')"
            :disabled="!appConfig.isDeprecated"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>
          {{ $t('appBuildSettings.ios.title')}}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosBundleId"
          :validator="$v.appConfig.iosBundleId">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosBundleId"
            :label="$t('appBuildSettings.ios.iosBundleId')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          name="iosMinimumSupportedProductVersion"
          v-model="appConfig.iosMinimumSupportedProductVersion"
          :validator="$v.appConfig.iosMinimumSupportedProductVersion">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosMinimumSupportedProductVersion"
            :label="$t('appBuildSettings.ios.iosMinimumSupportedProductVersion')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosProjectVersion"
          :validator="$v.appConfig.iosProjectVersion">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosProjectVersion"
            :label="$t('appBuildSettings.ios.iosProjectVersion')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosMarketingVersion"
          :validator="$v.appConfig.iosMarketingVersion">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosMarketingVersion"
            :label="$t('appBuildSettings.ios.iosMarketingVersion')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosTeamId"
          :validator="$v.appConfig.iosTeamId">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosTeamId"
            :label="$t('appBuildSettings.ios.iosTeamId')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.appleAccountName"
          :validator="$v.appConfig.appleAccountName">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.appleAccountName"
            :label="$t('appBuildSettings.ios.appleAccountName')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosStoreUrl"
          :validator="$v.appConfig.iosStoreUrl">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosStoreUrl"
            :label="$t('appBuildSettings.ios.iosStoreUrl')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.isUniversalApp"
          :validator="$v.appConfig.isUniversalApp">
          <v-select
            v-model="appConfig.isUniversalApp"
            :label="$t('appBuildSettings.ios.universalApp')"
            :items="defaultValues.booleans"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosClientLoggerKey"
          :validator="$v.appConfig.iosClientLoggerKey">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosClientLoggerKey"
            :label="$t('appBuildSettings.ios.iosClientLoggerKey')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosGoogleMapsKey"
          :validator="$v.appConfig.iosGoogleMapsKey">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosGoogleMapsKey"
            :label="$t('appBuildSettings.ios.iosGoogleMapsKey')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>
          {{ $t('appBuildSettings.android.title')}}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidApplicationId"
          :validator="$v.appConfig.androidApplicationId">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidApplicationId"
            :label="$t('appBuildSettings.android.androidApplicationId')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          name="androidMinimumSupportedProductVersion"
          v-model="appConfig.androidMinimumSupportedProductVersion"
          :validator="$v.appConfig.androidMinimumSupportedProductVersion">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidMinimumSupportedProductVersion"
            :label="$t('appBuildSettings.android.androidMinimumSupportedProductVersion')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidVersionCode"
          :validator="$v.appConfig.androidVersionCode">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidVersionCode"
            :label="$t('appBuildSettings.android.androidVersionCode')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidVersionName"
          :validator="$v.appConfig.androidVersionName">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidVersionName"
            :label="$t('appBuildSettings.android.androidVersionName')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidGoogleMapsKey"
          :validator="$v.appConfig.androidGoogleMapsKey">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidGoogleMapsKey"
            :label="$t('appBuildSettings.android.androidGoogleMapsKey')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.googleAccountName"
          :validator="$v.appConfig.googleAccountName">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.googleAccountName"
            :label="$t('appBuildSettings.android.googleAccountName')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidClientLoggerKey"
          :validator="$v.appConfig.androidClientLoggerKey">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidClientLoggerKey"
            :label="$t('appBuildSettings.android.androidClientLoggerKey')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>
          {{ $t('appBuildSettings.general.title')}}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.percentageMaxAmount"
          :validator="$v.appConfig.percentageMaxAmount">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.percentageMaxAmount"
            :label="$t('appBuildSettings.general.percentageMaxAmount')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.currencyMaxAmount"
          :validator="$v.appConfig.currencyMaxAmount">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.currencyMaxAmount"
            :label="$t('appBuildSettings.general.currencyMaxAmount')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.personalLoginEnabled"
          :validator="$v.appConfig.personalLoginEnabled">
          <v-select
            v-model="appConfig.personalLoginEnabled"
            :items="defaultValues.booleans"
            :label="$t('appBuildSettings.general.personalLoginEnabled')"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.prebookingEnabled"
          :validator="$v.appConfig.prebookingEnabled">
          <v-select
            v-model="appConfig.prebookingEnabled"
            :items="defaultValues.booleans"
            :label="$t('appBuildSettings.general.prebookingEnabled')"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.promoCodeEnabled"
          :validator="$v.appConfig.promoCodeEnabled">
          <v-select
            v-model="appConfig.promoCodeEnabled"
            :items="defaultValues.booleans"
            :label="$t('appBuildSettings.general.promoCodeEnabled')"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.isGooglePayInProduction"
          :validator="$v.appConfig.isGooglePayInProduction">
          <v-select
            v-model="appConfig.isGooglePayInProduction"
            :items="defaultValues.booleans"
            :label="$t('appBuildSettings.general.isGooglePayInProduction')"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.firstInstallPromptEnabled"
          :validator="$v.appConfig.firstInstallPromptEnabled">
          <v-select
            v-model="appConfig.firstInstallPromptEnabled"
            :items="defaultValues.booleans"
            :label="$t('appBuildSettings.general.firstInstallPromptEnabled')"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.supportPhoneNumber"
          :validator="$v.appConfig.supportPhoneNumber">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.supportPhoneNumber"
            :label="$t('appBuildSettings.general.supportPhoneNumber')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.callDriverDirectPhoneNumber"
          :validator="$v.appConfig.callDriverDirectPhoneNumber">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.callDriverDirectPhoneNumber"
            :label="$t('appBuildSettings.general.callDriverDirectPhoneNumber')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>
          {{ $t('appBuildSettings.assets.title')}}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.favicons"
          :validator="$v.appConfig.favicons">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.favicons"
            :label="$t('appBuildSettings.assets.favicons')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.msIcons"
          :validator="$v.appConfig.msIcons">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.msIcons"
            :label="$t('appBuildSettings.assets.msIcons')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.androidAssets"
          :validator="$v.appConfig.androidAssets">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.androidAssets"
            :label="$t('appBuildSettings.assets.androidAssets')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.iosAssets"
          :validator="$v.appConfig.iosAssets">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.iosAssets"
            :label="$t('appBuildSettings.assets.iosAssets')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="appConfig.figmaExample"
          :validator="$v.appConfig.figmaExample">
          <v-text-field
            autocomplete="off"
            v-model="appConfig.figmaExample"
            :label="$t('appBuildSettings.assets.figmaExample')"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <h3>
            {{ $t('appBuildSettings.feedbackEmails.title')}}
          </h3>
          <v-btn color="blue" class="white--text" @click="addFeedbackEmail">
            {{ $t('appBuildSettings.feedbackEmails.addEmail')}}
         </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(email, index) in appConfig.feedbackEmails" :key="`feedbackEmail${index}`" cols="12">
        <p>
          {{ $t('appBuildSettings.feedbackEmails.feedbackEmail')}}
          <v-icon color="info" class="delete-feedbackemail" @click="removeFeedbackEmail(index)">
            delete
          </v-icon>
        </p>
        <validated-component
            v-model="appConfig.feedbackEmails[index]"
            :validator="$v.appConfig.feedbackEmails.$each.$iter[index]">
            <v-text-field
              v-model="appConfig.feedbackEmails[index]"
              autocomplete="off"
              :label="$t('appBuildSettings.feedbackEmails.email')"
              outlined
              hide-details
              no-filter
            >
            </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <div style="height: 2rem;"></div>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex justify-start">
          <v-btn v-if="isCustomerFormAvailable && isNewAppConfigModel" class="mr-4" color="primary" @click="preFillFromCustomerForm">Prefill using Jot Form</v-btn>
          <v-btn color="success" @click="displayAppBuildConfig">App Build Config</v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex justify-end ">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
    <json-dialog @onDismiss="appBuildConfigJsonDialog.display = false" :display="appBuildConfigJsonDialog.display" :title="appBuildConfigJsonDialog.title" :object="appBuildConfigJsonDialog.model"></json-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  // eslint-disable-next-line no-unused-vars
  required, integer, email,
} from 'vuelidate/lib/validators';
import { isBoolean, isValidSemver } from '@/validation/validationMethods';
import JsonDialog from '@/components/JsonDialog.vue';
import { AppConfigUtil } from '@/util/appConfigUtil';

export default {
  name: 'AppBuildSettingsForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  components: {
    JsonDialog,
  },
  data() {
    return {
      isDisplayFormError: false,
      defaultValues: {
        booleans: [false, true],
      },
      appConfig: _.cloneDeep(_.isEmpty(this.remoteState.appConfig) ? this.getAppConfigDefaults() : this.remoteState.appConfig),
      // For testing that the new app build configuration endpoint works correctly (/v1/configurations),
      // we will compare the temporary app build config (which will replace the current app build config endpoint when ready)
      // with the current app build config.
      // the JSON objects will be displayed in dialogs
      appBuildConfigJsonDialog: {
        title: null,
        model: null,
        display: false,
      },
    };
  },
  created() {
    this.$v.$touch();
  },
  methods: {
    getAppConfigDefaults() {
      return {
        iosProjectVersion: 1,
        iosMarketingVersion: '1',
        androidVersionCode: 1,
        androidVersionName: '1.0.0',
      };
    },
    preFillFromCustomerForm() {
      const { customerForm } = this.remoteState;
      this.appConfig.androidApplicationId = customerForm.androidApplicationId;
      this.appConfig.appleAccountName = customerForm.appleAccountName;
      this.appConfig.callDriverDirectPhoneNumber = customerForm.callDriverDirectPhoneNumber;
      this.appConfig.supportPhoneNumber = customerForm.supportPhoneNumber;
      this.appConfig.googleAccountName = customerForm.googleAccountName;
      this.appConfig.iosBundleId = customerForm.iosBundleId;
      this.appConfig.iosStoreUrl = customerForm.storeUrlIos;

      this.$forceUpdate();

      // Scroll to top of the page
      window.setTimeout(() => {
        this.$vuetify.goTo(0);
      }, 100);
    },
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
        window.setTimeout(() => {
          this.$vuetify.goTo(0);
        }, 100);
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      window.setTimeout(() => {
        this.$vuetify.goTo(0);
      }, 100);

      // eslint-disable-next-line no-unused-vars
      const payload = {
        ...this.appConfig,
        bookingChannelId: this.remoteState.bookingChannel.id,
        humanReadableId: this.remoteState.bookingChannel.humanReadableId,
        name: this.remoteState.bookingChannel.name,
      };

      await this.$store.dispatch('bookingChannelView/updateAppConfig', {
        payload: AppConfigUtil.cleanAppConfig(payload),
      });

      // Only update if the `androidApplicationId` or `iosBundleId` are different.
      if (this.isAndroidIdOrIosBundleIdDifferentInBookingChannel()) {
        // The "androidApplicationId" and "iosBundleId" are also part of the booking channel
        // and are used for setting up the Push Notifications.
        // For backward compatibility, we will update the booking channel too.
        await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
          id: this.remoteState.bookingChannel.id,
          payload: {
            ..._.cloneDeep(this.remoteState.bookingChannel),
            androidApplicationId: this.appConfig.androidApplicationId,
            iosBundleId: this.appConfig.iosBundleId,
          },
        });
      }

      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
    },
    isAndroidIdOrIosBundleIdDifferentInBookingChannel() {
      const bch = this.remoteState.bookingChannel;
      return (bch.androidApplicationId !== this.appConfig.androidApplicationId)
        || (bch.iosBundleId !== this.appConfig.iosBundleId);
    },
    async displayLegacyAppBuildConfig() {
      this.appBuildConfigJsonDialog.model = await this.$store.dispatch('bookingChannelView/getLegacyAppBuildConfig', {
        humanReadableId: this.remoteState.bookingChannel.humanReadableId,
      });
      this.appBuildConfigJsonDialog.display = true;
      this.appBuildConfigJsonDialog.title = 'Legacy App Build Configuration';
    },
    async displayAppBuildConfig() {
      this.appBuildConfigJsonDialog.model = await this.$store.dispatch('bookingChannelView/getAppBuildConfig', {
        humanReadableId: this.remoteState.bookingChannel.humanReadableId,
      });
      this.appBuildConfigJsonDialog.display = true;
      this.appBuildConfigJsonDialog.title = 'App Build Configuration';
    },
    addFeedbackEmail() {
      if (!this.appConfig.feedbackEmails) {
        this.appConfig.feedbackEmails = [];
      }
      this.appConfig.feedbackEmails.push('');
      this.$forceUpdate();
    },
    removeFeedbackEmail(index) {
      this.appConfig.feedbackEmails.splice(index, 1);
    },
  },
  computed: {
    isNoFleetsWarningShown() {
      const { fleets } = this.remoteState.bookingChannel;
      return !Array.isArray(fleets) || !fleets.length;
    },
    canSave() {
      return !this.isNoPaymentMethodsWarningShown && this.$v.$anyDirty;
    },
    isNoPaymentMethodsWarningShown() {
      const { allowedPaymentMethods } = this.remoteState.bookingChannel;
      return !Array.isArray(allowedPaymentMethods) || !allowedPaymentMethods.length;
    },
    isCustomerFormAvailable() {
      return this.remoteState.customerForm;
    },
    /**
     * Return true if an appConfig model does not yet exist for this booking channel.
     * @returns {boolean}
     */
    isNewAppConfigModel() {
      return _.isEmpty(this.remoteState.appConfig);
    },
  },
  validations() {
    return {
      appConfig: {
        iosMinimumSupportedProductVersion: {
          isValidSemver,
        },
        iosBundleId: {
          required,
        },
        iosProjectVersion: {
          required,
          integer,
        },
        iosMarketingVersion: {
          required,
        },
        iosTeamId: {
          required,
        },
        isUniversalApp: {
          isBoolean,
        },
        appleAccountName: {
          required,
        },
        iosStoreUrl: {
        },
        iosGoogleMapsKey: {
          required,
        },
        androidMinimumSupportedProductVersion: {
          isValidSemver,
        },
        androidApplicationId: {
          required,
        },
        androidVersionCode: {
          required,
          integer,
        },
        androidVersionName: {
          required,
        },
        androidGoogleMapsKey: {
          required,
        },
        googleAccountName: {
          required,
        },
        isGooglePayInProduction: {
          required,
          isBoolean,
        },
        supportPhoneNumber: {
        },
        callDriverDirectPhoneNumber: {
        },
        percentageMaxAmount: {
          required,
          integer,
        },
        currencyMaxAmount: {
          required,
          integer,
        },
        personalLoginEnabled: {
          required,
          isBoolean,
        },
        prebookingEnabled: {
          required,
          isBoolean,
        },
        firstInstallPromptEnabled: {
          required,
          isBoolean,
        },
        promoCodeEnabled: {
          required,
          isBoolean,
        },
        favicons: {},
        msIcons: {},
        androidAssets: {},
        iosAssets: {},
        figmaExample: {},
        feedbackEmails: {
          $each: {
            required,
            email,
          },
        },
        isDeprecated: {
          isBoolean,
        },
        androidReplacementApplicationId: {
          isRequired(value) {
            return !(this.appConfig.isDeprecated && !value);
          },
        },
        iosReplacementBundleId: {
          isRequired(value) {
            return !(this.appConfig.isDeprecated && !value);
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

export default class FileReaderUtil {

  /**
   * @param {File} file
   * @return {Promise<string>}
   */
  static stringifyJsonFileContent(file) {
    return new Promise((resolve, reject) => {
      if (file && (file.type === 'application/json')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const parsedJson = JSON.parse(e.target.result);
            resolve(JSON.stringify(parsedJson)); // Pretty print JSON
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsText(file);
      } else {
        const error = new Error('Invalid JSON file.');
        reject(error);
      }
    });
  }

}

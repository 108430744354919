<template>
  <div class="color-preview-container" :style="style">X</div>
</template>
<script>
export default {
  name: 'color-preview',
  props: ['color'],
  computed: {
    style() {
      return `background-color: ${this.color}; color: ${this.color};`;
    },
  },
};
</script>
<style lang="scss" scoped>
.color-preview-container {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 13px;
  right: 13px;
  border: 1px solid #1a1a1a;
  color: red;
  background-color: white;
  font-size: 30px;
  padding: 4px;
}
</style>

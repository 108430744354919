<template>
  <v-container>
    <v-overlay :value="isLoaderShown" z-index="1" opacity="0.1">
      <v-progress-circular
        indeterminate
        size="64"
        color="blue"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="isConfirmationDialogShown"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('common.confirmYourChanges') }}
        </v-card-title>
        <v-card-text>
          <h4>
            {{ $t('appBuildSettings.android.androidMinimumSupportedProductVersion') }}: {{ globalAppConfiguration.androidMinimumSupportedProductVersion }}
          </h4>
          <h4>
            {{ $t('appBuildSettings.ios.iosMinimumSupportedProductVersion') }}: {{ globalAppConfiguration.iosMinimumSupportedProductVersion }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="isConfirmationDialogShown = false"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            class="success"
            @click="isConfirmationDialogShown = false; save()"
          >
            {{ $t('common.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      class="mx-auto"
    >
      <v-toolbar
        :color="'grey darken-4'"
        dark
      >
        <v-toolbar-title>
          Global App Build Settings
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-alert type="error" v-if="isFormErrorVisible" class="form-error-alert">
          The form is invalid. Please correct the errors.
        </v-alert>
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="d-flex flex-row align-center mb-4">
              <v-icon class="black--text" style="font-size: 32px">android</v-icon>
              <span class="d-inline-block ml-2 mt-1">
                Android Minimum Global Version
              </span>
            </h4>
            <validated-component
              name="minimumSupportedProductVersionAndroid"
              v-model="globalAppConfiguration.androidMinimumSupportedProductVersion"
              :validator="$v.globalAppConfiguration.androidMinimumSupportedProductVersion">
              <v-text-field
                autocomplete="off"
                v-model="globalAppConfiguration.androidMinimumSupportedProductVersion"
                :label="$t('appBuildSettings.android.androidMinimumSupportedProductVersion')"
                outlined
                hide-details
                no-filter
              >
              </v-text-field>
            </validated-component>
          </v-col>
          <v-col cols="12" md="6">
            <h4 class="d-flex flex-row align-center mb-6">
              <span class="d-inline-block" style="width: 24px; height: 24px;">
                <v-img src="/apple.png" width="24px" height="24px"></v-img>
              </span>
              <span class="d-inline-block ml-2">
                iOS Minimum Global Version
              </span>
            </h4>
            <validated-component
              name="iosMinimumSupportedProductVersion"
              v-model="globalAppConfiguration.iosMinimumSupportedProductVersion"
              :validator="$v.globalAppConfiguration.iosMinimumSupportedProductVersion">
              <v-text-field
                autocomplete="off"
                v-model="globalAppConfiguration.iosMinimumSupportedProductVersion"
                :label="$t('appBuildSettings.ios.iosMinimumSupportedProductVersion')"
                outlined
                hide-details
                no-filter
              >
              </v-text-field>
            </validated-component>
          </v-col>
        </v-row>
        <v-divider class="mb-4"></v-divider>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import { isValidSemver } from '@/validation/validationMethods';

export default {
  name: 'GlobalAppBuildManagement',
  data() {
    return {
      isConfirmationDialogShown: false,
      isLoaderShown: false,
      isFormErrorVisible: false,
      globalAppConfiguration: {
        iosMinimumSupportedProductVersion: null,
        androidMinimumSupportedProductVersion: null,
      },
    };
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  async created() {
    await this.setCurrentGlobalConfig();
  },
  methods: {
    async setCurrentGlobalConfig() {
      this.isLoaderShown = true;

      try {
        const config = await this.$store.dispatch('appConfigApi/getGlobalAppConfig');
        if (config !== null) {
          this.globalAppConfiguration.iosMinimumSupportedProductVersion = config.iosMinimumSupportedProductVersion;
          this.globalAppConfiguration.androidMinimumSupportedProductVersion = config.androidMinimumSupportedProductVersion;
        }
      } finally {
        this.isLoaderShown = false;
      }
    },
    async onSaveClick() {
      this.isFormErrorVisible = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isFormErrorVisible = true;
      } else {
        this.openConfirmationDialog();
      }
    },
    openConfirmationDialog() {
      this.isConfirmationDialogShown = true;
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      this.isLoaderShown = true;
      try {
        await this.$store.dispatch('appConfigApi/updateGlobalAppConfig', this.globalAppConfiguration);
      } finally {
        this.isLoaderShown = false;
      }
    },
  },
  validations() {
    return {
      globalAppConfiguration: {
        iosMinimumSupportedProductVersion: {
          required,
          isValidSemver,
        },
        androidMinimumSupportedProductVersion: {
          required,
          isValidSemver,
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <fleet-list @loadData="getFleets" :is-loading="fleetsLoading" :total-items="totalFleets" :items="fleets" @viewNewFleetForm="showFleetManagementCreate" @viewFleetClick="showFleetManagement($event)" @viewFleetJsonClick="displayFleetDialog($event)" @webhooksClick="displayWebhooksDialog($event)"></fleet-list>
    <fleet-json-dialog @onDismiss="closeFleetDialog" :display="isDisplayJsonDialog" :fleet="selectedFleet"></fleet-json-dialog>
    <fleet-webhooks-dialog  @onDismiss="closeWebhooksDialog" :display="shouldDisplayWebhooksDialog" :fleet="selectedFleet"></fleet-webhooks-dialog>
  </div>
</template>

<script>
import FleetList from '@/components/fleet/FleetList.vue';
import FleetJsonDialog from '@/components/fleet/FleetJsonDialog.vue';
import FleetWebhooksDialog from '@/components/fleet/FleetWebhooksDialog.vue';

export default {
  name: 'FleetsView',
  data() {
    return {
      selectedFleet: null,
      fleets: [],
      fleetsLoading: true,
      totalFleets: 0,
      isDisplayJsonDialog: false,
      shouldDisplayWebhooksDialog: false,
    };
  },
  components: {
    FleetList,
    FleetJsonDialog,
    FleetWebhooksDialog,
  },
  methods: {
    closeFleetDialog() {
      this.isDisplayJsonDialog = false;
      this.selectedFleet = null;
    },
    closeWebhooksDialog() {
      this.shouldDisplayWebhooksDialog = false;
      this.selectedFleet = null;
    },
    displayFleetDialog(fleet) {
      this.selectedFleet = fleet;
      this.isDisplayJsonDialog = true;
    },
    async showFleetManagement(fleet) {
      await this.$router.push(`/fleets/${fleet.id}`);
    },
    async showFleetManagementCreate() {
      await this.$router.push('/fleets/new');
    },
    async displayWebhooksDialog(fleetId) {
      this.fleetsLoading = true;
      this.selectedFleet = await this.$store.dispatch('fleets/getFleet', { fleetId, webhooks: true });
      this.shouldDisplayWebhooksDialog = true;
      this.fleetsLoading = false;
    },
    async getFleets({
      page, maxPerPage, dynamicSearch,
    }) {
      this.fleetsLoading = true;
      const { fleets, totalFleets } = await this.$store.dispatch('fleets/getFleets', {
        page, maxPerPage, dynamicSearch,
      });
      this.fleets = fleets;
      this.totalFleets = totalFleets;
      this.fleetsLoading = false;
    },
  },
};
</script>

<style scoped>

</style>

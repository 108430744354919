<template>
  <v-container>
    <v-row>
      <v-col>
        <v-label>{{ $t('trackmytaxi.fleet' )}}</v-label>
        <autocomplete
          :label="$t('trackmytaxi.fleet')"
          :loading='listFleetInProgress'
          :items='fleetList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('fleetManagementForm.autocomplete')"
          @searchAutocomplete='listFleets'
          @updateModel='setFleet'
        ></autocomplete>
      </v-col>
      <v-col>
        <v-label>{{ $t('trackmytaxi.bookingChannel') }}</v-label>
        <autocomplete
          :label="$t('trackmytaxi.bookingChannel')"
          :loading='listBookingChannelInProgress'
          :items='bookingChannelList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('fleetManagementForm.autocomplete')"
          @searchAutocomplete='bookingChannelList'
          @updateModel='setBookingChannel'
          :disabled='!fleet'
        ></autocomplete>
      </v-col>
    </v-row>
    <hr/>
    <v-row v-if="bookingChannel && fleet">
      <trackmytaxi-config :fleet="fleet" :bookingChannel="bookingChannel"></trackmytaxi-config>
    </v-row>
    <v-row v-else>
     <h3 class="mx-auto my-10">{{ $t('trackmytaxi.notSelected' )}}</h3>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TrackmytaxiConfig from './TrackmytaxiConfig.vue';

export default {
  name: 'trackmytxi',
  components: { TrackmytaxiConfig },
  mounted() {
    this.$store.dispatch('fleets/list', '');
  },
  data() {
    return {
      fleet: null,
      bookingChannel: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      fleetList: 'fleets/list',
      bookingChannelList: 'bookingChannels/list',
      listFleetInProgress: 'fleets/listFleetInProgress',
      listBookingChannelInProgress: 'bookingChannels/listBookingChannelInProgress',
      selectedConfigurations: 'fleets/selectedFleetBookingChannelConfig',
    }),
  },
  watch: {
    fleet(val) {
      this.$store.dispatch('fleets/resetFleetWithBookingChannelConfig');
      this.$store.dispatch('bookingChannels/listByFleet', val.id);
    },
    bookingChannel(val) {
      this.$store.dispatch('fleets/getFleetWithBookingChannelConfig', { fleetId: this.fleet.id, bookingChannelId: val.id });
    },
  },
  methods: {
    listFleets(dynamicSearch) {
      this.$store.dispatch('fleets/list', dynamicSearch);
    },
    setFleet(fleet) {
      this.fleet = fleet;
    },
    setBookingChannel(bookingChannel) {
      this.bookingChannel = bookingChannel;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12">
      <h3>{{ $t('trackmytaxiConfig.config')}}</h3>
      <v-checkbox
        v-model="enablePassengerPay"
        :label="$t('trackmytaxiConfig.enablePassengerPay')"
      ></v-checkbox>
      <v-btn
        color="primary"
        class="header-button"
        large
        :loading="configGenerationInProgress"
        :disabled="configGenerationInProgress"
        @click="generateConfig">
        {{ $t('generate') }}
      </v-btn>
    </v-col>
    <v-col cols="12" v-if="generatedConfig !== undefined">
      <h3>{{ $t('trackmytaxiConfig.devStep')}} </h3>
      <p>
        In trackmytaxi config file, look for </p>
      <p>
        <code>endpoint="{{ dispatchUrl }}/"</code>
      </p>
      <p>
        and add or update the following line:
      </p>
      <p>
        <code v-html="`companies[${clientId}]=${config}`"></code>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
  name: 'trackmytaxi-config',
  components: {},
  props: {
    fleet: null,
    bookingChannel: null,
  },
  data() {
    return {
      enablePassengerPay: false,
    };
  },
  computed: {
    ...mapGetters({
      generatedConfig: 'trackmytaxi/generatedConfig',
      configGenerationInProgress: 'trackmytaxi/configGenerationInProgress',
    }),
    dispatchUrl() {
      return get(this.generatedConfig, 'dispatchUrl', '-');
    },
    config() {
      const config = get(this.generatedConfig, 'config', '-');
      return JSON.stringify(config);
    },
    clientId() {
      return get(this.generatedConfig, 'clientId', '-');
    },
  },
  methods: {
    generateConfig() {
      this.$store.dispatch('trackmytaxi/generateConfig', { fleetId: this.fleet.id, bookingChannelId: this.bookingChannel.id, enablePassengerPay: this.enablePassengerPay });
    },
  },
};
</script>

<style scoped>

</style>

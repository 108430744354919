<template>
  <v-dialog
    max-width="500"
    v-if="display"
    :value="true"
    persistent
  >
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">Add a fleet</span>
      </v-card-title>
      <v-card-text style="height: 100%; overflow-y: hidden;">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="$icons_system_search"
              :label="$t('networkFleetDialog.fleets')"
              single-line
              clearable
              outlined
              hide-details
              class="book-employee-search-field"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="employee-list">
            <v-data-table
              :headers="headers"
              :items="fleets"
              :server-items-length="totalFleets"
              :loading="isFleetSearchLoading"
              hide-default-footer
              :multi-sort="false"
              :must-sort="true"
              @page-count="pageCount = $event"
              :page.sync="page"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :footer-props="{ showFirstLastPage: true }"
              @click:row="toggleFleet"
              :loading-text="$t('networkFleetDialog.loadingItems')"
              :no-data-text="$t('networkFleetDialog.noData')"
              class="elevation-0 employee-search-table"
            >
              <template v-slot:header.companyName="{ header }">
                <span class="font-weight-black black--text">{{ header.text }}</span>
              </template>
              <template v-slot:header.humanReadableId="{ header }">
                <span class="font-weight-black black--text">{{ header.text }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2 icon-checkbox-employee icon-checkbox-empty">{{ fleetRowIcon(item) }}</v-icon>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dismiss">
          Close
        </v-btn>
        <v-btn color="success" @click="addFleets" :disabled="!addFleetIds">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'debounce';

export default {
  name: 'NetworkFleetDialog',
  data() {
    return {
      search: null,
      fleet: null,
      page: 1,
      itemsPerPage: 5,
      options: {},
      addFleetIds: [],
      pageCount: 0,
    };
  },
  props: {
    fleets: {
      required: false,
    },
    display: {
      required: true,
    },
    isFleetSearchLoading: {
      required: true,
    },
    totalFleets: {
      type: Number,
      required: true,
    },
  },
  methods: {
    dismiss() {
      this.addFleetIds = [];
      this.search = null;
      this.$emit('onDismiss');
    },
    addFleets() {
      const addedFleets = [];
      this.addFleetIds.forEach((fleetId) => {
        addedFleets.push({ fleetId });
      });
      this.$emit('manageNetworkFleets', { addedFleets });
      this.addFleetIds = [];
      this.search = null;
    },
    async load() {
      this.$emit('loadData', {
        dynamicSearch: this.search,
        page: this.page - 1,
        maxPerPage: this.itemsPerPage,
      });
    },
    toggleFleet(fleet) {
      if (this.addFleetIds.includes(fleet.id)) {
        this.addFleetIds = this.addFleetIds.filter(id => id !== fleet.id);
      } else {
        this.addFleetIds.push(fleet.id);
      }
    },
    fleetRowIcon(fleet) {
      return this.addFleetIds.includes(fleet.id) ? '$icons_system_checkbox' : '$icons_system_checkbox_empty';
    },
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
    search(v) {
      if (!v || v.length !== 1) {
        this.debouncedLoad();
      }
    },
    totalFleets() {
      this.options.pageCount = Math.round(this.totalFleets / this.itemsPerPage) + 1;
    },
  },
  created() {
    this.debouncedLoad = debounce(this.load, 500);
  },
  computed: {
    headers() {
      return [
        {
          text: 'Human Readable ID',
          sortable: false,
          value: 'humanReadableId',
        },
        {
          text: 'Company Name',
          align: 'left',
          sortable: false,
          value: 'companyName',
        },
        {
          text: '',
          value: 'action',
          width: '10%',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <v-alert type="error" v-if="isAlreadyAssociatedErrorShown" class="form-error-alert">
      The network is already linked to the booking channel.
    </v-alert>
    <div class="d-flex flex-row flex-wrap mb-10" v-if="!hasAtLeastOneNetwork">
      <div class="fleet-search-cmp mr-8 mb-4" style="min-width: 360px;">
        <v-autocomplete
          @change="setNetwork"
          :label="$t('common.fleetNetworks')"
          :loading="isNetworksSearchLoading"
          :items="networkSearchResults"
          :search-input.sync="search"
          item-value='id'
          item-text="name"
          outlined
          hide-details
          no-filter
          return-object
        >
        </v-autocomplete>
      </div>
      <div>
        <v-btn
          class="header-button"
          color="success"
          @click="updateBookingChannel"
          min-height="55"
          :disabled="!selectedNetwork">
          {{ $t('common.associate') }}
        </v-btn>
      </div>
    </div>
    <v-row>
        <v-col>
          <h4>Associated Networks</h4>
        </v-col>
      </v-row>
    <v-row v-for="(network, index) in remoteState.bookingChannel.fleetNetworks" :key="index">
        <v-col md="4">
          <v-text-field
            v-model="network.fleetNetworkName"
            outlined
            hide-details
            no-filter
            dense
            disabled
            readonly>
          </v-text-field>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FleetNetworksForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {
      search: null,
      isAlreadyAssociatedErrorShown: false,
      selectedNetwork: null,
      isNetworksSearchLoading: false,
      networkSearchResults: [],
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
    };
  },
  computed: {
    hasAtLeastOneNetwork() {
      return (Array.isArray(this.bookingChannel.fleetNetworks)) && (this.bookingChannel.fleetNetworks.length > 0);
    },
  },
  watch: {
    async search(val) {
      await this.listNetworks(val);
    },
  },
  methods: {
    async updateBookingChannel() {
      if (!Array.isArray(this.bookingChannel.fleetNetworks)) {
        this.bookingChannel.fleetNetworks = [];
      }

      this.bookingChannel.fleetNetworks.push({
        fleetNetworkId: this.selectedNetwork.id,
        priority: 0, // This will be updated at a later point when multiple networks will be associated
      });


      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
    async listNetworks(dynamicSearch) {
      this.isNetworksSearchLoading = true;
      const { fleetNetworks } = await this.$store.dispatch('fleetNetworkApi/getFleetNetworks', {
        page: 0,
        maxPerPage: 10,
        dynamicSearch,
      });
      this.networkSearchResults = fleetNetworks;
      this.isNetworksSearchLoading = false;
    },
    setNetwork(network) {
      this.isAlreadyAssociatedErrorShown = false;

      if (this.isNetworkAlreadyAssociated(network)) {
        this.isAlreadyAssociatedErrorShown = true;
      }
      this.selectedNetwork = network;
    },
    isNetworkAlreadyAssociated(network) {
      if (!this.bookingChannel.fleetNetworks) {
        return false;
      }

      return (this.bookingChannel.fleetNetworks.filter(n => n.id === network.id).length > 0);
    },
  },
};
</script>

<style scoped>

</style>

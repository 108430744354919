<template>
  <v-container class="login">
    <v-row class="justify-center">
      <v-img max-height="80px" max-width="300px" src="/icabbi-logo.png"></v-img>
    </v-row>
    <v-row class="justify-center mt-10">
      <h2>{{ $t('login.onboardingConsole') }}</h2>
    </v-row>
    <v-row class="justify-center mt-10">
      <v-btn color="default" @click="start">
        <v-img src="/google-logo.png" max-height="20px" max-width="20px"></v-img>
        <span class="ml-5">{{ $t('login.google') }}</span>
      </v-btn>
    </v-row>
    <v-row class="justify-center mt-10">
      <span class="ml-5 font-weight-bold red--text" v-if="authError">
        {{ authError.message }}
      </span>
    </v-row>
  </v-container>
</template>

<script>
import { Authentication } from '@icabbi/vue-authentication';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loginUrl: false,
    };
  },
  computed: {
    ...mapGetters({
      authError: 'globalMessages/authError',
      idToken: 'authentication/idToken',
      accessToken: 'authentication/token',
    }),
  },
  async created() {
    await this.setLoginUrl(await this.getLoginUrl());
  },
  methods: {
    start() {
      window.location = this.loginUrl;
    },
    async setLoginUrl(url) {
      this.loginUrl = url;
    },
    async getLoginUrl() {
      return Authentication(this.$store).getLoginRedirectionUrl({
        to: { path: '/booking-channels' },
        clientId: process.env.VUE_APP_CLIENT_ID,
        redirectUri: process.env.VUE_APP_AUTH_CALLBACK_URL,
        audience: process.env.VUE_APP_LOGIN_AUDIENCE,
        acrValues: 'urn:icabbi:oidc:google',
      });
    },
  },
};
</script>

<style>
  .login {
    margin-top: 15%!important;
  }
</style>

<template>
  <div class="fleet-financial-settings-view">
    <v-overlay :value="isLoaderShown" z-index="1" opacity="0.1">
      <v-progress-circular
        indeterminate
        size="64"
        color="blue"
      ></v-progress-circular>
    </v-overlay>
    <div class="fleet-search">
      <v-autocomplete
        @change="setFleet"
        :loading="isFleetSearchLoading"
        :items="fleetResults"
        :search-input.sync="search"
        item-value='id'
        item-text="humanReadableId"
        label="Enter Fleet Human Readable Id"
        hide-details
        no-filter
        return-object
        outlined>
      </v-autocomplete>
    </div>
    <div v-if="false">
      <v-alert
        class="mt-8"
        prominent
        type="info"
      >
        <v-row align="center">
          <v-col class="grow">
            The fleet does not have any Network Financial Settings
          </v-col>
          <v-col class="shrink">
            <v-btn @click="showModal = true">Add Financial Setting</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <div class="financial-settings-table--wrapper mt-8" v-if="selectedFleet">
      <fleet-to-network-financial-settings-list
        :items="fleetFinancialSettingsDisplay"
        @createClick="showModal = true"
      >
      </fleet-to-network-financial-settings-list>
    </div>
    <template>
      <financial-settings-create
        :visible="showModal"
        @created="createFinancialSettings"
        @close="closeModal"
      ></financial-settings-create>
    </template>
  </div>
</template>

<script>
import debounce from 'debounce';
import FleetToNetworkFinancialSettingsList from '@/components/financialSettings/FleetToNetworkFinancialSettingsList.vue';
import FinancialSettingsCreate from '@/components/financialSettings/FinancialSettingsCreate.vue';

export default {
  name: 'FleetToNetworkFinancialSettingsView',
  components: {
    FleetToNetworkFinancialSettingsList,
    FinancialSettingsCreate,
  },
  data() {
    return {
      showModal: false,
      search: null,
      isFleetSearchLoading: false,
      isFinancialSettingsLoading: false,
      isLoaderShown: false,
      fleetResults: [],
      selectedFleet: null,
      fleetFinancialSettingsDisplay: [],
    };
  },
  async created() {
    this.debouncedLoad = debounce(this.load, 500);
    await this.load();
  },
  watch: {
    async search() {
      await this.debouncedLoad();
    },
  },
  computed: {
    doesFleetHaveNetworkFinancialSettings() {
      return this.selectedFleet
        && this.selectedFleet.financialSettings
        && Array.isArray(this.selectedFleet.financialSettings.network);
    },
  },
  methods: {
    async load() {
      await this.listFleets(this.search);
    },
    async listFleets(dynamicSearch) {
      this.isFleetSearchLoading = true;
      const { fleets } = await this.$store.dispatch('fleets/getFleets', {
        page: 0, maxPerPage: 10, dynamicSearch,
      });
      this.fleetResults = fleets;
      this.isFleetSearchLoading = false;
    },
    setFleet(fleet) {
      this.selectedFleet = fleet;
      this.setFinancialSettingsDisplayElements();
    },
    setFinancialSettingsDisplayElements() {
      this.fleetFinancialSettingsDisplay = !this.doesFleetHaveNetworkFinancialSettings ? []
        : this.selectedFleet.financialSettings.network.map(setting => ({
          ...setting,
          currency: this.selectedFleet.currency,
        }));
    },
    closeModal() {
      this.showModal = false;
    },
    transformModalFormDataToApiPayload(financialConfig) {
      const data = {
        ...financialConfig,
      };

      if (financialConfig.startDate) {
        data.startDate = `${data.startDate}T00:00:00Z`;
      }

      if (financialConfig.endDate) {
        data.endDate = `${data.endDate}T11:59:59Z`;
      } else {
        delete data.endDate;
      }

      const bookings = financialConfig.fees.booking || [];
      data.fees.booking = bookings.map((booking) => {
        const mapped = {
          ...booking,
        };

        mapped.minimumCount = parseInt(mapped.minimumCount, 10);

        if (!mapped.maximumCount) {
          delete mapped.maximumCount;
        } else {
          mapped.maximumCount = parseInt(mapped.maximumCount, 10);
        }

        mapped.amount = parseFloat(mapped.amount);

        return mapped;
      });

      return data;
    },
    // eslint-disable-next-line no-unused-vars
    async createFinancialSettings(financialConfig) {
      this.isLoaderShown = true;
      try {
        await this.$store.dispatch('fleets/addFleetToNetworkFinancialConfig', {
          financialConfig: this.transformModalFormDataToApiPayload(financialConfig),
          fleetId: this.selectedFleet.id,
        });

        this.showModal = false;
        this.selectedFleet = await this.$store.dispatch('fleets/getById', this.selectedFleet.id);
        this.setFinancialSettingsDisplayElements();
        await this.$store.dispatch(
          'globalMessages/addSuccess',
          { message: 'financialSettings.financialSettingsAddedDelay' },
          { root: true },
        );
      } finally {
        this.isLoaderShown = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

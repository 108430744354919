<template>
  <div>
    <v-row>
      <v-col>
        <h4>Create an Alert Message</h4>
      </v-col>
    </v-row>

    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
      <ul>
        <li v-for="(error, index) in getErrors()" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <!-- Alert Message Builder -->
    <template>
      <v-container>

        <v-row>
          <!-- Level -->
          <v-col cols="4" class="form-part pr-1 pl-1">
            <v-select
              v-model="newAlert.level"
              :label="$t('alertsConfigurationForm.level')"
              outlined
              :items="alertLevels"
              dense
            >
            </v-select>
          </v-col>

          <!-- appPlatformCode -->
          <v-col cols="4" class="form-part pr-1 pl-1">
            <v-select
              v-model="newAlert.appPlatformCode"
              :label="$t('alertsConfigurationForm.appPlatformCode')"
              :items="appPlatformCodes"
              outlined
              dense
              clearable
            >
            </v-select>
          </v-col>

          <!-- appProductVersion -->
          <v-col cols="4" class="form-part pr-1 pl-1">
            <v-text-field
              v-model="newAlert.appProductVersion"
              :label="$t('alertsConfigurationForm.appProductVersion')"
              outlined
              hide-details
              dense>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- Title -->
          <v-col cols="3" class="form-part pr-1 pl-1">
            <v-text-field
              v-model="newAlert.title"
              :label="$t('alertsConfigurationForm.title')"
              :maxlength="43"
              outlined
              hide-details
              dense>
            </v-text-field>
          </v-col>

          <!-- Message -->
          <v-col cols="8" class="form-part pr-1 pl-1">
            <v-text-field
              v-model="newAlert.message"
              :label="$t('alertsConfigurationForm.message')"
              :maxlength="80"
              outlined
              hide-details
              dense>
            </v-text-field>
          </v-col>

          <v-col lg="1" class="form-part pr-1 pl-1 justify-center align-center">
            <v-btn
              color="success"
              fab
              x-small
              :disabled="(!newAlert.level || !newAlert.title || !newAlert.message)"
              @click="onSaveClick"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </template>

    <v-row class="pt-0 mt-12">
      <v-col>
        <h4>Alert Messages</h4>
      </v-col>
    </v-row>


    <!-- Saved Alert Messages List -->
    <template>
        <v-container
          v-for="(alert, index) in bookingChannel.alerts"
          :key="index"
          >
          <v-row>
            <!-- Level -->
            <v-col cols="3" class="form-part pr-1 pl-1">
              <v-text-field
                v-model="alert.level"
                :label="$t('alertsConfigurationForm.level')"
                :disabled="true"
                outlined
                hide-details
                dense
                readonly
              >
              </v-text-field>
            </v-col>

            <!-- Title -->
            <v-col cols="3" class="form-part pr-1 pl-1">
              <v-text-field
                v-model="alert.appPlatformCode"
                :label="$t('alertsConfigurationForm.appPlatformCode')"
                :disabled="true"
                outlined
                hide-details
                dense
                readonly>
              </v-text-field>
            </v-col>

            <!-- Message -->
            <v-col cols="3" class="form-part pr-1 pl-1">
              <v-text-field
                v-model="alert.appProductVersion"
                :label="$t('alertsConfigurationForm.appProductVersion')"
                :disabled="true"
                outlined
                hide-details
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-6">
            <!-- Title -->
            <v-col cols="3" class="form-part pr-1 pl-1">
              <v-text-field
                v-model="alert.title"
                :label="$t('alertsConfigurationForm.title')"
                outlined
                hide-details
                dense
                readonly>
              </v-text-field>
            </v-col>

            <!-- Message -->
            <v-col cols="8" class="form-part pr-1 pl-1">
              <v-text-field
                v-model="alert.message"
                :label="$t('alertsConfigurationForm.message')"
                :disabled="true"
                outlined
                hide-details
                readonly
                dense>
              </v-text-field>
            </v-col>

            <v-col lg="1" class="form-part pr-1 pl-1 justify-center align-center">
              <v-btn
                color="error"
                fab
                x-small
                @click="deleteAlert(index)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
    </template>

  </div>
</template>

<script>
import _ from 'lodash';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';

export default {
  name: 'AlertsConfigurationForm',
  props: {
    remoteState: {
      level: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
      pageSettings: {
        isMapEnabledForDefaultRadiusRadius: false,
      },
      alertLevels: [
        'INFO',
        'WARNING',
        'ERROR',
      ],
      appPlatformCodes: [
        'ANDROID',
        'IOS',
        'WEB',
      ],
      addressSearchConfigurationFormDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('addressSearchConfiguration'),
      newAlert: {
        level: null,
        title: null,
        message: null,
        appPlatformCode: null,
        appProductVersion: null,
      },
    };
  },
  computed: {
    hasErrors() {
      if (this.getErrors().length) {
        return true;
      }
      return false;
    },
  },
  created() {
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      if (this.getErrors().length) {
        this.isDisplayFormError = true;
      } else {
        await this.saveAlert();
      }
    },
    getErrors() {
      return this.addressSearchConfigurationFormDefinition.getErrors({ bookingChannel: this.bookingChannel });
    },
    async saveAlert() {
      const params = {
        level: this.newAlert.level,
        title: this.newAlert.title,
        message: this.newAlert.message,
        appPlatformCode: this.newAlert.appPlatformCode,
        appProductVersion: this.newAlert.appProductVersion,
      };

      if (this.bookingChannel.alerts) {
        this.bookingChannel.alerts.push(params);
      } else {
        this.bookingChannel.alerts = [params];
      }
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
      this.newAlert.level = null;
      this.newAlert.title = null;
      this.newAlert.message = null;
      this.newAlert.appPlatformCode = null;
      this.newAlert.appProductVersion = null;
    },
    async deleteAlert(index) {
      this.bookingChannel.alerts.splice(index, 1);
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
  },
};
</script>

<style scoped>

</style>

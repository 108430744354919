<template>
  <div>
    <v-row class="pt-4">
      <v-col>
        <h4>{{ $t('onboarding.iosLiveActivityNotificationsForm') }}</h4>
      </v-col>
    </v-row>
    <loader color="primary" :is-loading="isLoading">
      <v-col cols="12">
        <v-row>
          <v-col cols="8">
            <v-file-input
              v-model="p8Certificate"
              :label="$t(`onboarding.p8Certificate`)"
              :placeholder="$t('onboarding.p8Certificate')"
              outlined
              hide-details
              no-filter
            >
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="iosLiveActivity.p8TeamId"
              :label="$t(`onboarding.p8TeamId`)"
              :placeholder="$t('onboarding.p8TeamId')"
              outlined
              hide-details
              no-filter
              :validator="$v.iosLiveActivity.p8TeamId"
              >
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="iosLiveActivity.p8AuthKeyId"
              :label="$t(`onboarding.p8AuthKeyId`)"
              :placeholder="$t('onboarding.p8AuthKeyId')"
              outlined
              hide-details
              no-filter
              :validator="$v.iosLiveActivity.p8AuthKeyId"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
          <span v-if="error" class="error--text">
            {{ $t('onboarding.loadCertificateError') }}
          </span>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn
              block
              large
              :disabled="$v.$invalid"
              class="header-button"
              color="primary"
              @click="saveIosLiveActivityConfig">
              {{ $t(`save`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </loader>
  </div>
</template>

<script>
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';
import { decodeP8Certificate } from '@/services/cerficateService';
import iosLiveActivityNotificationForm from '../../../validationDatas/iosLiveActivityNotification';

export default {
  name: 'IOSLiveActivityNotificationForm',
  validations() {
    return iosLiveActivityNotificationForm;
  },
  components: {
  },
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: 'ios',
      isLoading: false,
      p8Certificate: null,
      error: false,
      iosLiveActivity: {
        p8TeamId: (!this.remoteState?.bookingChannel?.connectors?.iosLiveActivity?.p8TeamId) ? '' : this.remoteState.bookingChannel.connectors.iosLiveActivity.p8TeamId,
        p8AuthKeyId: (!this.remoteState?.bookingChannel?.connectors?.iosLiveActivity?.p8AuthKeyId) ? '' : this.remoteState.bookingChannel.connectors.iosLiveActivity.p8AuthKeyId,
      },
    };
  },
  computed: {
  },
  methods: {
    async saveIosLiveActivityConfig() {
      this.isLoading = true;
      try {
        const p8Certificate = await decodeP8Certificate(
          this.p8Certificate,
        );
        const { bookingChannel } = this.remoteState;
        await this.$store.dispatch('bookingChannels/updateBookingChannelIosLiveActivity', {
          payload: {
            ...bookingChannel,
            connectors: {
              ...bookingChannel.connectors,
              iosLiveActivity: {
                ...bookingChannel.connectors.iosLiveActivity,
                ...this.iosLiveActivity,
                p8Certificate,
              },
            },
          },
        });
      } catch (e) {
        this.isLoading = false;
        throw e;
      } finally {
        this.isLoading = false;
      }
      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>

</style>

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';

const defaultState = {
  onboardingApiServiceClient: null,
  updateNetworkInProgress: false,
  saveNetworkInProgress: false,
};

const getters = {
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async getFleetNetworks(context, { page, maxPerPage, dynamicSearch }) {
    const params = {
      page,
      maxPerPage,
      dynamicSearch,
    };
    try {
      const { data, headers } = await context.getters.onboardingApiServiceClient(context)
        .get('/fleet-networks', {
          params,
        });
      return {
        fleetNetworks: data,
        totalItems: parseInt(headers['x-total-count'], 0),
      };
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'fleetNetwork.listFleetNetworksError', error },
        { root: true },
      );
      throw new Error(error);
    }
  },

  async getNetworkFleets(context, {
    networkId, page, maxPerPage, dynamicSearch,
  }) {
    const params = {
      page,
      maxPerPage,
      dynamicSearch,
    };
    try {
      const { data: fleets } = await context.getters.onboardingApiServiceClient(context)
        .get(`/fleet-networks/${networkId}/fleets`, {
          params,
        });
      return fleets;
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'fleetNetwork.listFleetNetworksError', error },
        { root: true },
      );
      throw new Error(error);
    }
  },

  async getFleetNetworkById(context, { id }) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/fleet-networks/${id}`);
      return data;
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'fleetNetwork.getFleetNetworkError', error },
        { root: true },
      );
      throw new Error(error);
    }
  },

  async save(context, networkData) {
    try {
      context.commit('setSaveNetworkInProgress', true);
      const networkId = await context.getters.onboardingApiServiceClient(context)
        .post('/fleet-networks', networkData);
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'network.saveNetworkSuccess' },
        { root: true },
      );
      return networkId;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'network.saveNetworkError', error },
        { root: true },
      );
      return false;
    } finally {
      context.commit('setSaveNetworkInProgress', false);
    }
  },
  async update(context, networkData) {
    try {
      context.commit('setUpdateNetworkInProgress', true);
      const { data: networkId } = await context.getters.onboardingApiServiceClient(context)
        .put(`/fleet-networks/${networkData.id}`, { name: networkData.name });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'network.updateNetworkSuccess' },
        { root: true },
      );
      return networkId;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'network.updateNetworkError', error },
        { root: true },
      );
      return false;
    } finally {
      context.commit('setUpdateNetworkInProgress', false);
    }
  },
  async manageNetworkFleets(context, { networkId, addedFleets = [], removedFleets = [] }) {
    try {
      context.commit('setUpdateNetworkInProgress', true);
      const { data: result } = await context.getters.onboardingApiServiceClient(context)
        .post(`/fleet-networks/${networkId}/fleets`, { addedFleets, removedFleets });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'network.updateNetworkSuccess' },
        { root: true },
      );
      return result;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'network.updateNetworkError', error },
        { root: true },
      );
      return false;
    } finally {
      context.commit('setUpdateNetworkInProgress', false);
    }
  },
};
const mutations = {
  setSaveNetworkInProgress(state, saveNetworkInProgress) {
    state.saveNetworkInProgress = saveNetworkInProgress;
  },
  setUpdateNetworkInProgress(state, updateNetworkInProgress) {
    state.updateNetworkInProgress = updateNetworkInProgress;
  },
};
export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

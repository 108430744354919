import axios from 'axios';
import dayjs from 'dayjs';
import pMap from 'p-map';

const getEnvVariable = function getEnvVariable(variables, key) {
  const envVar = variables.find(variable => variable.key === key);
  return envVar ? envVar.value : 'NA';
};

const mapBuildVariables = function mapBuildVariables(
  newBuilds, builds, client, platform,
) {
  return pMap(newBuilds, async (newBuild) => {
    // eslint-disable-next-line no-shadow
    const existingBuild = builds.find(build => newBuild.id === build.id);
    if (existingBuild) {
      return Object.assign({
        humanReadableId: existingBuild.humanReadableId,
        paymentEnv: existingBuild.paymentEnv,
      }, newBuild);
    }
    const { data: variables } = await client
      .get(`/projects/${process.env[`VUE_APP_GITLAB_${platform.toUpperCase()}_PROJECT_ID`]}/pipelines/${newBuild.id}/variables`);
    const humanReadableId = getEnvVariable(variables, 'HUMAN_READABLE_ID');
    const paymentEnv = getEnvVariable(variables, 'PAYMENT_ENV');
    return Object.assign({ humanReadableId, paymentEnv }, newBuild);
  }, { concurrency: 5 });
};

const defaultState = {
  list: [],
  triggerBuildInProgress: false,
  listBuildsInProgress: false,
  gitlabApiServiceClient: null,
  buildDetailsLoading: false,
  buildDetails: [],
};

const getters = {
  list: state => state.list,
  triggerBuildInProgress: state => state.triggerBuildInProgress,
  listBuildsInProgress: state => state.listBuildsInProgress,
  gitlabApiServiceClient: (state) => {
    if (state.gitlabApiServiceClient === null) {
      state.gitlabApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_GITLAB_API_BASE_URL,
        timeout: 30000,
        headers: { 'PRIVATE-TOKEN': process.env.VUE_APP_GITLAB_API_TOKEN },
      });
    }
    return state.gitlabApiServiceClient;
  },
  buildDetailsLoading: state => state.buildDetailsLoading,
  buildDetails: state => state.buildDetails,
};

const actions = {
  async list(context, { platform }) {
    try {
      context.commit('setListBuildsInProgress', true);
      const { data } = await context.getters.gitlabApiServiceClient
        .get(
          `/projects/${process.env[`VUE_APP_GITLAB_${platform.toUpperCase()}_PROJECT_ID`]}/pipelines`,
          {
            params: {
              updated_after: dayjs().add(-2, 'days').toISOString(),
              ref: process.env.VUE_APP_GITLAB_BUILD_REFERENCE,
            },
          },
        );
      const newBuilds = await mapBuildVariables(
        data, context.getters.list, context.getters.gitlabApiServiceClient, platform,
      );
      context.commit('setBuildsList', newBuilds);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'builds.listBuildsError', error },
        { root: true },
      );
    } finally {
      context.commit('setListBuildsInProgress', false);
    }
  },
  async loadBuildDetails(context, { platform, build }) {
    try {
      context.commit('setBuildDetailsLoading', true);
      const { data } = await context.getters.gitlabApiServiceClient
        .get(`/projects/${process.env[`VUE_APP_GITLAB_${platform.toUpperCase()}_PROJECT_ID`]}/pipelines/${build.id}/jobs`);
      context.commit('setBuildDetails', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'builds.buildDetailsError', error },
        { root: true },
      );
    } finally {
      context.commit('setBuildDetailsLoading', false);
    }
  },
  // eslint-disable-next-line object-curly-newline
  async trigger(context, { bookingChannel, platform, paymentEnv, buildVersion }) {
    try {
      context.commit('setTriggerBuildInProgress', true);
      let ref = process.env.VUE_APP_GITLAB_BUILD_REFERENCE;
      if (buildVersion && buildVersion !== 'latest') {
        if (buildVersion === '3.2.0'
          || buildVersion === '3.2.2'
          || buildVersion === '4.1.4'
          || buildVersion === '4.2.0'
        ) {
          ref = `${buildVersion}-build`;
        } else {
          ref = buildVersion;
        }
      }
      await context.getters.gitlabApiServiceClient
        .post(
          `/projects/${process.env[`VUE_APP_GITLAB_${platform.toUpperCase()}_PROJECT_ID`]}/pipeline`,
          {
            ref,
            variables: [
              { value: bookingChannel.humanReadableId, key: 'HUMAN_READABLE_ID' },
              { value: paymentEnv, key: 'PAYMENT_ENV' },
            ],
          },
        );
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'builds.buildTriggeredSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'builds.triggerBuildsError', error },
        { root: true },
      );
    } finally {
      context.commit('setTriggerBuildInProgress', false);
    }
  },
};

const mutations = {
  setBuildsList(state, builds) {
    state.list = builds;
  },
  setTriggerBuildInProgress(state, triggerBuildInProgress) {
    state.triggerBuildInProgress = triggerBuildInProgress;
  },
  setListBuildsInProgress(state, listBuildsInProgress) {
    state.listBuildsInProgress = listBuildsInProgress;
  },
  setBuildDetails(state, buildDetails) {
    state.buildDetails = buildDetails;
  },
  setBuildDetailsLoading(state, buildDetailsLoading) {
    state.buildDetailsLoading = buildDetailsLoading;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

<template>
  <onboarding-step :step="step" v-if="statePart">
    <v-list>
      <v-list-item v-if="statePart.created">
        <v-text-field
          v-model="statePart.id"
          :label="$t(`onboarding.bookingChannelId`)"
          readonly
          dense
          outlined
        ></v-text-field>
      </v-list-item>
      <v-list-item v-if="!statePart.created">
        <span class="error--text">{{ $t('onboarding.missingBookingChannel') }}</span>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-for="(detail, index) in statePart.details" :key="index">
        <v-list-item-icon>
          <v-icon :color="getColor(detail)">{{ getIcon(detail) }}</v-icon>
        </v-list-item-icon>
        <span>{{ detail }}</span>
      </v-list-item>
    </v-list>
  </onboarding-step>
</template>

<script>
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';
import BookingChannelStepMixin from '../../../mixins/bookingChannelStep.mixin';

export default {
  name: 'booking-channel-step',
  components: { OnboardingStep },
  mixins: [
    // order is important
    OnboardingStepMixin,
    BookingChannelStepMixin,
  ],
  computed: {
    statePartExtended() {
      return this.statePart
        ? Object.assign({}, this.statePart, { inconsistent: this.isCompletedButInconsistent() })
        : null;
    },
  },
};
</script>

<style scoped>

</style>

import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/dist/vuetify.css';
import VueIcons from '@icabbi/vue-icons';

import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VAvatar,
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VContainer,
  VContent,
  VDataTable,
  VDatePicker,
  VCol,
  VRow,
  VDialog,
  VDivider,
  VFlex,
  VForm,
  VIcon,
  VImg,
  VInput,
  VLayout,
  VList,
  VListItem,
  VListItemAction,
  VListItemIcon,
  VListItemTitle,
  VListItemContent,
  VListItemSubtitle,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VProgressCircular,
  VPagination,
  VSelect,
  VSnackbar,
  VSpacer,
  VTextarea,
  VTextField,
  VStepper,
  VStepperContent,
  VStepperStep,
  VStepperItems,
} from 'vuetify/lib';


Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VAvatar,
    VBtn,
    VBtnToggle,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCheckbox,
    VContainer,
    VContent,
    VCol,
    VRow,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VFlex,
    VForm,
    VIcon,
    VImg,
    VInput,
    VLayout,
    VList,
    VListItem,
    VListItemAction,
    VListItemIcon,
    VListItemTitle,
    VListItemContent,
    VListItemSubtitle,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VProgressCircular,
    VPagination,
    VSelect,
    VSnackbar,
    VSpacer,
    VStepper,
    VStepperContent,
    VStepperStep,
    VStepperItems,
    VTextarea,
    VTextField,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: VueIcons.allIcons(),
  },
  theme: {
    themes: {
      light: {
        primary: '#000000',
        secondary: '#FFFFFF',
        anchor: '#D4D4D4',
      },
    },
  },
});

// eslint-disable-next-line import/prefer-default-export
export class SafeAwait {
  static async execute(callable) {
    try {
      const result = await callable();
      return {
        result,
      };
    } catch (e) {
      return {
        error: e,
      };
    }
  }
}

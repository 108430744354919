<template>
  <div>
    <v-row>
      <v-col>
        <v-list
          align-start>
          <v-list-item
            align-start
            v-for="(detail, key) in appSigningCheckState.details"
            :key="key">
            <v-list-item-icon>
              <v-icon :color="getColor(detail.completed)">
                {{ getIcon(detail.completed) }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ key }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle
                align="start"
                v-if="!detail.completed">
                {{ detail.detail }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-btn-toggle
          exclusive
          mandatory
          v-model='appSigningOption'>
          <v-btn>
            {{ $t(`Upload`) }}
          </v-btn>
          <v-btn>
            {{ $t(`Generate`) }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <component
        @upload-android-keystore="uploadAndroidKeystore"
        @generate-android-keystore="generateAndroidKeystore"
        :is="selectedAppSigningComponent">
      </component>
    </v-row>
  </div>
</template>

<script>
import AppSigningGenerateForm from '@/components/onboarding/steps/appSigning/AppSigningGenerateForm.vue';
import AppSigningUploadForm from '@/components/onboarding/steps/appSigning/AppSigningUploadForm.vue';
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';

export default {
  name: 'AppSigningForm',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AppSigningGenerateForm,
    // eslint-disable-next-line vue/no-unused-components
    AppSigningUploadForm,
  },
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appSigningOption: 0,
    };
  },
  computed: {
    selectedAppSigningComponent() {
      return this.appSigningOption === 0 ? 'app-signing-upload-form' : 'app-signing-generate-form';
    },
    appSigningCheckState() {
      return this.remoteState.checkState.appSigning;
    },
  },
  methods: {
    async uploadAndroidKeystore(keystore) {
      this.$store.dispatch('bookingChannelView/showLoader');
      try {
        await this.$store.dispatch('appSigning/uploadAndroidKeystore', {
          bookingChannelId: this.remoteState.bookingChannel.id,
          appSigning: keystore,
          throwFail: true,
        });
      } catch (e) {
        this.$store.dispatch('bookingChannelView/hideLoader');
        throw e;
      }
      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
    },
    async generateAndroidKeystore() {
      await this.$store.dispatch('appSigning/generateAndroidKeystore', {
        humanReadableId: this.remoteState.bookingChannel.humanReadableId,
        throwFail: true,
      });
    },
  },
};
</script>

<style scoped>

</style>

import { mapGetters } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const globalErrorsComputed = {
  computed: {
    ...mapGetters({
      errors: 'globalMessages/errors',
    }),
  },
};

// eslint-disable-next-line import/prefer-default-export
export const globalSuccessesComputed = {
  computed: {
    ...mapGetters({
      successes: 'globalMessages/successes',
    }),
  },
};

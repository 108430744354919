<template>
  <div>
    <booking-channel-list @onCreateClick="displayCreateDialog" @loadData="getBookingChannels" :is-loading="bookingChannelsLoading" :total-items="totalBookingChannels" :items="bookingChannels" @viewClick="viewBookingChannelClick($event)"></booking-channel-list>
    <booking-channel-json-dialog @onDismiss="closeBookingChannelDialog" :display="isDisplayJsonDialog" :booking-channel="selectedBookingChannel"></booking-channel-json-dialog>
    <create-booking-channel-dialog v-if="isCreateDialogShown" @onDismiss="closeCreateDialog" :display="isCreateDialogShown"></create-booking-channel-dialog>
  </div>
</template>

<script>
import BookingChannelList from '@/components/BookingChannelList.vue';
import BookingChannelJsonDialog from '@/components/bookingChannel/BookingChannelJsonDialog.vue';
import CreateBookingChannelDialog from '@/components/bookingChannel/CreateBookingChannelDialog.vue';

export default {
  name: 'BookingChannelsView',
  data() {
    return {
      selectedBookingChannel: null,
      bookingChannels: [],
      bookingChannelsLoading: true,
      totalBookingChannels: 0,
      isDisplayJsonDialog: false,
      isCreateDialogShown: false,
    };
  },
  components: {
    BookingChannelList,
    BookingChannelJsonDialog,
    CreateBookingChannelDialog,
  },
  methods: {
    displayCreateDialog() {
      this.isCreateDialogShown = true;
    },
    closeCreateDialog() {
      this.isCreateDialogShown = false;
      this.getBookingChannels({
        page: 0,
        maxPerPage: 10,
      });
    },
    closeBookingChannelDialog() {
      this.isDisplayJsonDialog = false;
      this.selectedBookingChannel = null;
    },
    async viewBookingChannelClick(bookingChannel) {
      await this.$router.push(`/booking-channels/${bookingChannel.id}`);
    },
    displayBookingChannelJson(bookingChannel) {
      this.selectedBookingChannel = bookingChannel;
      this.isDisplayJsonDialog = true;
    },
    async getBookingChannels({
      page, maxPerPage, dynamicSearch,
    }) {
      this.bookingChannelsLoading = true;
      const { bookingChannels, totalBookingChannels } = await this.$store.dispatch('bookingChannels/getBookingChannels', {
        page, maxPerPage, dynamicSearch,
      });
      this.bookingChannels = bookingChannels;
      this.totalBookingChannels = totalBookingChannels;
      this.bookingChannelsLoading = false;
    },
  },
};
</script>

<style scoped>

</style>

<template>
    <v-list-item href="javascript:void(0)">
      <v-list-item-content>
        <v-menu offset-x>
          <template v-slot:activator="{ on }">
            <v-list-item-title
              v-on="on">
              <span>{{ $t(item.label) }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-title>
            <v-list-item-subtitle
              v-text="$t(`languages.${selectedLanguage}`)">
            </v-list-item-subtitle>
          </template>
          <v-list>
            <v-list-item
              v-for="language in languages"
              :key="language.id"
              @click="selectLanguage(language.id)">
              <v-list-item-content>
                <v-list-item-title v-text="language.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-content>
    </v-list-item>
</template>

<script>
import { languageMethods, languageComputed } from '../mixins/language.mixins';

export default {
  name: 'LanguageSelector',
  mixins: [languageComputed, languageMethods],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>

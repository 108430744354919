import * as _ from 'lodash';

import {
  minLength, maxLength, required, url, numeric, requiredIf, decimal, integer, between,
} from 'vuelidate/lib/validators';

import { HiDash } from '@/util/HiDash';
import { WaitingTimeTypes } from '@/core/waitingTimeTypes';
import {
  isValidString,
  isValidLocale,
  isValidPaymentProvider,
  isValidPhoneNumber,
  isValidMarketingProvider,
  isValidPreauthType,
  isValidMarketingInstance,
  isValidCountry,
  isValidCurrency,
  isValidExternalFee,
  isBoolean,
  isArray,
} from '../../validation/validationMethods';

export const ic2DispatchAttributesValidation = {
  baseURL: {
    minLength: minLength(1),
    maxLength: maxLength(100),
    url,
    required,
  },
  dispatchId: {
    minLength: minLength(1),
    maxLength: maxLength(100),
    isValidString,
    required,
  },
  apiCredentials: {
    key: {
      minLength: minLength(1),
      maxLength: maxLength(100),
      isValidString,
      required,
    },
    secret: {
      minLength: minLength(1),
      maxLength: maxLength(100),
      isValidString,
      required,
    },
  },
  gatewayApiCredentials: {
    username: {
      minLength: minLength(32),
      required,
    },
    password: {
      minLength: minLength(64),
      required,
    },
  },
};

export const ic2FleetPropertiesValidation = {
  siteId: {
    minLength: minLength(1),
    maxLength: maxLength(50),
  },
  clientIx: {
    minLength: minLength(1),
    maxLength: maxLength(50),
    required,
  },
};

export const isFeeAmountCompatibleWithDriverShare = ({ feeAmount, driverShare }) => {
  try {
    if (feeAmount || driverShare) {
      return (_.toNumber(feeAmount)) >= (_.toNumber(driverShare));
    }
    return true;
  } catch (e) {
    // Either "value" or "driverShare" is not a number.
    return false;
  }
};

export const fleetValidation = {
  fleet: {
    humanReadableId: {
      minLength: minLength(1),
      maxLength: maxLength(50),
      isValidString,
      required,
    },
    country: {
      minLength: minLength(1),
      maxLength: maxLength(4),
      isValidCountry,
      required,
    },
    locale: {
      minLength: minLength(1),
      maxLength: maxLength(50),
      isValidLocale,
      required,
    },
    paymentAccountId: {
      minLength: minLength(1),
      maxLength: maxLength(50),
      isValidString,
      required,
    },
    paymentFailureAccountId: {
      minLength: minLength(1),
      maxLength: maxLength(50),
      isValidString,
      required,
    },
    ivrPhoneNumber: {
      minLength: minLength(11),
      maxLength: maxLength(16),
      isValidPhoneNumber,
    },
    supportPhoneNumber: {
      minLength: minLength(11),
      maxLength: maxLength(16),
      isValidPhoneNumber,
    },
    driverConnectPhoneNumber: {
      minLength: minLength(11),
      maxLength: maxLength(16),
      isValidPhoneNumber,
    },
    dataRegion: {
      required,
      minLength: minLength(1),
    },
    connectors: {
      payment: {
        $each: {
          name: {
            minLength: minLength(1),
            maxLength: maxLength(50),
            isValidPaymentProvider,
            required,
          },
          instance: {
            minLength: minLength(1),
            maxLength: maxLength(50),
            required,
          },
          configuration: {
            fees: {
              fixed: {
                numeric,
                required,
              },
              percentage: {
                required,
              },
            },
            accountId: {
              minLength: minLength(1),
              maxLength: maxLength(100),
              isValidString,
              required,
            },
            statementDescriptor: {
              minLength: minLength(1),
              maxLength: maxLength(22),
              isValidString,
              required,
            },
            managedFees: {
              $each: {
                isValidExternalFee,
              },
            },
          },
        },
      },
      marketing: {
        name: {
          minLength: minLength(1),
          maxLength: maxLength(50),
          isValidMarketingProvider,
          required,
        },
        configuration: {
          applicationId: {
            minLength: minLength(1),
            maxLength: maxLength(100),
            required,
          },
          clientSecret: {
            minLength: minLength(1),
            maxLength: maxLength(100),
            required,
          },
          instance: {
            minLength: minLength(1),
            maxLength: maxLength(50),
            isValidMarketingInstance,
            required,
          },
        },
      },
    },
    taxes: {
      enabled: {},
      country: {
        isValidString,
        minLength: minLength(1),
        maxLength: maxLength(10),
      },
      region: {
        isValidString,
        minLength: minLength(1),
        maxLength: maxLength(10),
      },
      numbers: {
        gst: {
          isValidString,
          minLength: minLength(1),
          maxLength: maxLength(5),
        },
        vat: {
          isValidString,
          minLength: minLength(1),
          maxLength: maxLength(5),
        },
        qst: {
          isValidString,
          minLength: minLength(1),
          maxLength: maxLength(5),
        },
        pst: {
          isValidString,
          minLength: minLength(1),
          maxLength: maxLength(5),
        },
      },
    },
    credentials: {
      required,
    },
    preauthorizationRules: {
      required,
      $each: {
        type: {
          required,
          isValidPreauthType,
        },
        margin: {
          required: requiredIf((object => object.type !== 'FIXED')),
          base: {
            isValidString,
          },
          value: {
            required: requiredIf(object => typeof object !== 'undefined'),
          },
        },
        minimalAmount: {
          required: requiredIf((object => object.type !== 'FIXED')),
        },
        fareEstimateAmount: {
          required,
        },
        fixedAmount: {
          required: requiredIf((object => object.type === 'FIXED')),
        },
      },
    },
    legalInformation: {
      officialCompanyName: {
        isValidString,
      },
      companyAddress: {
        isValidString,
      },
      vatNumber: {
        isValidString,
      },
    },
    dispatchInformation: {
      companyName: {
        isValidString,
        required,
      },
      currency: {
        isValidCurrency,
        required,
      },
      timezone: {
        required,
      },
      dispatchFleetUuid: {
        required,
      },
      dispatchFleetUID: {
        required,
      },
      dispatchFleetIx: {
        required,
      },
      coordinates: {
        latitude: {
          decimal,
          required,
        },
        longitude: {
          required,
          decimal,
        },
      },
    },
    networkCoordinates: {
      latitude: {
        decimal,
        required,
      },
      longitude: {
        required,
        decimal,
      },
    },
    networkRadius: {
      required,
      decimal,
    },
    allowedPaymentMethods: {
      required,
      minLength: minLength(1),
      $each: {
        type: {
          required,
          minLength: minLength(1),
        },
      },
    },
    sendOnBehalfOf: {
      required,
      isBoolean,
    },
    fees: {
      prematureCancellationFee: {
        cancellationFee: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            if (this.fleet.fees.prematureCancellationFee.cancellationFee) {
              return false;
            }
            if (
              this.fleet.fees.prematureCancellationFee.driverShare
              || this.fleet.fees.prematureCancellationFee.chargeablePostDriverAssignedDurationMinutes
              || this.fleet.fees.prematureCancellationFee.exemptDriverAssignmentDurationMinutes
            ) {
              return true;
            }
            return false;
          }),
          decimal,
          between: between(0, 20),
        },

        driverShare: {
          // eslint-disable-next-line func-names
          // required: requiredIf(function () {
          //   if (this.fleet.fees.prematureCancellationFee.driverShare) {
          //     return false;
          //   }
          //   if (this.fleet.fees.prematureCancellationFee.cancellationFee) {
          //     return true;
          //   }
          //   return false;
          // }),
          decimal,
          isDriverShareCompatibleWithFeeAmount: function isDriverShareCompatibleWithFeeAmount(value) {
            if (!_.isNil(this.fleet.fees.prematureCancellationFee.cancellationFee)) {
              const driverShare = HiDash.zeroIfNil(value);
              return isFeeAmountCompatibleWithDriverShare({
                feeAmount: this.fleet.fees.prematureCancellationFee.cancellationFee,
                driverShare,
              });
            }
            return true;
          },
        },

        chargeablePostDriverAssignedDurationMinutes: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            return HiDash.isGreaterThanZero(this.fleet.fees.prematureCancellationFee.cancellationFee);
          }),
          integer,
          between: between(1, 20),
        },
        exemptDriverAssignmentDurationMinutes: {
          required: requiredIf(function () {
            return HiDash.isGreaterThanZero(this.fleet.fees.prematureCancellationFee.cancellationFee);
          }),
          integer,
          between: between(1, 20),
        },
      },

      passengerNoShowFeeConfig: {
        noShowFee: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            if (this.fleet.fees.passengerNoShowFeeConfig.noShowFee) {
              return false;
            }
            if (this.fleet.fees.passengerNoShowFeeConfig.driverShare
              || this.fleet.fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationMinutes
              || this.fleet.fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaMinutes
              || this.fleet.fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalMinutes) {
              return true;
            }
            return false;
          }),
          decimal,
          between: between(0, 20),
          isFeeAmountCompatibleWithDriverShare: function isNoShowFeeAmountCompatibleWithDriverShare(value) {
            return isFeeAmountCompatibleWithDriverShare({
              feeAmount: value,
              driverShare: this.fleet.fees.passengerNoShowFeeConfig.driverShare,
            });
          },
        },

        driverShare: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            if (this.fleet.fees.passengerNoShowFeeConfig.driverShare) {
              return false;
            }
            if (this.fleet.fees.passengerNoShowFeeConfig.noShowFee) {
              return true;
            }
            return false;
          }),
          decimal,
          between: between(0, 20),
          isDriverShareCompatibleWithFeeAmount: function isDriverShareCompatibleWithFeeAmount(value) {
            if (!_.isNil(this.fleet.fees.passengerNoShowFeeConfig.noShowFee)) {
              return isFeeAmountCompatibleWithDriverShare({
                feeAmount: this.fleet.fees.passengerNoShowFeeConfig.noShowFee,
                driverShare: value,
              });
            }
            return true;
          },
        },

        exemptAsapDriverArrivalDurationMinutes: {
          integer,
          between: between(1, 60),
          required: requiredIf(function () {
            if (this.localFleetModel.fees.noShowFee.exemptAsapDriverArrivalDurationConfig.isEnabled) {
              return true;
            }
            return false;
          }),
        },

        prebookingMaxPickupToArrivalDeltaMinutes: {
          integer,
          between: between(1, 60),
          required: requiredIf(function () {
            if (this.localFleetModel.fees.noShowFee.preBookingMaxPickupToArrivalDeltaConfig.isEnabled) {
              return true;
            }
            return false;
          }),
        },

        requiredDriverWaitingDurationAfterArrivalMinutes: {
          integer,
          between: between(1, 60),
          required: requiredIf(function () {
            if (this.localFleetModel.fees.noShowFee.requiredDriverWaitingDurationAfterArrivalConfig.isEnabled) {
              return true;
            }
            return false;
          }),
        },
      },
      // FIXME FEE: "passengerNoShowFee" is Deprecated
      passengerNoShowFee: {
        decimal,
        between: between(0, 20),
      },
    },
    driverBookingInfoDisplayConfiguration: {
      showDriverReference: {
        required,
        isBoolean,
      },
      driverNameLabel: {
        required,
        isValidString,
      },
      showDriverImage: {
        required,
        isBoolean,
      },
      showRegistrationPlate: {
        required,
        isBoolean,
      },
    },
    receiptConfig: {
      disableForPayments: {
        isArray,
      },
      useDefaultTaxMessage: {
        isBoolean,
      },
      customTaxesMessage: {
        isValidString,
        required: requiredIf(function () {
          if (!this.fleet.receiptConfig.useDefaultTaxMessage) {
            return true;
          }
          return false;
        }),
      },
    },
    dispatchEngineCode: {
      required,
      isValidString,
    },
    waitingTimeConfiguration: {
      isEnabled: {
        isBoolean,
      },
      selectionType: {
        required: requiredIf((object => object.isEnabled)),
        isValidString,
      },
      timeThreshold: {
        required: requiredIf((object => object.isEnabled && object.selectionType === WaitingTimeTypes.CUSTOM)),
        integer,
      },
    },
    pricingDisplayForPaymentMethodsConfiguration: {
      cash: {
        isEnabled: {
          isBoolean,
        },
      },
      businessDispatchInvoice: {
        isEnabled: {
          isBoolean,
        },
      },
    },
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Onboarding from '../views/Onboarding.vue';
import GetStarted from '../views/GetStarted.vue';
import FleetManagement from '../views/FleetManagement.vue';
import BuildsView from '../views/AppBuildManagementView.vue';
import CustomerData from '../views/CustomerData.vue';
import Trackmytaxi from '../views/Trackmytaxi.vue';
import OAuthCallback from '../views/auth/OAuthCallback.vue';
import OAuthLogout from '../views/auth/OAuthLogout.vue';
import BookingChannelsView from '../views/BookingChannelsView.vue';
import FleetsView from '../views/FleetsView.vue';
import BookingChannelView from '../views/bookingChannel/BookingChannelView.vue';
import JotFormsView from '../views/JotFormsView.vue';
import FinancialSettingsView from '../views/financialSettings/FinancialSettingsView.vue';
import NetworksView from '../views/NetworksView.vue';
import NetworkFleetsView from '../views/NetworkFleetsView.vue';
import routeGuard from './routingHelper';

// eslint-disable-next-line no-unused-vars
function checkIfLoggedIn(to, from, next) {
  const token = store.getters['authentication/token'];
  if (token) {
    next('/booking-channels');
    return;
  }
  next();
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: GetStarted,
    beforeEnter: checkIfLoggedIn,
  },
  {
    path: '/onboard',
    name: 'onboarding',
    component: Onboarding,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/fleet-management',
    name: 'fleet-management',
    component: FleetManagement,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/builds',
    name: 'builds-view',
    component: BuildsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/customer-data',
    name: 'customer-data',
    component: CustomerData,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/jot-forms',
    name: 'jot-forms',
    component: JotFormsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/booking-channels',
    name: 'booking-channels',
    component: BookingChannelsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/booking-channels/:id',
    name: 'booking-channel-route',
    component: BookingChannelView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/fleets',
    name: 'fleets',
    component: FleetsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/fleets/new',
    name: 'fleet-management-cew',
    component: FleetManagement,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/fleets/:id',
    name: 'fleet-management-by-id',
    component: FleetManagement,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/financial-settings',
    name: 'financial-settings',
    component: FinancialSettingsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/trackmytaxi',
    name: 'trackmytaxi',
    component: Trackmytaxi,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/networks',
    name: 'networks',
    component: NetworksView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/networks/:id/fleets',
    name: 'network-fleets',
    component: NetworkFleetsView,
    meta: { hasNavigation: true },
    beforeEnter: routeGuard,
  },
  {
    path: '/oauth/callback',
    name: 'oauth-callback',
    component: OAuthCallback,
  },
  {
    path: '/logout',
    name: 'logout',
    component: OAuthLogout,
  },
  {
    path: '*',
    redirect: '/',
    beforeEnter: routeGuard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';

const defaultState = {
  onboardingApiServiceClient: null,
  gitlabApiServiceClient: null,
  paymentMethodsSaveInProgress: false,
};

const getters = {
  paymentMethodsSaveInProgress: state => state.paymentMethodsSaveInProgress,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async save(context, { allowedPaymentMethods, bookingChannelId }) {
    try {
      context.commit('setPaymentMethodsSaveInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/payment-methods', { allowedPaymentMethods, bookingChannelId });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.paymentMethods.saveSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.paymentMethods.saveError', error },
        { root: true },
      );
    } finally {
      context.commit('setPaymentMethodsSaveInProgress', false);
    }
  },
};

const mutations = {
  setPaymentMethodsSaveInProgress(state, paymentMethodsSaveInProgress) {
    state.paymentMethodsSaveInProgress = paymentMethodsSaveInProgress;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

<template>
  <onboarding-step :step="step">
    <loader color="primary" :is-loading="waitingTimesUploadInProgress">
      <v-col cols='12'>
        <v-row>
          <v-col cols="12">
            <h4>{{ $t('onboarding.waitingTime.waitingTimesUpload') }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col col="4">
            <autocomplete
              :label="$t('onboarding.waitingTime.fleets')"
              :loading='listFleetInProgress'
              :items='fleetList'
              itemsName='humanReadableId'
              outlined
              hide-details
              no-filter
              :placeholder="$t('onboarding.waitingTime.autocomplete')"
              @searchAutocomplete='listFleets'
              @updateModel='setFleet'
            ></autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="fleet.id"
              :label="$t(`onboarding.waitingTime.fleet`)"
              readonly
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-file-input
              accept="text/csv"
              v-model="waitingTimes.file"
              :label="$t(`onboarding.waitingTime.waitingTimesCsv`)"
              :placeholder="$t(`onboarding.waitingTime.waitingTimesCsv`)"
              outlined
              hide-details
              no-filter
              show-size>
            </v-file-input>
            <v-alert v-if="csvInvalid" class="red--text">{{ $t(`onboarding.waitingTime.csvToBig`) }}</v-alert>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn
              block
              large
              :disabled="invalid"
              class="header-button"
              color="primary"
              @click="uploadWaitingTimes">
              {{ $t(`upload`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </loader>
  </onboarding-step>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'waiting-time-step',
  components: {
    OnboardingStep,
  },
  mixins: [OnboardingStepMixin],
  data() {
    return {
      waitingTimes: {
        file: null,
      },
      fleet: {},
    };
  },
  computed: {
    ...mapGetters({
      fleetList: 'fleets/list',
      listFleetInProgress: 'fleets/listFleetInProgress',
      waitingTimesUploadInProgress: 'waitingTimes/waitingTimesUploadInProgress',
    }),
    invalid() {
      return !this.fleet.id || !this.waitingTimes.file || this.csvInvalid;
    },
    csvInvalid() {
      return _.get(this.waitingTimes, 'file.size') > 2000000;
    },
  },
  methods: {
    setFleet(fleetData) {
      this.fleet = Object.assign({}, fleetData);
    },
    listFleets(dynamicSearch) {
      this.$store.dispatch('fleets/list', dynamicSearch);
    },
    async uploadWaitingTimes() {
      await this.$store.dispatch('waitingTimes/uploadWaitingTimes', {
        waitingTimes: this.waitingTimes,
        fleet: this.fleet,
      });
      this.fleet = {};
      this.waitingTimes.file = null;
    },
  },
};
</script>

<style scoped>

</style>

const ObjectMapper = require('object-mapper');

const TO_BOOKING_CHANNEL = {
  name: 'name',
  currencySymbol: 'currencySymbol',
  defaultCountry: 'defaultCountry',
  currency: 'preAuthorization.currency',
  longitude: 'location.longitude',
  latitude: 'location.latitude',
  communicationLanguage: 'communicationLanguage',
  defaultTipBase: 'defaultTip.defaultTipBase',
  defaultTipValue: 'defaultTip.defaultTipValue',
};

// eslint-disable-next-line import/prefer-default-export
export const JotFormMapper = {
  mapToBookingChannel(jotForm) {
    return ObjectMapper(jotForm, TO_BOOKING_CHANNEL);
  },
};

import { render, staticRenderFns } from "./GlobalError.vue?vue&type=template&id=1fff9a94&scoped=true&"
import script from "./GlobalError.vue?vue&type=script&lang=js&"
export * from "./GlobalError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fff9a94",
  null
  
)

export default component.exports
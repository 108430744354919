<template>
  <v-container>
    <jot-form-dialog
      v-if="showModal"
      :type="itemCreatePopup"
      :customerData="customerInfo"
      :raw-jot-form="rawJotForm"
      @close="closeModal"
    ></jot-form-dialog>
    <v-data-table
      :sort-by="['createdAt', 'jotForm.businessName']"
      :sort-desc="[true, false]"
      :headers="headers"
      :items="customers"
      :loading="customerDataLoading"
      :items-per-page="5000"
      :search="search"
      hide-default-footer
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="space-between">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
                Jot Form Submissions
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-text-field
                  v-model="search"
                  append-icon="$icons_system_search"
                  :label="'Search'"
                  single-line
                  clearable
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:item.id="{ item }">
        <string-copy-tooltip truncate :text="item.id"></string-copy-tooltip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn small color="primary" @click="viewClick(item)">
          View
        </v-btn>
      </template>
    </v-data-table>

    <div class="text-right pt-2">
      <div class="my-2">
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!cursors.length"
          @click="loadPrev()"
        >
          <v-icon small>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!cursor"
          @click="loadNext()"
        >
          <v-icon small>mdi-skip-next</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import JotFormDialog from '@/components/customerData/JotFormDialog.vue';
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';

export default {
  name: 'jot-forms-view',
  components: { JotFormDialog, StringCopyTooltip },
  data: () => ({
    cursors: [],
    itemInfoPopup: false,
    itemCreatePopup: false,
    customerInfo: {},
    humanReadableIdFilter: '',
    showImportModal: false,
    rawJotForm: null,
    search: null,
  }),
  computed: {
    ...mapGetters({
      customers: 'customerData/customers',
      customerDataLoading: 'customerData/customerDataListingInProgress',
      total: 'customerData/total',
      cursor: 'customerData/cursor',
      count: 'customerData/count',
    }),
    showModal() {
      return this.itemCreatePopup === 'edit' || this.itemCreatePopup === 'create';
    },
    headers() {
      return [
        { text: 'ID', align: 'start', value: 'id' },
        {
          text: 'Company ID',
          value: 'jotForm.companyId',
        },
        {
          text: 'Name',
          value: 'jotForm.name',
        },
        {
          text: 'Business Name',
          value: 'jotForm.businessName',
        },
        {
          text: 'Created (UTC)',
          value: 'createdAt',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('customerData/list', { humanReadableId: this.humanReadableIdFilter });
    },
    // async showItem(item) {
    //   if (!this.customerDataLoading) {
    //     await this.$store.dispatch('customerData/item', item.id);
    //     this.customerInfo = this.$store.getters['customerData/customer'];
    //     this.itemInfoPopup = true;
    //   }
    // },
    // async createItem() {
    //   if (!this.customerDataLoading) {
    //     this.customerInfo = {};
    //     this.itemCreatePopup = 'create';
    //   }
    // },
    async editItem(item) {
      if (!this.customerDataLoading) {
        await this.$store.dispatch('customerData/item', item.id);
        this.customerInfo = this.$store.getters['customerData/customer'];
        this.itemCreatePopup = 'edit';
      }
    },
    async viewClick(item) {
      this.customerInfo = item.jotForm;
      this.rawJotForm = JSON.parse(item.rawData);
      this.itemCreatePopup = 'edit';
    },
    async loadNext() {
      this.cursors.push(this.cursor);
      await this.$store.dispatch('customerData/list', { cursor: this.cursor, humanReadableId: this.humanReadableIdFilter });
    },
    async loadPrev() {
      this.cursors.pop();
      await this.$store.dispatch('customerData/list', { cursor: this.cursors[this.cursors.length - 1], humanReadableId: this.humanReadableIdFilter });
    },
    debounceInput: debounce(function (value) {
      this.humanReadableIdFilter = value;
    }, 500),
    closeModal() {
      this.itemCreatePopup = false;
    },
  },
  watch: {
    humanReadableIdFilter() {
      this.initialize();
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

<template>
  <v-dialog
    max-width="750"
    v-if="display"
    :value="true"
    scrollable
    persistent
  >
    <v-card class="mx-auto">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text style="height: 100%; overflow-y: hidden;">
        <div class="json-preview">
          <v-btn
            icon
            @click="copyToClipboard"
            text-color="black"
            class="copy-icon-link">
            <v-icon>$icons_system_clipboard_copy</v-icon>
          </v-btn>
          <pre>{{ json }}</pre>
        </div>
        <input style="display: none;" id="clipboard-copy-input" type="text"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import JsonUtil from '@/util/jsonUtil';

export default {
  name: 'BookingChannelJsonDialog',
  props: {
    title: {
      required: true,
    },
    object: {
      required: true,
    },
    display: {
      required: true,
    },
  },
  data() {
    return {
      test: '"YOOO OTEST ADADAAD"',
    };
  },
  methods: {
    dismiss() {
      this.$emit('onDismiss');
    },
    copyToClipboard() {
      // https://github.com/euvl/v-clipboard/issues/18
      const el = document.createElement('textarea');
      el.addEventListener('focusin', e => e.stopPropagation());
      el.value = this.json;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$store.dispatch(
        'globalMessages/addSuccess',
        { message: 'Copied to clipboard' },
      );
    },
  },
  computed: {
    json() {
      return JsonUtil.jsonStringifySorted(this.object);
    },
  },
};
</script>

<style scoped lang="scss">
  .copy-icon-link {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .json-preview {
    position: relative;
    padding: 1.2rem;
    margin: 1.2rem 0;
    background: #f9f9f9;
    border-radius: 4px;
    max-height: 100%;
    overflow-y: scroll;
    pre {
      font-weight: 500;
    }
  }
</style>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="listData.data"
      :loading="isLoading"
      :items-per-page="5000"
      hide-default-footer
      :sort-by="['createdAt', 'jotForm.businessName']"
      :sort-desc="[true, false]"
      :search="search"
      class="ic-table elevation-0 ic-table--heavy-headers"
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="space-between">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
                Jot Forms
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-text-field
                  class="mr-4"
                  v-model="search"
                  append-icon="$icons_system_search"
                  :label="'Search'"
                  single-line
                  clearable
                  dense
                  outlined
                  hide-details
                ></v-text-field>
                <v-btn class="error header-list-button" @click="closeClick" >
                  Close
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="font-weight-black black--text">{{ header.text }}</span>
      </template>
      <template v-slot:item.id="{ item }">
        <string-copy-tooltip truncate :text="item.id"></string-copy-tooltip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn small color="primary" @click="importClick(item)">
          Import
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-8">
      <div class="my-2">
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!cursors.length"
          @click="loadPrev()"
        >
          <v-icon small>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!listData.cursor"
          @click="loadNext()"
        >
          <v-icon small>mdi-skip-next</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';


export default {
  name: 'CustomerDataList',
  components: { StringCopyTooltip },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    listData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: null,
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 5000,
      loading: true,
      cursors: [],
    };
  },
  async created() {
    await this.$emit('loadData', { cursor: undefined });
  },
  methods: {
    async loadPrev() {
      this.cursors.pop();
      await this.$emit('loadData', { cursor: this.cursors[this.cursors.length - 1] });
    },
    async loadNext() {
      this.cursors.push(this.listData.cursor);
      await this.$emit('loadData', { cursor: this.listData.cursor });
    },
    importClick(row) {
      this.$emit('importForm', {
        id: row.id,
        ...row.jotForm,
      });
    },
    closeClick() {
      this.$emit('close');
    },
  },
  computed: {
    headers() {
      return [{
        text: 'ID',
        align: 'left',
        sortable: false,
        value: 'id',
      }, {
        text: 'Company ID',
        value: 'jotForm.companyId',
      }, {
        text: 'Name',
        value: 'jotForm.name',
      }, {
        text: 'Business Name',
        value: 'jotForm.businessName',
      }, {
        text: 'Created (UTC)',
        value: 'createdAt',
      }, {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false,
      }];
    },
  },
};
</script>

<style scoped>

</style>

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';

const defaultState = {
  onboardingApiServiceClient: null,
  gitlabApiServiceClient: null,
  waitingTimesUploadInProgress: false,
};

const getters = {
  waitingTimesUploadInProgress: state => state.waitingTimesUploadInProgress,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async uploadWaitingTimes(context, { waitingTimes, fleet }) {
    try {
      context.commit('setWaitingTimesUploadInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/waiting-times', waitingTimes.file, {
          headers: {
            'Content-Type': 'text/csv',
          },
          params: {
            fleetId: fleet.id,
            fleetHumanReadableId: fleet.humanReadableId,
          },
        });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.waitingTime.uploadSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.waitingTime.uploadError', error },
        { root: true },
      );
    } finally {
      context.commit('setWaitingTimesUploadInProgress', false);
    }
  },
};

const mutations = {
  setWaitingTimesUploadInProgress(state, waitingTimesUploadInProgress) {
    state.waitingTimesUploadInProgress = waitingTimesUploadInProgress;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

// eslint-disable-next-line import/prefer-default-export
import { mapGetters } from 'vuex';

export const initLocale = {
  beforeMount() {
    const locale = this.$store.getters['language/selectedLanguage'];
    this.$store.dispatch('language/setSelectedLanguage', locale);
  },
};

export const languageMethods = {
  methods: {
    getDefaultLanguage() {
      return 'en-uk';
    },
    languageIsAvailable(language) {
      return (this.languages.map(l => l.id).includes(language.toLowerCase()));
    },
    async selectLanguage(language) {
      return this.$store.dispatch('language/setSelectedLanguage', (this.languageIsAvailable(language) ? language.toLowerCase() : this.getDefaultLanguage()));
    },
  },
};

export const languageComputed = {
  computed: {
    ...mapGetters({
      storedLanguage: 'language/selectedLanguage',
    }),
    selectedLanguage() {
      if (this.languageIsAvailable(this.storedLanguage)) return this.storedLanguage;
      return this.getDefaultLanguage();
    },
    languages() {
      return this.$i18n.availableLocales
        .map(language => ({ id: language, name: this.$i18n.t(`languages.${language}`) }));
    },
  },
};

<template>
  <div class="vehicle_icon_container">
    <v-row class="mt-2" no-gutters>
      <v-col cols="12" class="px-1 mb-2">
        <strong class="vehicle_alias">{{ getVehicleTypeHeading() }}</strong>
      </v-col>
      <!--Map Icon-->
      <v-col cols="6" class="px-1">
        <vehicle-type-icon :vehicle-type-icon.sync="vehicleType.mapIcon" :type="$t('fleetManagementForm.fleetBookingAppConfig.vehicleIcons.mapLabel')"></vehicle-type-icon>
      </v-col>
      <!--List Icon-->
      <v-col cols="6" class="px-1">
        <vehicle-type-icon :vehicle-type-icon.sync="vehicleType.listIcon" :type="$t('fleetManagementForm.fleetBookingAppConfig.vehicleIcons.listLabel')"></vehicle-type-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VehicleTypeIcon from '@/components/fleetManagement/FleetBookingAppConfig/VehicleTypeIcon.vue';

const DEFAULT_VEHICLE_TYPE_KEY = 'pax_default_override';

export default {
  props: {
    vehicleType: {
      type: Object,
      required: true,
    },
  },
  components: {
    VehicleTypeIcon,
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {
    getVehicleTypeHeading() {
      if (this.vehicleType.vehicleType === DEFAULT_VEHICLE_TYPE_KEY) {
        return this.vehicleType.vehicleTitle;
      }
      return `${this.vehicleType.vehicleTitle} - ${this.vehicleType.vehicleType}`;
    },
  },
};
</script>

<style scoped>
.vehicle_icon_container .vehicle_alias{
  font-size: 16px;
}
</style>

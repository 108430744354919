<template>
  <onboarding-step :step="step">
    <loader color="primary" :is-loading="fleetAssociationInProgress">
      <v-col>
        <v-row>
          <v-col col="4">
            <autocomplete
              :label="$t('fleetManagementForm.fleets')"
              :loading='listFleetInProgress'
              :items='fleetList'
              itemsName='humanReadableId'
              outlined
              hide-details
              no-filter
              :placeholder="$t('fleetManagementForm.autocomplete')"
              @searchAutocomplete='listFleets'
              @updateModel='setFleet'
            ></autocomplete>
          </v-col>
          <v-col col="4">
            <v-select
              outlined
              hide-details
              no-filter
              :items="fleetTypes"
              v-model="associationType">
            </v-select>
          </v-col>
          <v-col col="4">
            <v-btn
              block
              class="header-button"
              color="primary"
              @click="associatedFleet"
              :disabled="!fleet">
              {{ $t('onboarding.associate') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="bookingChannelFleets.length">
          <v-col col="4">
            <h4>{{ $t('onboarding.fleetAssociation.associatedFleets') }}</h4>
          </v-col>
        </v-row>
        <v-row v-for="(fleet, index) in bookingChannelFleets" :key="index">

          <v-col col="6">
            <v-text-field
              v-model="fleet.humanReadableId "
              outlined
              hide-details
              no-filter
              dense
              readonly>
            </v-text-field>
          </v-col>
          <v-col col="6">
            <v-select
              outlined
              hide-details
              no-filter
              dense
              @change="updateFleetAsscoation(fleet, $event)"
              :items="fleetTypes"
              v-model="fleet.type">
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </loader>
  </onboarding-step>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'fleet-association-step',
  components: { OnboardingStep },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
    humanReadableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fleet: null,
      fleetTypes: ['standard', 'partner'],
      associationType: 'standard',
    };
  },
  computed: {
    ...mapGetters({
      fleetList: 'fleets/list',
      listFleetInProgress: 'fleets/listFleetInProgress',
      fleetAssociationInProgress: 'onboarding/fleetAssociationInProgress',
    }),
    bookingChannelFleets() {
      return _.get(this.statePart, 'fleets', []);
    },
  },
  methods: {
    setFleet(fleetData) {
      this.fleet = Object.assign({}, fleetData);
    },
    async updateFleetAsscoation(fleet, value) {
      await this.$store.dispatch('onboarding/associateFleet', {
        bookingChannelId: this.bookingChannelId,
        fleetId: fleet.id,
        associationType: value,
        humanReadableId: this.humanReadableId,
      });
    },
    listFleets(dynamicSearch) {
      this.$store.dispatch('fleets/list', dynamicSearch);
    },
    async associatedFleet() {
      await this.$store.dispatch('onboarding/associateFleet', {
        bookingChannelId: this.bookingChannelId,
        fleetId: this.fleet.id,
        humanReadableId: this.humanReadableId,
        associationType: this.associationType,
      });
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

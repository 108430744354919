<template>
  <v-dialog :value="showDialog" persistent width="60%" scrollable>
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">{{ $t('paymentConnector.title') }}</span>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-data-table
            :headers="headers"
            :items="paymentConnectors"
            :loading="isLoading"
            hide-default-footer
            :multi-sort="false"
        >
          <template v-slot:item.status="{ item }">
            <v-tooltip bottom v-if="isCompleted(item.status)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="success">mdi-file-check</v-icon>
              </template>
              {{ $t('paymentConnector.completed') }}
            </v-tooltip>
            <v-tooltip bottom v-if="isPending(item.status)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="warning">mdi-file-clock</v-icon>
              </template>
              {{ $t('paymentConnector.pending') }}
            </v-tooltip>
            <span v-if="!isPending(item.status) && !isCompleted(item.status)" >N/A</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn :disabled="!isPending(item.status) && !isCompleted(item.status)" @click="evaluate(item)">{{ $t('paymentConnector.evaluate') }}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="close()">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  name: 'PaymentConnectorDialog',
  props: ['showDialog', 'fleetId'],
  data: () => ({
    isLoading: true,
    reEvaluationTriggered: false,
    paymentConnectors: [],
    headers: [
      {
        text: 'Name',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Account Id',
        sortable: false,
        value: 'configuration.accountId',
      },
      {
        text: 'Status',
        sortable: false,
        value: 'status',
      },
      {
        sortable: false,
        value: 'actions',
      },
    ],
  }),
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.loadPaymentConnectors();
      }
    },
  },
  methods: {
    close() {
      this.paymentConnectors = [];
      this.$emit('close', { reload: this.reEvaluationTriggered });
      this.reEvaluationTriggered = false;
    },
    isCompleted(status) {
      return status === 'completed';
    },
    isPending(status) {
      return status === 'pending';
    },
    async loadPaymentConnectors() {
      this.isLoading = true;
      const fleet = await this.$store.dispatch('fleets/getFleet', { fleetId: this.fleetId });
      this.paymentConnectors = _.get(fleet, 'connectors.payment');
      this.isLoading = false;
    },
    async evaluate(paymentConnector) {
      this.isLoading = true;
      this.reEvaluationTriggered = true;
      await this.$store.dispatch('fleets/evaluatePaymentConnectorStatus', {
        fleetId: this.fleetId,
        connectedAccountId: _.get(paymentConnector, 'configuration.accountId'),
        paymentProviderName: _.get(paymentConnector, 'name'),
      });
      await this.loadPaymentConnectors();
    },
  },
};
</script>

import _ from 'lodash';

function isObject(item) {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

/**
 * Recursive function to consume an object and return
 * the same object with the keys sorted in alphabetical order.
 * The maximum depth is set to 10.
 * @param object
 * @param depth
 * @returns {*}
 */
function sortObjectKeys(object, depth = 1) {
  const clonedObject = _.cloneDeep(object);
  const keys = Object.keys(clonedObject).sort();

  if (depth > 10) {
    return Object.assign({}, _.pick(clonedObject, keys));
  }

  keys.forEach((key) => {
    const val = clonedObject[key];
    if (isObject(val)) {
      clonedObject[key] = sortObjectKeys(val, depth + 1);
    }
  });
  return Object.assign({}, _.pick(clonedObject, keys));
}

// eslint-disable-next-line import/prefer-default-export
export default {
  jsonStringifySorted(object) {
    return JSON.stringify(sortObjectKeys(object), null, 2);
  },
  jsonPrettyPrint(object) {
    return JSON.stringify(object, null, 2);
  },
};

<template>
  <user-step
    :step="step"
    :statePart="statePart"
    :in-progress="consoleAdminInProgress"
    @createUser="createConsoleAdmin">
  </user-step>
</template>

<script>
import { mapGetters } from 'vuex';
import UserStep from './UserStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'console-admin-step',
  components: {
    UserStep,
  },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      consoleAdminInProgress: 'onboarding/consoleAdminInProgress',
    }),
  },
  methods: {
    async createConsoleAdmin(user) {
      await this.$store.dispatch('onboarding/createConsoleAdmin', {
        ...user,
        bookingChannelId: this.bookingChannelId,
        product: this.step.product,
      });
      this.$emit('component-updated');
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

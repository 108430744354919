<template>
  <div class="vehicle_icon_guidelines_container">
    <v-row class="mt-2" no-gutters>
      <v-col cols="12" class="px-1 mb-2">
        <strong class="label">{{ $t('fleetManagementForm.fleetBookingAppConfig.vehicleIconStyleGuidelines.label') }}</strong>
      </v-col>
    </v-row>
    <v-row class="mt-2 align-center" no-gutters >

      <v-col cols="12" class="px-1 mb-2">
        <p class="font-weight-bold">
          {{ $t('fleetManagementForm.fleetBookingAppConfig.vehicleIconStyleGuidelines.recommendations') }}
        </p>
      </v-col>

      <v-col xs="12" sm="4" lg="2" xl="3" class="justify-center align-center">
        <v-row class="justify-center align-center">
          <p>{{ $t('fleetManagementForm.fleetBookingAppConfig.vehicleIconStyleGuidelines.map') }} 45x81</p>
        </v-row>
        <v-row class="justify-center align-center">
          <v-img src="@/assets/default-map-vehicle-icon.png" max-height="81px" max-width="45px"></v-img>
        </v-row>
      </v-col>

      <v-col xs="12" sm="4" lg="2" xl="3" class="justify-center align-center">
        <v-row class="justify-center align-center">
          <p>{{ $t('fleetManagementForm.fleetBookingAppConfig.vehicleIconStyleGuidelines.list') }} 48x35</p>
        </v-row>
        <v-row class="justify-center align-center">
          <v-img src="@/assets/default-list-vehicle-icon.png" max-height="35px" max-width="48px" class="mr-0"></v-img>
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>

<script>

export default {
  props: {
  },
  components: {
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {
  },
};
</script>

<style scoped>
.vehicle_icon_guidelines_container .label{
  font-size: 16px;
}
</style>

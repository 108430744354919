<template>
  <v-dialog :value="type" persistent width="80%" scrollable>
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">View Customer Data - {{ customerData.name }}</span>
      </v-card-title>
      <v-card-text class="pt-4">
        <jot-form :jot-form="customerData" :raw-jot-form="rawJotForm"></jot-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="close()">
          {{$t('customerDataForm.close')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import JotForm from './JotForm.vue';
import customerDataValidations from '../validationDatas/customerData';

export default {
  name: 'JotFormDialog',
  props: ['type', 'customerData', 'rawJotForm'],
  components: { JotForm },
  data: () => ({
    defaultValues: {
      countries: ['CA', 'US', 'IE', 'UK', 'GB', 'AU', 'SE', 'FI', 'NZ', 'GY', 'ES', 'BE'],
      currencies: ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SEK', 'NZD'],
      currencySymbols: ['$', '€', '£', 'kr'],
      languages: ['en', 'fr', 'fi', 'es', 'sv'],
      booleans: [false, true],
      tipBase: ['currency', 'percentage'],
    },
  }),
  computed: {
    isEdit() {
      return this.type === 'edit';
    },
    isCreate() {
      return this.type === 'create';
    },
    disabled() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  validations() {
    return { customerData: customerDataValidations.customerData };
  },
  methods: {
    async saveItem() {
      await this.$store.dispatch('customerData/save', this.customerData);
      this.$emit('updateParent');
      this.$emit('close');
    },
    async editItem() {
      await this.$store.dispatch('customerData/edit', this.customerData);
      this.$emit('updateParent');
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","width":"60%","scrollable":""}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('paymentConnector.title')))])]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paymentConnectors,"loading":_vm.isLoading,"hide-default-footer":"","multi-sort":false},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.isCompleted(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-file-check")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('paymentConnector.completed'))+" ")]):_vm._e(),(_vm.isPending(item.status))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-file-clock")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('paymentConnector.pending'))+" ")]):_vm._e(),(!_vm.isPending(item.status) && !_vm.isCompleted(item.status))?_c('span',[_vm._v("N/A")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.isPending(item.status) && !_vm.isCompleted(item.status)},on:{"click":function($event){return _vm.evaluate(item)}}},[_vm._v(_vm._s(_vm.$t('paymentConnector.evaluate')))])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
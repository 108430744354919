<template>
  <v-container>
    <customer-data>
    </customer-data>
  </v-container>
</template>

<script>

import CustomerData from '@/components/customerData/CustomerData.vue';

export default {
  name: 'customer-data-view',
  components: {
    CustomerData,
  },
};
</script>

<style scoped>

</style>

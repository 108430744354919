import {
  minLength, required,
} from 'vuelidate/lib/validators';


export default {
  iosApp: {
    credentials: {
      minLength: minLength(1),
      required,
    },
    principal: {
      minLength: minLength(1),
      required,
    },
  },
};

<template>
  <div>

    <v-tabs
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        Notifications
      </v-tab>
      <v-tab>
        IOs Live Activity Notifications
      </v-tab>
      <v-tab-item>
        <NotificationP12Form :remote-state="remoteState"></NotificationP12Form>
      </v-tab-item>
      <v-tab-item>
        <IOSLiveActivityNotificationForm :remote-state="remoteState"></IOSLiveActivityNotificationForm>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';
import NotificationP12Form from './notificationFroms/NotificationForm.vue';
import IOSLiveActivityNotificationForm from './notificationFroms/IOSLiveActivityNotificationForm.vue';

export default {
  name: 'NotificationForm',
  components: {
    NotificationP12Form,
    IOSLiveActivityNotificationForm,
  },
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: 'ios',
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>

<template>
  <loader color="primary" :is-loading="isLoading">
    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <v-file-input
            v-model="p12Certificate"
            :label="$t(`onboarding.p12Certificate`)"
            :placeholder="$t('onboarding.p12Certificate')"
            outlined
            hide-details
            no-filter
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="p12Password"
            :label="$t(`onboarding.p12Password`)"
            :placeholder="$t('onboarding.p12Password')"
            outlined
            hide-details
            no-filter>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn
            block
            large
            :disabled="loadDisabled"
            class="header-button"
            color="primary"
            @click="loadCertificate">
            {{ $t(`load`) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span v-if="error" class="error--text">
            {{ $t('onboarding.loadCertificateError') }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validated-component
            name="humanReadableId"
            v-model="iosApp.credentials"
            :validator="$v.iosApp.credentials">
            <v-textarea
              v-model="iosApp.credentials"
              :label="$t(`onboarding.credentials`)"
              :placeholder="$t('onboarding.credentials')"
              outlined
              hide-details
              no-filter>
            </v-textarea>
          </validated-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validated-component
            name="humanReadableId"
            v-model="iosApp.principal"
            :validator="$v.iosApp.principal">
            <v-textarea
              v-model="iosApp.principal"
              :label="$t(`onboarding.principal`)"
              :placeholder="$t('onboarding.principal')"
              outlined
              hide-details
              no-filter>
            </v-textarea>
          </validated-component>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="4">
          <v-btn
            block
            large
            :disabled="$v.$invalid"
            class="header-button"
            color="primary"
            @click="saveAttributes">
            {{ $t(`save`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </loader>
</template>

<script>
import iosAppValidation from '../../../validationDatas/iosAppValidation';
import { decodeCertificate } from '../../../../services/cerficateService';

export default {
  name: 'ios-form',
  validations() {
    return iosAppValidation;
  },
  data() {
    return {
      error: false,
      p12Certificate: null,
      p12Password: null,
      iosApp: {
        credentials: '',
        principal: '',
      },
    };
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    loadDisabled() {
      return !this.p12Certificate || !this.p12Password;
    },
  },
  methods: {
    saveAttributes() {
      this.$emit('save-attributes', this.iosApp);
    },
    async loadCertificate() {
      try {
        this.error = false;
        const { privateKey, certificate } = await decodeCertificate(
          this.p12Certificate,
          this.p12Password,
        );
        this.iosApp.credentials = privateKey.trim();
        this.iosApp.principal = certificate.trim();
      } catch (error) {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

import {
  minLength, maxLength, required, email,
} from 'vuelidate/lib/validators';

export default {
  email: {
    minLength: minLength(1),
    maxLength: maxLength(255),
    email,
    required,
  },
  givenName: {
    minLength: minLength(1),
    maxLength: maxLength(255),
    required,
  },
  familyName: {
    minLength: minLength(1),
    maxLength: maxLength(255),
    required,
  },
  communicationLanguage: {
    required,
  },
};

<template>
  <div>
    <v-alert type="warning" v-if="noFleetAssociatedWarning" class="form-error-alert">
      No fleets have been associated
    </v-alert>
    <v-alert type="error" v-if="isDisplayFleetLinkedError" class="form-error-alert">
      The fleet is already linked to the booking channel.
    </v-alert>
    <v-alert type="error" v-if="isIncompatibleFleetErrorShown" class="form-error-alert">
      The fleet is not compatible with the booking channel. Check the following attributes of the fleet and booking channel
      <ul>
        <li>
          Currency
        </li>
      </ul>
    </v-alert>
    <div class="d-flex flex-row flex-wrap">
      <div class="fleet-search-cmp mr-8 mb-4" style="min-width: 260px;">
        <autocomplete
          :label="$t('fleetManagementForm.fleets')"
          :loading="fleetsSearchLoading"
          :items="fleetsSearchResults"
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('fleetManagementForm.autocomplete')"
          @searchAutocomplete='listFleets'
          @updateModel='setFleet'
        ></autocomplete>
      </div>
      <div class="mr-8 mb-4" style="min-width: 100px;">
        <v-select
          outlined
          hide-details
          no-filter
          :label="$t('fleetManagementForm.associationType')"
          :items="fleetTypes"
          v-model="associationType">
        </v-select>
      </div>
      <div class="mr-8 mb-4" style="min-width: 100px;">
        <v-select
          outlined
          hide-details
          no-filter
          :label="$t('fleetManagementForm.priorityType')"
          :items="priorityTypes"
          v-model="priorityType">
        </v-select>
      </div>
      <div>
        <v-btn
          class="header-button"
          color="success"
          @click="updateFleetAssociation"
          min-height="55"
          :disabled="!fleet">
          {{ $t('onboarding.associate') }}
        </v-btn>
      </div>
    </div>
    <v-row>
      <v-col>
        <h4 class="mt-10">Associated Fleets</h4>
      </v-col>
    </v-row>
    <v-row v-for="(fleet, index) in remoteState.bookingChannel.fleets" :key="index">
      <v-col md="4">
        <v-text-field
          v-model="fleet.humanReadableId"
          outlined
          hide-details
          no-filter
          dense
          disabled
          readonly>
        </v-text-field>
      </v-col>
      <v-col md="4">
        <v-select
          outlined
          hide-details
          no-filter
          dense
          :label="$t('fleetManagementForm.associationType')"
          :items="fleetTypes"
          v-model="fleet.type">
        </v-select>
      </v-col>
      <v-col md="4">
        <v-select
          outlined
          hide-details
          no-filter
          dense
          :label="$t('fleetManagementForm.priorityType')"
          :items="priorityTypes"
          v-model="fleet.priority">
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FleetsForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {
      fleet: null,
      fleetsSearchLoading: false,
      fleetTypes: ['standard', 'partner'],
      priorityTypes: ['high', 'standard'],
      associationType: 'standard',
      priorityType: 'standard',
      fleetsSearchResults: [],
      isDisplayFleetLinkedError: false,
      isIncompatibleFleetErrorShown: false,
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
    };
  },
  methods: {
    isFleetCompatibleWithBookingChannel(fleet) {
      return _.get(this.remoteState.bookingChannel, 'preAuthorization.currency') === _.get(fleet, 'currency');
    },
    async updateFleetAssociation() {
      if (this.getBookingChannelFleetsIdx()[this.fleet.id]) {
        this.isDisplayFleetLinkedError = true;
        return;
      }

      if (!this.isFleetCompatibleWithBookingChannel(this.fleet)) {
        this.isIncompatibleFleetErrorShown = true;
        return;
      }
      this.bookingChannel.fleets.push({
        id: this.fleet.id,
        type: this.associationType,
        humanReadableId: this.fleet.humanReadableId,
        ...(!!this.priorityType && { priority: this.priorityType }),
      });

      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
    async listFleets(dynamicSearch) {
      this.fleetsSearchLoading = true;
      const { fleets } = await this.$store.dispatch('fleets/getFleets', {
        page: 0,
        maxPerPage: 10,
        dynamicSearch,
      });
      this.fleetsSearchResults = fleets;
      this.fleetsSearchLoading = false;
    },
    setFleet(fleetData) {
      this.isDisplayFleetLinkedError = false;
      this.fleet = Object.assign({}, fleetData);
    },
    getBookingChannelFleetsIdx() {
      return _.keyBy(Object.assign([], this.remoteState.bookingChannel.fleets), 'id');
    },
  },
  computed: {
    noFleetAssociatedWarning() {
      return _.get(this.remoteState, 'bookingChannel.fleets.length') === 0;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
      <ul>
        <li v-for="(error, index) in getErrors()" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>
    <v-row>
      <v-col cols="12">
        <validated-component
          v-model="bookingChannel.connectors.payment"
          :validator="$v.bookingChannel.connectors.payment">
          <v-jsoneditor
            :plus="false"
            height="400px"
            v-model="bookingChannel.connectors.payment">
          </v-jsoneditor>
        </validated-component>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  // eslint-disable-next-line no-unused-vars
  minLength, maxLength, required, integer,
} from 'vuelidate/lib/validators';
// eslint-disable-next-line no-unused-vars
import { isBoolean } from '@/validation/validationMethods';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';

export default {
  name: 'PaymentConnectorForm',
  components: {
  },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
      paymentDataFormDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('paymentConnector'),
    };
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      if (this.getErrors().length) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    getErrors() {
      return this.paymentDataFormDefinition.getErrors({ bookingChannel: this.bookingChannel });
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  validations() {
    return {
      bookingChannel: {
        connectors: {
          payment: {
            required,
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

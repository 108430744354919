<template>
  <v-snackbar
      v-if="display"
      :value="true"
      :color="type"
      :timeout="timeout"
      :top="!isMobile"
      :bottom="isMobile"
      :vertical="!!(details || hint)"
    >
      <div v-if="!details && !hint">
        {{ message }}
      </div>
      <div v-if="details || hint">
        <v-card-title>
          {{ message }}
        </v-card-title>
        <v-card-text>
          <h3 class="font-weight-bold" v-if="details">Error Details</h3>
          <p v-if="details">{{ details }}</p>
          <h3 class="font-weight-bold" v-if="hint">Hint</h3>
          <p v-if="hint">{{ hint }}</p>
        </v-card-text>
      </div>
      <v-btn
        dark
        text
        @click="onDismiss()"
      >
        {{ $t('alerts.snackbar.close') }}
      </v-btn>
    </v-snackbar>
</template>

<script>
import { isMobile } from '../../mixins/responsive.mixins';

export default {
  name: 'error-snack',
  mixins: [isMobile],
  components: {},
  props: {
    globalMessage: {
      type: Object,
      required: true,
    },
    timeout: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dismiss: false,
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  computed: {
    display() {
      return this.globalMessage.message && this.show && !this.dismiss;
    },
    message() {
      if (this.globalMessage.code && this.$te(`apiErrors.${this.globalMessage.code}`)) {
        return this.$t(`apiErrors.${this.globalMessage.code}`);
      }

      return this.$t(this.globalMessage.message);
    },
    details() {
      if (this.type !== 'error') {
        return null;
      }
      return this.globalMessage.details;
    },
    hint() {
      if (this.type !== 'error') {
        return null;
      }
      if (this.globalMessage.code && this.$te(`apiErrorHints.${this.globalMessage.code}`)) {
        return this.$t(`apiErrorHints.${this.globalMessage.code}`);
      }
      return this.$t('apiErrorHints.default');
    },
  },
  methods: {
    onDismiss() {
      this.$emit('dismissed', this.globalMessage);
      this.dismiss = true;
    },
  },
};
</script>
<style>
</style>

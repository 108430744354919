<template>
  <v-dialog
    max-width="750"
    v-if="display"
    :value="true"
    scrollable
    persistent
  >
    <v-card class="mx-auto">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ fleet.companyName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text style="height: 100%; overflow-y: hidden;">
        <div class="json-preview">
          <pre>{{ fleetJson }}</pre>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FleetJsonDialog',
  props: {
    fleet: {
      required: true,
    },
    display: {
      required: true,
    },
  },
  methods: {
    dismiss() {
      this.$emit('onDismiss');
    },
  },
  computed: {
    fleetJson() {
      return JSON.stringify(this.fleet, null, 2);
    },
  },
};
</script>

<style scoped lang="scss">
.json-preview {
  padding: 1.2rem;
  margin: 1.2rem 0;
  background: #f9f9f9;
  border-radius: 4px;
  max-height: 100%;
  overflow-y: scroll;
  pre {
    font-weight: 500;
  }
}
</style>

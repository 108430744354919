<template>
  <onboarding-step :step="step">
    <loader :is-loading="appSigningInProgress" color="primary">
      <v-col cols=12>
        <v-row>
          <v-list
            align-start>
            <v-list-item
              align-start
              v-for="(detail, key) in statePart.details"
              :key="key">
              <v-list-item-icon>
                <v-icon :color="getColor(detail.completed)">
                  {{ getIcon(detail.completed) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  align="start">
                  {{ key }}
                </v-list-item-title>
                <v-list-item-subtitle
                  align="start"
                  v-if="!detail.completed">
                  {{ detail.detail }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="4">
            <v-btn-toggle
              exclusive
              mandatory
              v-model='appSigningOption'>
              <v-btn>
                {{ $t(`Upload`) }}
              </v-btn>
              <v-btn>
                {{ $t(`Generate`) }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <component
           @upload-android-keystore="uploadAndroidKeystore"
           @generate-android-keystore="generateAndroidKeystore"
           :is="selectedAppSigningComponent">
          </component>
        </v-row>
      </v-col>
    </loader>
  </onboarding-step>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSigningGenerateForm from './AppSigningGenerateForm.vue';
import AppSigningUploadForm from './AppSigningUploadForm.vue';
import OnboardingStep from '../OnboardingStep.vue';
import OnboardingStepMixin from '../../../../mixins/onboardingStep.mixin';

export default {
  name: 'app-signing-step',
  components: {
    OnboardingStep,
    AppSigningGenerateForm,
    AppSigningUploadForm,
  },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
    humanReadableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      appSigningOption: 0,
    };
  },
  computed: {
    ...mapGetters({
      appSigningInProgress: 'appSigning/appSigningInProgress',
    }),
    selectedAppSigningComponent() {
      return this.appSigningOption === 0 ? 'app-signing-upload-form' : 'app-signing-generate-form';
    },
  },
  methods: {
    async uploadAndroidKeystore(keystore) {
      await this.$store.dispatch('appSigning/uploadAndroidKeystore', {
        bookingChannelId: this.bookingChannelId,
        appSigning: keystore,
      });
      this.$emit('component-updated');
    },
    async generateAndroidKeystore() {
      await this.$store.dispatch('appSigning/generateAndroidKeystore', {
        humanReadableId: this.humanReadableId,
      });
      this.$emit('component-updated');
    },
  },
};
</script>

<style scoped>

</style>

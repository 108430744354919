<template>
  <onboarding-step :step="step">
      <v-list
        align-start>
        <v-list-item
          align-start
          v-for="(detail, key) in statePart.details"
          :key="key">
          <v-list-item-icon>
            <v-icon :color="getColor(detail.completed)">
              {{ getIcon(detail.completed) }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              align="start">
              {{ key }}
            </v-list-item-title>
            <v-list-item-subtitle
              align="start"
              v-if="!detail.completed">
              {{ detail.detail }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </onboarding-step>
</template>

<script>
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'oidc-clients-step',
  components: { OnboardingStep },
  mixins: [OnboardingStepMixin],
};
</script>

<style scoped>

</style>

<template>
  <v-footer app class="user-profile-navigation-footer">
    <v-menu
      :close-on-content-click="false"
      v-model="open"
      offset-x class="user-profile-navigation-menu">
      <template v-slot:activator="{ on }">
        <v-btn
          text
          depressed
          block outlined min-height="50px"
          v-on="on"
          class="user-profile-navigation-btn">
          <v-layout row wrap align-center>
            <v-flex xs2>
              <v-icon class="avatar-icon">$icons_product_avatar</v-icon>
            </v-flex>
            <v-flex xs8>
              <span class="user-profile-navigation-username">{{ displayName | truncate(12) }}</span>
            </v-flex>
            <v-flex xs2>
              <v-icon right dark>mdi-chevron-right</v-icon>
            </v-flex>
          </v-layout>
        </v-btn>
      </template>

      <v-list>
        <component
          v-for="item in items"
          :key="item.label"
          :is="item.componentName"
          :item="item">
        </component>
      </v-list>
    </v-menu>
  </v-footer>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';
import Logout from './Logout.vue';

export default {
  name: 'UserProfileNavigation',
  components: { LanguageSelector, Logout },
  data() {
    return {
      open: false,
    };
  },
  filters: {
    truncate(text, length) {
      if (!text) return '';
      const v = text.toString();
      if (v.length <= length) return v;
      return `${v.slice(0, length)}...`;
    },
  },
  watch: {
    locale() {
      if (this.open) {
        this.open = false;
        this.$nextTick(() => {
          this.open = true;
        });
      }
    },
  },
  props: {
    displayName: {
      type: String,
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    items() {
      const menuItems = [];
      menuItems.push({
        componentName: 'language-selector',
        label: 'navigation.menu.language',
      }, {
        componentName: 'logout',
        label: 'navigation.menu.logout',
      });
      return menuItems;
    },
  },
};
</script>

<style lang="scss" scoped>
  .user-profile-navigation-footer {
    margin: 0;
    padding: 0;
    background-color: transparent !important;

    button {
      border: 0;
      border-top: 1px solid #d4d4d4;
    }
  }

  .user-profile-navigation-username {
    text-transform: none;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    letter-spacing: normal;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .avatar-icon {
    width: 35px;
    height: 35px;
  }
</style>

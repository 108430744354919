import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';
import _ from 'lodash';

const ALLOWED_PAYMENT_INSTANCES = Object.freeze(['uk', 'canada', 'ireland', 'us', 'aus']);

const validPaymentInstance = value => _.includes(ALLOWED_PAYMENT_INSTANCES, value);


export default {
  network: {
    name: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      required,
    },
    paymentInstance: {
      validPaymentInstance,
    },
  },
};

import {
  minLength, required,
} from 'vuelidate/lib/validators';


export default {
  keystore: {
    file: {
      required,
    },
    alias: {
      minLength: minLength(1),
      required,
    },
    storePassword: {
      minLength: minLength(1),
      required,
    },
    keyPassword: {
      minLength: minLength(1),
      required,
    },
  },
};

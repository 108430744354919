<template>
  <v-container>
    <trackmytaxi>
    </trackmytaxi>
  </v-container>
</template>

<script>
import Trackmytaxi from '@/components/trackmytaxi/Trackmytaxi.vue';

export default {
  name: 'financial-settings-view',
  components: {
    Trackmytaxi,
  },
};
</script>

<style scoped>

</style>

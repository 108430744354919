<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
          The form is invalid. Please correct the errors.
        </v-alert>
        <h3>{{ $t('geoConnectorForm.frontendConnector') }}</h3>
      </v-col>
      <v-col cols="12">
        <validated-component
          v-model="bookingChannel.connectors.geo.name"
          :validator="$v.bookingChannel.connectors.geo.name">
          <v-select
            v-model="bookingChannel.connectors.geo.name"
            :label="$t('geoConnectorForm.name')"
            outlined
            :items="uiGeoNames"
            hide-details
          >
          </v-select>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.geo.uiConfiguration.accessToken"
          :validator="$v.bookingChannel.connectors.geo.uiConfiguration.accessToken">
          <v-text-field
            v-model="bookingChannel.connectors.geo.uiConfiguration.accessToken"
            :label="$t('geoConnectorForm.webKey')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.geo.uiConfiguration.mapStyle"
          :validator="$v.bookingChannel.connectors.geo.uiConfiguration.mapStyle">
          <v-text-field
            v-model="bookingChannel.connectors.geo.uiConfiguration.mapStyle"
            :label="$t('geoConnectorForm.mapStyle')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <h3>{{ $t('geoConnectorForm.backendConnector') }}</h3>
          <v-btn color="blue" class="white--text" @click="addConnector">
            Add Connector
          </v-btn>
        </div>
      </v-col>
      <v-col v-for="(connector, index) in bookingChannel.connectors.geo.driverConfigurations" :key="`geoConnector${index}`" cols="12">
        <div class="d-flex align-center" :class="{ 'justify-space-between': connector.driverName === 'googleMaps'  }">
          {{ $t('geoConnectorForm.connector', { index: index + 1 }) }}
          <v-icon v-if="bookingChannel.connectors.geo.driverConfigurations.length > 1 && connector.driverName !== 'googleMaps'" color="info" class="delete-driver" @click="removeDriver(index)">
            delete
          </v-icon>

          <v-switch
            v-if="connector.driverName === 'googleMaps'"
            v-model="bookingChannel.addressSearchConfiguration.useGoogleAddressSearch"
            label="Use Google for address search"
            color="success"
            hide-details
            class="mt-0 pt-0"
          ></v-switch>
        </div>
        <p>

        </p>
        <validated-component
          v-model="connector.driverName"
          :validator="$v.bookingChannel.connectors.geo.driverConfigurations.$each.$iter[index].driverName">
          <v-select
            v-model="connector.driverName"
            :label="$t('geoConnectorForm.name')"
            outlined
            :items="geoNames"
            hide-details
          >
          </v-select>
        </validated-component>
        <validated-component
          v-model="connector.configuration.key"
          :validator="$v.bookingChannel.connectors.geo.driverConfigurations.$each.$iter[index].configuration.key">
          <v-text-field
            v-model="connector.configuration.key"
            :label="$t('geoConnectorForm.key')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';
import { isInList } from '@/validation/validationMethods';

export default {
  name: 'GeoConnectorForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
      geoNames: ['googleMaps', 'ic2ICabbi'],
      uiGeoNames: ['googleMaps'],
    };
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  created() {
    this.$v.$touch();
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    addConnector() {
      this.bookingChannel.connectors.geo.driverConfigurations.push(
        this.getEmptyConnector(),
      );
    },
    getEmptyConnector() {
      return {
        driverName: '',
        configuration: {
          key: '',
        },
      };
    },
    removeDriver(index) {
      this.bookingChannel.connectors.geo.driverConfigurations.splice(index, 1);
    },
  },
  validations() {
    return {
      bookingChannel: {
        connectors: {
          geo: {
            required,
            name: {
              minLength: minLength(1),
              maxLength: maxLength(255),
              required,
              isInList: isInList(this.uiGeoNames),
            },
            driverConfigurations: {
              $each: {
                driverName: {
                  minLength: minLength(1),
                  maxLength: maxLength(255),
                  required,
                  isInList: isInList(this.geoNames),
                },
                configuration: {
                  key: {
                    minLength: minLength(1),
                    maxLength: maxLength(255),
                    required,
                  },
                },
              },
            },
            uiConfiguration: {
              required,
              accessToken: {
                minLength: minLength(1),
                required,
              },
              mapStyle: {
                minLength: minLength(1),
                required,
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

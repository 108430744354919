<template>
  <v-dialog :value="visible" persistent width="80%">
    <v-card>
        <v-card-title>
          <span class="headline">Create</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="settings.startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validated-component
                    name="startDate"
                    v-model="settings.startDate"
                    :validator="$v.settings.startDate">
                    <v-text-field
                      v-model="settings.startDate"
                      :label="$t('financialSettingsCreate.startDate')"
                      :placeholder="$t('financialSettingsCreate.startDate')"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </validated-component>
                </template>
                <v-date-picker v-model="settings.startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuStartDate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuStartDate.save(settings.startDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="settings.endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validated-component
                    name="endDate"
                    v-model="settings.endDate"
                    :validator="$v.settings.endDate">
                    <v-text-field
                      v-model="settings.endDate"
                      :label="$t('financialSettingsCreate.endDate')"
                      :placeholder="$t('financialSettingsCreate.endDatePlaceholder')"
                      prepend-inner-icon="event"
                      readonly
                      clearable
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </validated-component>
                </template>
                <v-date-picker v-model="settings.endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndDate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuEndDate.save(settings.endDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validated-component
                name="uid"
                v-model="settings.uid"
                :validator="$v.settings.uid">
                <v-text-field
                  v-model="settings.uid"
                  :label="$t('financialSettingsCreate.uid')"
                  :placeholder="$t('financialSettingsCreate.uid')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col>
              <validated-component
                name="businessBookingAmount"
                v-model="settings.fees.businessBookingAmount"
                :validator="$v.settings.fees.businessBookingAmount">
                <v-text-field
                  v-model.number="settings.fees.businessBookingAmount"
                  :label="$t('financialSettingsCreate.businessBookingAmount')"
                  :placeholder="$t('financialSettingsCreate.businessBookingAmount')"
                  outlined
                  type="number"
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                class="fee-table"
                :headers="headers"
                :items="settings.fees.booking"
                hide-default-footer
                disable-sort
                disable-pagination
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Booking Fees</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="add"
                    >
                      <v-icon>playlist_add</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.minimumCount="{ item }">
                  <span>
                      <v-text-field
                        v-model="item.minimumCount"
                        outlined
                        type="number"
                        hide-details
                        no-filter
                      ></v-text-field>
                  </span>
                </template>
                <template v-slot:item.maximumCount="{ item }">
                  <span>
                      <v-text-field
                        v-model="item.maximumCount"
                        outlined
                        type="number"
                        hide-details
                        no-filter
                      ></v-text-field>
                  </span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>
                      <v-text-field
                        v-model="item.amount"
                        outlined
                        type="number"
                        hide-details
                        no-filter
                      ></v-text-field>
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="close()">
            close
          </v-btn>
          <v-btn color="success"
                 :disabled="$v.$invalid"
                 @click="saveItem()">
            create
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import financialSettingsValidation from '../validationDatas/financialSettingsValidation';

export default {
  name: 'financial-settings-create',
  data: () => ({
    menuStartDate: false,
    menuEndDate: false,
    settings: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: null,
      uid: null,
      fees: {
        businessBookingAmount: 0.16,
        booking: [
          { minimumCount: 0, maximumCount: 50000, amount: 0.07 },
          { minimumCount: 50000, maximumCount: null, amount: 0.05 },
        ],
      },
    },
  }),
  props: ['visible'],
  computed: {
    headers() {
      return [
        {
          text: this.$t('financialSettingsCreate.from'),
          align: 'left',
          sortable: false,
          value: 'minimumCount',
        },
        {
          text: this.$t('financialSettingsCreate.to'),
          align: 'left',
          sortable: false,
          value: 'maximumCount',
        },
        {
          text: this.$t('financialSettingsCreate.cost'),
          align: 'left',
          sortable: false,
          value: 'amount',
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'actions',
        },
      ];
    },
  },
  validations() {
    return financialSettingsValidation;
  },
  watch: {
    settings() {
      this.$nextTick(() => this.$v.$reset());
    },
  },
  methods: {
    async saveItem() {
      this.$emit('created', this.settings);
    },
    close() {
      this.$emit('close');
    },
    add() {
      this.settings.fees.booking.push({ minimumCount: null, maximumCount: null, amount: 0.01 });
    },
    deleteItem(item) {
      const index = this.settings.fees.booking.indexOf(item);
      this.settings.fees.booking.splice(index, 1);
    },
  },
};
</script>

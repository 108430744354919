<template>
  <onboarding-step :step="step">
    <v-row>
      <v-col cols="8">
        <v-list
          align-start>
          <v-list-item
            align-start
            v-for="(detail, key) in statePart.details"
            :key="key">
            <v-list-item-icon>
              <v-icon :color="getColor(detail.completed)">
                {{ getIcon(detail.completed) }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                align="start">
                {{ key }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle
                align="start"
                v-if="!detail.completed">
                {{ detail.details.message }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="8">
        <h4>{{ $t('onboarding.notificationForm') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <platform-toggle
          @platformUpdate="setPlatform">
        </platform-toggle>
      </v-col>
    </v-row>
    <v-row>
      <component
        @save-attributes="saveAttributes"
        :is-loading="notificationSetupInProgress"
        :is="selectedPlatformComponent">
      </component>
    </v-row>
  </onboarding-step>
</template>

<script>
import { mapGetters } from 'vuex';
import AndroidForm from './AndroidForm.vue';
import IosForm from './IosForm.vue';
import OnboardingStep from '../OnboardingStep.vue';
import PlatformToggle from '../../../elements/PlatformToggle.vue';
import OnboardingStepMixin from '../../../../mixins/onboardingStep.mixin';

export default {
  name: 'notification-setup-step',
  components: {
    OnboardingStep,
    AndroidForm,
    IosForm,
    PlatformToggle,
  },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      platform: 'ios',
    };
  },
  computed: {
    ...mapGetters({
      notificationSetupInProgress: 'onboarding/notificationSetupInProgress',
    }),
    selectedPlatformComponent() {
      return `${this.platform}-form`;
    },
  },
  methods: {
    async saveAttributes(attributes) {
      await this.$store.dispatch('onboarding/setupNotificationPlatform', {
        bookingChannelId: this.bookingChannelId,
        platform: this.platform,
        attributes,
      });
      this.$emit('component-updated');
    },
    setPlatform(platform) {
      this.platform = platform;
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

<template>
  <div>
    <vue-headful :title="this.title" />
    <v-app style="background-color: #ffffff !important;">
      <navigation v-if="showNavigation" :displayName="email"></navigation>
      <v-content>
        <v-container>
          <global-error></global-error>
          <global-success></global-success>
          <router-view></router-view>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import jwt from 'jsonwebtoken';
import { initLocale } from './mixins/language.mixins';

import Navigation from './components/Navigation.vue';
import GlobalError from './components/GlobalError.vue';
import GlobalSuccess from './components/GlobalSuccess.vue';

export default {
  name: 'App',
  data: () => ({
    displayDrawer: false,
  }),
  components: { GlobalError, GlobalSuccess, Navigation },
  mixins: [initLocale],
  created() {
    // The authentication module requires that the OIDC client is available in the booking channel store
    // when trying to refresh a token.
    this.$store.commit('bookingChannel/setBookingChannelConfig', { oidcClientId: process.env.VUE_APP_CLIENT_ID });
  },
  computed: {
    ...mapGetters({
      idToken: 'authentication/idToken',
    }),
    showNavigation() {
      if (!this.$route || !this.$route.meta) {
        return false;
      }
      return this.$route.meta.hasNavigation || false;
    },
    title() {
      return this.$t('head.title', { companyName: this.companyName });
    },
    email() {
      return this.idToken ? jwt.decode(this.idToken).email : '';
    },
  },
};
</script>
<style lang="scss">
  @import './assets/sass/onboarding-console.scss';
</style>

<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  #nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>

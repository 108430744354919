<template>
  <v-timeline dense>
    <v-timeline-item
      @click.native="() => emitSelection(step)"
      class="timeline-item"
      :color="getColor(step)"
      fill-dot
      v-for="(step, index) in steps"
      :key="index"
      small
      :icon="getIcon(step)">
      {{ $t(step.label) }}
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import _ from 'lodash';
import BookingChannelStepMixin from '../../mixins/bookingChannelStep.mixin';

export default {
  name: 'OnboardingTimeline',
  mixins: [BookingChannelStepMixin],
  props: {
    steps: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
    },
    configurationState: {
      type: Object,
    },
  },
  computed: {
    bookingChannelStatePart() {
      return _.get(this.configurationState, 'bookingChannel', null);
    },
  },
  methods: {
    getColor(step) {
      const statePart = _.get(this.configurationState, step.stateKey, null);
      if (statePart
        && step.isDone
        && this.isBookingChannelCheckComponent(step)
        && this.isCompletedButInconsistent(statePart, this.bookingChannelStatePart)) {
        return 'warning';
      }
      if (step.isDone && !this.isStatePartCompleted(step)) {
        return 'error';
      }
      return step.isDone ? 'primary' : '#D4D4D4';
    },
    getIcon(step) {
      const statePart = _.get(this.configurationState, step.stateKey, null);
      if (statePart
        && this.isBookingChannelCheckComponent(step)
        && this.isCompletedButInconsistent(statePart, this.bookingChannelStatePart)) {
        return 'help_outline';
      }
      if (this.configurationState && !this.isStatePartCompleted(step)) {
        return 'warning';
      }
      return step.icon;
    },
    isBookingChannelCheckComponent(step) {
      return [
        'bookingChannel',
        'geoConnector',
        'paymentConnector',
        'userConnector',
      ].includes(step.stateKey);
    },
    isStatePartCompleted(step) {
      return !!_.get(this.configurationState, `${step.stateKey}.completed`, true);
    },
    emitSelection(step) {
      if (!this.disable) {
        this.$emit('step-selection', step);
      }
    },
  },
};
</script>

<style scoped>
  .timeline-item {
    cursor: pointer;
  }
</style>

import {
  minLength, maxLength, required, decimal, numeric, url,
} from 'vuelidate/lib/validators';

import {
  isBoolean, isHexadecimalColor, isHumanReadableId, isInList, isValidString, nonZero,
} from '@/validation/validationMethods';

// eslint-disable-next-line import/prefer-default-export
export const bookingChannelVuelidate = {
  name: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
  },
  humanReadableId: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
    isHumanReadableId,
  },
  currencySymbol: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
  },
  logo: {
    required,
  },
  communicationLanguage: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
  },
  defaultCountry: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255),
  },
  colorScheme: {
    required,
    primary: { required, isHexadecimalColor },
    primaryDark: { required, isHexadecimalColor },
    primaryFont: { required, isHexadecimalColor },
    secondary: { required, isHexadecimalColor },
    error: { required, isHexadecimalColor },
    appIconBackground: { required, isHexadecimalColor },
    loginLogoBackground: { required, isHexadecimalColor },
    loginActionBackground: { required, isHexadecimalColor },
  },
  location: {
    required,
    latitude: {
      decimal,
      required,
      nonZero: nonZero(),
    },
    longitude: {
      required,
      decimal,
      nonZero: nonZero(),
    },
  },
  defaultTip: {
    required,
    defaultTipValue: {
      required,
      decimal,
    },
    defaultTipBase: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
  },
  // The booking channel object itself contains the payment (not preAuthorization)
  // So we must remember to map it before sending any update requests.
  preAuthorization: {
    amount: {
      required,
      numeric,
    },
    currency: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
  },
  allowedPaymentMethods: {
    required,
    minLength: minLength(1),
    $each: {
      type: {
        required,
        minLength: minLength(1),
      },
    },
  },
  fleets: {
    required,
    minLength: minLength(1),
    $each: {
      id: {
        required,
      },
      type: {
        required,
      },
    },
  },
  // The `oidcClientIds` will be create/updated by the backend
  oidcClientIds: {
    required,
    minLength: minLength(7),
    $each: {
      id: {
        required,
      },
    },
  },
  connectors: {
    required,
    geo: {
      driverConfigurations: {
        required,
        driverName: {
          required,
          minLength: minLength(1),
        },
        configuration: {
          required,
        },
      },
    },
    user: {
      required,
      name: { required, minLength: minLength(1) },
      configuration: {
        required,
      },
      uiConfiguration: {
        required,
      },
    },
    payment: {
      required,
      $each: {
        name: { required, minLength: minLength(1) },
        configuration: {
          required,
        },
      },
    },
    notification: {
      required,
      name: {
        required,
        minLength: minLength(1),
      },
    },
  },
  features: {
    appReview: { isBoolean },
    qaAutomation: { isBoolean },
    smsPersonalLogin: { isBoolean },
    displayPricingInBusinessReporting: { isBoolean },
    delivery: {
      status: {
        required,
        isInList: isInList(['active', 'inactive']),
      },
      clientVersions: {
        required,
        $each: {
          platform: {
            required,
            isInList: isInList(['ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console']),
          },
          minimumVersion: {
            required,
            minLength: minLength(1),
          },
        },
      },
    },
    customSelectField: {
      status: {
        required,
        isInList: isInList(['active', 'inactive']),
      },
      clientVersions: {
        required,
        $each: {
          platform: {
            required,
            isInList: isInList(['ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console']),
          },
          minimumVersion: {
            required,
            minLength: minLength(1),
          },
        },
      },
      icabbiGeoDriver: {
        required,
        $each: {
          platform: {
            required,
            isInList: isInList(['ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console']),
          },
          minimumVersion: {
            required,
            minLength: minLength(1),
          },
        },
      },
      googlePay: {
        required,
        $each: {
          platform: {
            required,
            isInList: isInList(['ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console']),
          },
          minimumVersion: {
            required,
            minLength: minLength(1),
          },
        },
      },
    },
    thirdPartyDelivery: {
      status: {
        required,
        isInList: isInList(['active', 'inactive']),
      },
      clientVersions: {
        required,
        $each: {
          platform: {
            required,
            isInList: isInList(['ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console']),
          },
          minimumVersion: {
            required,
            minLength: minLength(1),
          },
        },
      },
      iconUrl: {
        isValidString,
      },
      url: {
        required,
        isValidString,
      },
    },
    isAddressViasFeatureEnabled: { isBoolean },
    driverJobApplicationUrl: { isValidString, url },
    isPostTripTipsEnabled: { isBoolean },
    allowDuplicateBookings: { isBoolean },
    isPreBookingEnabled: { required, isBoolean },
    airportPickupSettings: {
      required,
      canSkip: {
        required,
        isBoolean,
      },
    },
  },
};

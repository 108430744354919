import _ from 'lodash';

export default class FileUploadUtil {
  static async getMetadataOfUploadedImage(uploadedFile) {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      if (!uploadedFile) {
        return reject(new Error('uploadedFile is required'));
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageBase64Encoded = e.target.result;
        const fileType = uploadedFile.type.split('/')[1];
        if (!_.includes(['png', 'jpg', 'jpeg'], fileType)) {
          resolve({
            imageBase64Encoded: null,
            width: null,
            height: null,
            fileType,
            fileSize: uploadedFile.size,
            fileName: uploadedFile.name,
          });
          return;
        }

        const image = new Image();
        image.src = imageBase64Encoded;
        image.onload = () => {
          const { width, height } = image;
          resolve({
            imageBase64Encoded,
            width,
            height,
            fileType: uploadedFile.type.split('/')[1],
            fileSize: uploadedFile.size,
            fileName: uploadedFile.name,
          });
          return true;
        };
      };
      reader.readAsDataURL(uploadedFile);
    });
  }

  static async getBase64EncodedStringFromFile(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject(new Error('file is required'));
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64EncodedString = e.target.result;
        // get the actual base64 encoded string, remove metadata
        const base64EncodedStringWithoutMetadata = base64EncodedString.split(',')[1];
        resolve(base64EncodedStringWithoutMetadata);
      };
      reader.readAsDataURL(file);
      return true;
    });
  }

  static getUploadedFileExtension(uploadedFile) {
    if (!uploadedFile) {
      throw new Error('uploadedFile is required');
    }
    const fileName = uploadedFile.name;
    return fileName.split('.')
      .pop();
  }
}

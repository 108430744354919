<template>
  <div>
    <div v-if="canSetupNotifications">
      <v-row>
        <v-col>
          <v-list
            align-start>
            <v-list-item
              align-start
              v-for="(detail, key) in notificationsCheckState.details"
              :key="key">
              <v-list-item-icon>
                <v-icon :color="getColor(detail.completed)">
                  {{ getIcon(detail.completed) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  align="start">
                  {{ key }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle
                  align="start"
                  v-if="!detail.completed">
                  {{ detail.details.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('onboarding.notificationForm') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <platform-toggle
            @platformUpdate="setPlatform">
          </platform-toggle>
        </v-col>
      </v-row>
      <v-row>
        <component
          @save-attributes="saveAttributes"
          :is="selectedPlatformComponent">
        </component>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <v-col>
            <v-alert
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              The iOS Bundle ID and the Android Application ID must be set before the Notifications Connector can be set up.
              Please refer to the <strong>App Build Settings</strong> section.
            </v-alert>
          </v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PlatformToggle from '@/components/elements/PlatformToggle.vue';
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';
import AndroidForm from '@/components/onboarding/steps/notification/AndroidForm.vue';
import IosForm from '@/components/onboarding/steps/notification/IosForm.vue';

export default {
  name: 'NotificationForm',
  components: {
    PlatformToggle,
    AndroidForm,
    IosForm,
  },
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      platform: 'ios',
    };
  },
  computed: {
    notificationsCheckState() {
      return this.remoteState.checkState.pushNotification;
    },
    selectedPlatformComponent() {
      return `${this.platform}-form`;
    },
    canSetupNotifications() {
      const bch = this.remoteState.bookingChannel;
      return bch.iosBundleId && bch.androidApplicationId;
    },
  },
  methods: {
    async saveAttributes(attributes) {
      this.$store.dispatch('bookingChannelView/showLoader');

      try {
        await this.$store.dispatch('onboarding/setupNotificationPlatform', {
          bookingChannelId: this.remoteState.bookingChannel.id,
          platform: this.platform,
          attributes,
          throwFail: true,
        });
      } catch (e) {
        throw e;
      } finally {
        this.$store.dispatch('bookingChannelView/hideLoader');
      }
      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
    },
    setPlatform(platform) {
      this.platform = platform;
    },
  },
};
</script>

<style scoped>

</style>

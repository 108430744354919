<template>
  <h2>
    Hello Waiting Times Form
  </h2>
</template>

<script>
export default {
  name: 'WaitingTimesForm',
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="jotForm.companyId" label="Company ID" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.name" label="Name" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.businessName" label="Business Name" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.businessType" label="Business Type" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.latitude" label="Latitude" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.longitude" label="Longitude" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.defaultCountry" label="Default Country" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mt-8 mb-8"></v-divider>
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="jotForm.currency" label="Currency" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.currencySymbol" label="Currency Symbol" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.paymentType" label="Payment Type" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.defaultTipBase" label="Default Tip Type" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.defaultTipValue" label="Default Tip Value" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.salesTaxInfo" label="Sales Tax Info" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="jotForm.appleAccountName" label="Apple Account Name" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.iosBundleId" label="iOS Bundle ID" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.storeUrlIos" label="iOS Store URL" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.googleAccountName" label="Google Account Name" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.androidApplicationId" label="Android Application ID" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="jotForm.supportPhoneNumber" label="Support Number" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.callDriverDirectPhoneNumber" label="Driver Support Number" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.businessAdminFullName" label="Business Admin Name" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.businessAdminEmail" label="Business Admin Email" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="jotForm.customerConsoleAdminEmail" label="Customer Console Admin Email" outlined hide-details no-filter>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>
          Jot Form JSON
        </h3>
      </v-col>
      <v-col cols="12">
        <div class="json-preview">
          <pre>{{ jotFormJson }}</pre>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import JsonUtil from '@/util/jsonUtil';

export default {
  name: 'JotForm',
  props: {
    jotForm: {
      type: Object,
      required: true,
    },
    rawJotForm: {
      type: Object,
      required: true,
    },
  },
  computed: {
    jotFormJson() {
      return JsonUtil.jsonStringifySorted(this.rawJotForm);
    },
  },
};
</script>

<style scoped lang="scss">
  .json-preview {
    padding: 1.2rem;
    margin: 1.2rem 0;
    background: #f9f9f9;
    border-radius: 4px;
    max-height: 100%;
    overflow-y: scroll;
    pre {
      font-weight: 500;
    }
  }
</style>

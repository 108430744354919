<template>
  <v-container>
    <v-row>
      <v-col cols="11">
      </v-col>
      <v-col cols="1">
        <v-btn
          color="primary"
          @click="load"
          :disabled="buildsLoading">
          <v-icon>cached</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="builds"
          item-key="id"
          :loading="buildsLoading"
          single-expand
          :expanded.sync="expanded"
          show-expand
          class="elevation-0"
        >
          <template v-slot:header.id="{ header }">
            <span class="font-weight-black black--text">{{ header.text }}</span>
          </template>
          <template v-slot:header.humanReadableId="{ header }">
            <span class="font-weight-black black--text">{{ header.text }}</span>
          </template>
          <template v-slot:header.paymentEnv="{ header }">
            <span class="font-weight-black black--text">{{ header.text }}</span>
          </template>
          <template v-slot:header.status="{ header }">
            <span class="font-weight-black black--text">{{ header.text }}</span>
          </template>
          <template v-slot:header.ref="{ header }">
            <span class="font-weight-black black--text">{{ header.text }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip dark :color="getBuildColor(item.status)">
              <v-icon>{{ getBuildIcon(item.status) }}</v-icon>
              <span class="status">{{ item.status }}</span>
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <build-details
                :buildDetailsLoading="buildDetailsLoading"
                :buildDetails="buildDetails"
                ></build-details>
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import BuildDetails from './BuildDetails.vue';
import BuildMixin from '../../mixins/build.mixin';

export default {
  name: 'BuildList',
  mixins: [BuildMixin],
  components: {
    BuildDetails,
  },
  props: {
    buildsLoading: {
      type: Boolean,
      required: true,
    },
    builds: {
      type: Array,
      required: true,
    },
    totalBuilds: {
      type: Number,
      required: true,
    },
    buildDetailsLoading: {
      type: Boolean,
      required: true,
    },
    buildDetails: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expanded: [],
      screenName: 'build-list',
      dynamicSearch: '',
      options: {},
      deleteDialogs: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.load();
  },
  watch: {
    selectedBuild(newValue) {
      if (newValue) {
        this.loadBuildDetails(newValue);
      }
    },
  },
  computed: {
    selectedBuild() {
      return this.expanded[0];
    },
    headers() {
      return [
        {
          text: this.$t('builds.list.id'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('builds.list.humanReadableId'),
          align: 'left',
          sortable: true,
          value: 'humanReadableId',
        },
        {
          text: this.$t('builds.list.paymentEnv'),
          align: 'left',
          sortable: true,
          value: 'paymentEnv',
        },
        {
          text: this.$t('builds.list.ref'),
          align: 'left',
          sortable: true,
          value: 'ref',
        },
        {
          text: this.$t('builds.list.status'),
          align: 'left',
          sortable: true,
          value: 'status',
        },
      ];
    },
  },
  methods: {
    async load() {
      this.$emit('loadData');
      if (this.selectedBuild) {
        this.loadBuildDetails(this.selectedBuild);
      }
    },
    loadBuildDetails(item) {
      this.$emit('loadBuildDetails', item);
    },
  },
};
</script>

<style scoped>
  .status {
    margin-left: 5px;
  }
</style>

<template>
  <v-container>
    <customer-data-get-item
      v-model="itemInfoPopup"
      :customer="customerInfo"
    ></customer-data-get-item>
    <customer-data-mutation
      v-if="showModal"
      :type="itemCreatePopup"
      :customerData="customerInfo"
      @updateParent="updateComponent"
      @close="closeModal"
    ></customer-data-mutation>
    <import-data-modal
      v-if="showImportModal"
      @updateParent="updateComponent"
      @close="closeImportModal"
    ></import-data-modal>
    <v-row>
      <v-col cols='4'>
        <v-text-field
          :label="$t('customerData.humanReadableId')"
          outlined
          hide-details
          no-filter
          v-on:input="debounceInput"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="customers"
      :disable-sort=true
      :loading="customerDataLoading"
      :items-per-page="100"
      hide-default-footer
    >
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template v-slot:item.actions="{item}">
        <v-icon small class="mr-2" @click="showItem(item)">
          mdi-information
        </v-icon>

        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon small class="mr-2" @click="importItem(item)">
          mdi-publish
        </v-icon>
      </template>
    </v-data-table>

    <div class="text-right pt-2">
      <div class="my-2">
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!cursors.length"
          @click="loadPrev()"
        >
          <v-icon small>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn
          small
          color="primary"
          class="mr-2"
          :disabled="!cursor"
          @click="loadNext()"
        >
          <v-icon small>mdi-skip-next</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import CustomerDataGetItem from './CustomerDataGetItem.vue';
import CustomerDataMutation from './CustomerDataMutation.vue';
import ImportDataModal from './ImportDataModal.vue';

export default {
  name: 'customer-data',
  components: { CustomerDataGetItem, CustomerDataMutation, ImportDataModal },
  data: () => ({
    cursors: [],
    itemInfoPopup: false,
    itemCreatePopup: false,
    customerInfo: {},
    humanReadableIdFilter: '',
    showImportModal: false,
    headers: [
      { text: 'ID', align: 'start', value: 'id' },
      { text: 'Import Status', value: 'importStatus' },
      { text: 'Human Readable Id', value: 'humanReadableId' },
      { text: 'Name', value: 'name' },
      { text: 'Dispatch Name', value: 'dispatchName' },
      {
        text: 'Created (UTC)',
        value: 'createdAt',
      },
      { text: 'Actions', value: 'actions' },
    ],
  }),
  computed: {
    ...mapGetters({
      customers: 'customerData/customers',
      customerDataLoading: 'customerData/customerDataListingInProgress',
      total: 'customerData/total',
      cursor: 'customerData/cursor',
      count: 'customerData/count',
    }),
    showModal() {
      return this.itemCreatePopup === 'edit' || this.itemCreatePopup === 'create';
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('customerData/list', { humanReadableId: this.humanReadableIdFilter });
    },
    async showItem(item) {
      if (!this.customerDataLoading) {
        await this.$store.dispatch('customerData/item', item.id);
        this.customerInfo = this.$store.getters['customerData/customer'];
        this.itemInfoPopup = true;
      }
    },
    async createItem() {
      if (!this.customerDataLoading) {
        this.customerInfo = {};
        this.itemCreatePopup = 'create';
      }
    },
    async editItem(item) {
      if (!this.customerDataLoading) {
        await this.$store.dispatch('customerData/item', item.id);
        this.customerInfo = this.$store.getters['customerData/customer'];
        this.itemCreatePopup = 'edit';
      }
    },
    async importItem(item) {
      if (!this.customerDataLoading) {
        await this.$store.dispatch('customerData/item', item.id);
        await this.$store.dispatch('customerData/getImportData', item.id);
        this.showImportModal = true;
      }
    },
    async updateComponent() {
      await this.initialize();
    },
    async loadNext() {
      this.cursors.push(this.cursor);
      await this.$store.dispatch('customerData/list', { cursor: this.cursor, humanReadableId: this.humanReadableIdFilter });
    },
    async loadPrev() {
      this.cursors.pop();
      await this.$store.dispatch('customerData/list', { cursor: this.cursors[this.cursors.length - 1], humanReadableId: this.humanReadableIdFilter });
    },
    debounceInput: debounce(function (value) {
      this.humanReadableIdFilter = value;
    }, 500),
    closeModal() {
      this.itemCreatePopup = false;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    saveImport() {

    },
  },
  watch: {
    humanReadableIdFilter() {
      this.initialize();
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

import axios from 'axios';
import _ from 'lodash';
import { Authentication } from '@icabbi/vue-authentication';


const getMaxUpdateRevision = function getMaxEtags(data) {
  const revisions = Object.values(data)
    .map(({ bookingChannelUpdateRevision, bookingChannelCheckRevision }) => ({
      bookingChannelUpdateRevision, bookingChannelCheckRevision,
    }));
  return _.maxBy(revisions, 'bookingChannelUpdateRevision') || {};
};

const getMinCheckRevision = function getMinEtags(data) {
  const revisions = Object.values(data)
    .map(({ bookingChannelUpdateRevision, bookingChannelCheckRevision }) => ({
      bookingChannelUpdateRevision, bookingChannelCheckRevision,
    }));
  return _.minBy(revisions, 'bookingChannelCheckRevision') || {};
};

const getCheckParams = function getCheckParams(humanReadableId, context) {
  const params = { humanReadableId };
  if (context.getters.bookingChannelUpdateRevision) {
    params.bookingChannelUpdateRevision = context.getters.bookingChannelUpdateRevision;
  }
  return params;
};

const defaultState = {
  configurationState: null,
  configurationStateCheckInProgress: false,
  configurationStateOnboardingInProgress: false,
  fleetAssociationInProgress: false,
  onboardingApiServiceClient: null,
  onboardConnectorInProgress: false,
  notificationSetupInProgress: false,
  businessSolutionInProgress: false,
  consoleAdminInProgress: false,
  bookingChannelUpdateRevision: null,
  silentCheckInProgress: null,
  featuresInProgress: false,
  humanReadableId: null,
};

const getters = {
  bookingChannelUpdateRevision: state => state.bookingChannelUpdateRevision,
  silentCheckInProgress: state => state.silentCheckInProgress,
  configurationState: state => state.configurationState,
  configurationStateCheckInProgress: state => state.configurationStateCheckInProgress,
  configurationStateOnboardingInProgress: state => state.configurationStateOnboardingInProgress,
  fleetAssociationInProgress: state => state.fleetAssociationInProgress,
  onboardConnectorInProgress: state => state.onboardConnectorInProgress,
  notificationSetupInProgress: state => state.notificationSetupInProgress,
  businessSolutionInProgress: state => state.businessSolutionInProgress,
  consoleAdminInProgress: state => state.consoleAdminInProgress,
  featuresInProgress: state => state.featuresInProgress,
  humaneReadableId: state => state.humaneReadableId,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async resetState(context) {
    context.commit('setConfigurationState', null);
  },
  async getCheckState(context, { humanReadableId }) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context).post('/check', {
        humanReadableId,
      });
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.checkError', error },
        { root: true },
      );
      throw error;
    }
  },
  async check(context, { humanReadableId }) {
    try {
      context.commit('setConfigurationStateCheckInProgress', true);
      if (humanReadableId !== context.getters.humanReadableId) {
        await context.commit('setHumanReadableId', humanReadableId);
        context.commit('setBookingChannelUpdateRevision', null);
      }
      const { data } = await context.getters.onboardingApiServiceClient(context).post('/check', getCheckParams(humanReadableId, context));

      const { bookingChannelCheckRevision } = getMinCheckRevision(data);
      if (parseInt(bookingChannelCheckRevision, 10)
        < parseInt(context.getters.bookingChannelUpdateRevision, 10)) {
        context.dispatch('silentCheck', { humanReadableId });
      } else {
        context.commit('setConfigurationState', data);
      }
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.checkError', error },
        { root: true },
      );
    } finally {
      context.commit('setConfigurationStateCheckInProgress', false);
    }
  },
  async silentCheck(context, { humanReadableId }) {
    try {
      context.commit('setSilentCheckInProgress', true);
      const { data } = await context.getters.onboardingApiServiceClient(context).post('/check', getCheckParams(humanReadableId, context));
      const { bookingChannelCheckRevision } = getMinCheckRevision(data);
      if (parseInt(bookingChannelCheckRevision, 10)
        < parseInt(context.getters.bookingChannelUpdateRevision, 10)) {
        context.dispatch('silentCheck', { humanReadableId });
      } else {
        context.commit('setConfigurationState', data);
        context.commit('setSilentCheckInProgress', false);
      }
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.checkError', error },
        { root: true },
      );
      context.commit('setSilentCheckInProgress', false);
    }
  },
  async onboard(context, { humanReadableId }) {
    try {
      context.commit('setConfigurationStateOnboardingInProgress', true);
      if (humanReadableId !== context.getters.humanReadableId) {
        await context.commit('setHumanReadableId', humanReadableId);
        context.commit('setBookingChannelUpdateRevision', null);
      }
      const { data } = await context.getters.onboardingApiServiceClient(context).post('/onboard', { humanReadableId });
      const { bookingChannelUpdateRevision } = getMaxUpdateRevision(data);
      context.commit('setBookingChannelUpdateRevision', bookingChannelUpdateRevision);
      context.dispatch('silentCheck', { humanReadableId });
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.onboardingError', error },
        { root: true },
      );
    } finally {
      context.commit('setConfigurationStateOnboardingInProgress', false);
    }
  },
  async onboardSystemComponents(context, { bookingChannelId }) {
    await context.getters.onboardingApiServiceClient(context).post('/onboard/system-components', { bookingChannelId });
  },
  async associateFleet(context, {
    bookingChannelId, fleetId, humanReadableId, associationType, silentCheck = true,
  }) {
    try {
      context.commit('setFleetAssociationInProgress', true);
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/fleet-association', { bookingChannelId, fleetId, type: associationType });
      context.commit('setBookingChannelUpdateRevision', data.bookingChannelUpdateRevision);
      if (silentCheck) {
        context.dispatch('silentCheck', { humanReadableId });
      }
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.fleetAssociation.error', error },
        { root: true },
      );
    } finally {
      context.commit('setFleetAssociationInProgress', false);
    }
  },
  async onboardConnector(context, {
    bookingChannelId, connector, connectorType, humanReadableId,
  }) {
    try {
      context.commit('setOnboardConnectorInProgress', true);
      const { data } = await context.getters
        .onboardingApiServiceClient(context)
        .post(`/onboard/connectors/${connectorType}`, { bookingChannelId, connector });
      context.commit('setBookingChannelUpdateRevision', data.bookingChannelUpdateRevision);
      context.dispatch('silentCheck', { humanReadableId });
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: `onboarding.onboardConnectorError.${connectorType}`, error },
        { root: true },
      );
    } finally {
      context.commit('setOnboardConnectorInProgress', false);
    }
  },
  async setupNotificationPlatform(context, {
    bookingChannelId, platform, attributes, throwFail = false,
  }) {
    try {
      context.commit('setNotificationSetupInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/notifications', { bookingChannelId, platform, attributes });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: `onboarding.setupNotificationSuccess.${platform}` },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: `onboarding.setupNotificationError.${platform}`, error },
        { root: true },
      );

      if (throwFail === true) {
        throw error;
      }
    } finally {
      context.commit('setNotificationSetupInProgress', false);
    }
  },
  async createBookingChannelAdmin(context, {
    bookingChannelId, givenName, familyName, email, communicationLanguage,
  }) {
    try {
      context.commit('setBusinessSolutionInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/business-solution', {
          bookingChannelId, email, givenName, familyName, communicationLanguage,
        });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.businessSolutionSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.businessSolutionError', error },
        { root: true },
      );
    } finally {
      context.commit('setBusinessSolutionInProgress', false);
    }
  },
  async createConsoleAdmin(context, {
    bookingChannelId, givenName, familyName, email, communicationLanguage, product, throwFail = false,
  }) {
    try {
      context.commit('setConsoleAdminInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post(`/onboard/${product}-console`, {
          bookingChannelId, email, givenName, familyName, communicationLanguage,
        });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: `onboarding.${product}Console.success` },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: `onboarding.${product}Console.error`, error },
        { root: true },
      );
      if (throwFail === true) {
        throw error;
      }
    } finally {
      context.commit('setConsoleAdminInProgress', false);
    }
  },
  async onboardFeatures(context, {
    bookingChannelId, features, humanReadableId,
  }) {
    try {
      context.commit('setFeaturesInProgress', true);
      const { data } = await context.getters
        .onboardingApiServiceClient(context)
        .post('/onboard/features', { bookingChannelId, features });
      context.commit('setBookingChannelUpdateRevision', data.bookingChannelUpdateRevision);
      context.dispatch('silentCheck', { humanReadableId });
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.onboardFeaturesError', error },
        { root: true },
      );
    } finally {
      context.commit('setFeaturesInProgress', false);
    }
  },
};

const mutations = {
  setConfigurationState(state, configurationState) {
    state.configurationState = configurationState;
  },
  setConfigurationStateCheckInProgress(state, configurationStateCheckInProgress) {
    state.configurationStateCheckInProgress = configurationStateCheckInProgress;
  },
  setConfigurationStateOnboardingInProgress(state, configurationStateOnboardingInProgress) {
    state.configurationStateOnboardingInProgress = configurationStateOnboardingInProgress;
  },
  setFleetAssociationInProgress(state, fleetAssociationInProgress) {
    state.fleetAssociationInProgress = fleetAssociationInProgress;
  },
  setOnboardConnectorInProgress(state, onboardConnectorInProgress) {
    state.onboardConnectorInProgress = onboardConnectorInProgress;
  },
  setNotificationSetupInProgress(state, notificationSetupInProgress) {
    state.notificationSetupInProgress = notificationSetupInProgress;
  },
  setBusinessSolutionInProgress(state, businessSolutionInProgress) {
    state.businessSolutionInProgress = businessSolutionInProgress;
  },
  setConsoleAdminInProgress(state, consoleAdminInProgress) {
    state.consoleAdminInProgress = consoleAdminInProgress;
  },
  setBookingChannelUpdateRevision(state, revision) {
    state.bookingChannelUpdateRevision = revision;
  },
  setSilentCheckInProgress(state, silentCheckInProgress) {
    state.silentCheckInProgress = silentCheckInProgress;
  },
  setFeaturesInProgress(state, featuresInProgress) {
    state.featuresInProgress = featuresInProgress;
  },
  setHumanReadableId(state, humanReadableId) {
    state.humanReadableId = humanReadableId;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

const defaultState = {
  oidcClientId: '',
};

const getters = {
  oidcClientId: state => state.oidcClientId,
};

const actions = {};

const mutations = {
  setBookingChannelConfig(state, {
    oidcClientId,
  }) {
    state.oidcClientId = oidcClientId;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.logoFormat"
          :validator="$v.bookingChannel.logoFormat">
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.logoFormat"
            label="Logo Format"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.primary"
          :validator="$v.bookingChannel.colorScheme.primary">
          <color-preview :color="bookingChannel.colorScheme.primary"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.primary"
            label="Primary Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.primaryDark"
          :validator="$v.bookingChannel.colorScheme.primaryDark">
          <color-preview :color="bookingChannel.colorScheme.primaryDark"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.primaryDark"
            label="Primary Dark Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.primaryFont"
          :validator="$v.bookingChannel.colorScheme.primaryFont">
          <color-preview :color="bookingChannel.colorScheme.primaryFont"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.primaryFont"
            label="Primary Font Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.secondary"
          :validator="$v.bookingChannel.colorScheme.secondary">
          <color-preview :color="bookingChannel.colorScheme.secondary"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.secondary"
            label="Secondary Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.error"
          :validator="$v.bookingChannel.colorScheme.error">
          <color-preview :color="bookingChannel.colorScheme.error"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.error"
            label="Error Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.loginLogoBackground"
          :validator="$v.bookingChannel.colorScheme.loginLogoBackground">
          <color-preview :color="bookingChannel.colorScheme.loginLogoBackground"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.loginLogoBackground"
            label="Login Logo Background Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.appIconBackground"
          :validator="$v.bookingChannel.colorScheme.appIconBackground">
          <color-preview :color="bookingChannel.colorScheme.appIconBackground"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.appIconBackground"
            label="App Icon Background Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.colorScheme.loginActionBackground"
          :validator="$v.bookingChannel.colorScheme.loginActionBackground">
          <color-preview :color="bookingChannel.colorScheme.loginActionBackground"></color-preview>
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.colorScheme.loginActionBackground"
            label="Login Action Background Color"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { bookingChannelVuelidate } from '@/components/validationDatas/bookingChannelValidationRules';
import ColorPreview from '@/components/customerData/ColorPreview.vue';

export default {
  name: 'ThemeForm',
  components: {
    ColorPreview,
  },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.$v.$touch();
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
    };
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  validations() {
    return {
      bookingChannel: {
        logoFormat: {
          required,
        },
        colorScheme: bookingChannelVuelidate.colorScheme,
      },
    };
  },
};
</script>

<style scoped>
</style>

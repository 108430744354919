<template>
  <loader color="primary" :is-loading="isLoading">
    <v-col cols='12'>
      <v-row>
        <v-col cols='12'>
          <v-file-input
            v-model="credentialsFile"
            :label="$t(`onboarding.credentials`)"
            :placeholder="$t('onboarding.credentials')"
            accept=".json"
            outlined
            hide-details
            @change="processCredentialsFile"
          >
          </v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <span v-if="errorMessage" class="error--text">
            {{ errorMessage }}
          </span>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="4">
          <v-btn
            block
            large
            :disabled="isSaveDisabled"
            class="header-button"
            color="primary"
            @click="saveAttributes">
            {{ $t(`save`) }}
          </v-btn>
        </v-col>
      </v-row>

    </v-col>
  </loader>
</template>

<script>
import FileReaderUtil from '@/util/FileReaderUtil';
import androidAppValidation from '../../../validationDatas/androidAppValidation';

export default {
  name: 'android-form',
  validations() {
    return androidAppValidation;
  },
  data() {
    return {
      errorMessage: null,
      credentialsFile: null,
      androidApp: {
        credentials: '',
        principal: '',
      },
    };
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    isSaveDisabled() {
      return !(this.credentialsFile && this.androidApp.credentials);
    },
  },
  methods: {
    async processCredentialsFile(file) {
      this.error = null;

      try {
        this.androidApp.credentials = await FileReaderUtil.stringifyJsonFileContent(file);

      } catch (error) {
        this.errorMessage = error.message || 'JSON file could not be processed correctly';
      }
    },
    saveAttributes() {
      this.$emit('save-attributes', this.androidApp);
    },
  },
};
</script>

<style scoped>

</style>

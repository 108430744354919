<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>
    <v-row>
      <v-col cols="12">
        <validated-component
          name="name"
          v-model="bookingChannel.connectors.user.name"
          :validator="$v.bookingChannel.connectors.user.name">
          <v-select
            v-model="bookingChannel.connectors.user.name"
            :label="$t('userConnectorForm.name')"
            outlined
            hide-details
            :items="userNames"
            no-filter>
          </v-select>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.configuration.serverKey"
          :validator="$v.bookingChannel.connectors.user.configuration.serverKey">
          <v-text-field
            v-model="bookingChannel.connectors.user.configuration.serverKey"
            :label="$t('userConnectorForm.serverKey')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.uiConfiguration.apiKey"
          :validator="$v.bookingChannel.connectors.user.uiConfiguration.apiKey">
          <v-text-field
            v-model="bookingChannel.connectors.user.uiConfiguration.apiKey"
            :label="$t('userConnectorForm.apiKey')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.uiConfiguration.projectId"
          :validator="$v.bookingChannel.connectors.user.uiConfiguration.projectId">
          <v-text-field
            v-model="bookingChannel.connectors.user.uiConfiguration.projectId"
            :label="$t('userConnectorForm.projectId')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.uiConfiguration.authDomain"
          :validator="$v.bookingChannel.connectors.user.uiConfiguration.authDomain">
          <v-text-field
            v-model="bookingChannel.connectors.user.uiConfiguration.authDomain"
            :label="$t('userConnectorForm.authDomain')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.uiConfiguration.storageBucket"
          :validator="$v.bookingChannel.connectors.user.uiConfiguration.storageBucket">
          <v-text-field
            v-model="bookingChannel.connectors.user.uiConfiguration.storageBucket"
            :label="$t('userConnectorForm.storageBucket')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="bookingChannel.connectors.user.uiConfiguration.messagingSenderId"
          :validator="$v.bookingChannel.connectors.user.uiConfiguration.messagingSenderId">
          <v-text-field
            v-model="bookingChannel.connectors.user.uiConfiguration.messagingSenderId"
            :label="$t('userConnectorForm.messagingSenderId')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col  cols=12>
        <h4 class="blue--text">Service Account JSON</h4>
      </v-col>
      <v-col cols="12">
        <validated-component
          v-model="bookingChannel.connectors.user.configuration.serviceAccount"
          :validator="$v.serviceAccount">
          <v-file-input
            label="Click to upload the service account JSON file"
            class="file-input-json"
            @change="updateServiceAccount"
            outlined
            hide-details
            accept=".json"
          >
          </v-file-input>
        </validated-component>
        <v-text-field
          v-model="serviceAccountJsonString"
          readonly
          hide-details
          :label="$t('userConnectorForm.serviceAccountJson')"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';

export default {
  name: 'UserConnectorForm',
  components: {
  },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      serviceAccount: null,
      isDisplayFormError: false,
      userNames: ['firebase'],
    };
  },
  created() {
    if (this.isRemoteServiceAccountAvailable()) {
      this.serviceAccount = this.getRemoteServiceAccount();
    }
    this.$v.$touch();
  },
  methods: {
    isRemoteServiceAccountAvailable() {
      return !!this.remoteState.bookingChannel.connectors.user.configuration.serviceAccount;
    },
    getRemoteServiceAccount() {
      return this.remoteState.bookingChannel.connectors.user.configuration.serviceAccount;
    },
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    updateServiceAccount(data) {
      const reader = new FileReader();
      reader.onload = () => {
        const json = reader.result;
        window.setTimeout(() => {
          this.setServiceAccount(json);
        }, 0);
      };

      reader.readAsText(data);
    },
    setServiceAccount(data) {
      // The serviceAccount is stored in a separate data property to make sure
      // vuejs change detection works correctly.
      // If we were to add it straight to the booking channel object, change detection would not work
      // as vuejs cannot detect addition or removal of existing properties.
      this.serviceAccount = JSON.parse(data);
    },
    async save() {
      const payload = this.bookingChannel;
      // Attach the service account to the correct booking channel sub object.
      payload.connectors.user.configuration.serviceAccount = this.serviceAccount;
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload,
      });
    },
  },
  computed: {
    serviceAccountJsonString() {
      if (this.serviceAccount) {
        return JSON.stringify(this.serviceAccount);
      }
      return null;
    },
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  validations() {
    return {
      serviceAccount: {
        minLength: minLength(1),
        required,
      },
      bookingChannel: {
        connectors: {
          user: {
            name: {
              minLength: minLength(1),
              maxLength: maxLength(255),
              required,
            },
            configuration: {
              serverKey: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
            },
            uiConfiguration: {
              apiKey: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
              projectId: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
              authDomain: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
              storageBucket: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
              messagingSenderId: {
                minLength: minLength(1),
                maxLength: maxLength(255),
                required,
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>
</style>

export default {
  props: {
    step: {
      required: true,
      type: Object,
    },
    statePart: {
      type: Object,
    },
  },
  methods: {
    getIcon(completed) {
      return completed ? 'check' : 'error_outline';
    },
    getColor(completed) {
      return completed ? 'success' : 'error';
    },
  },
};

export const checkStateDisplayMethods = {
  methods: {
    getIcon(completed) {
      return completed ? 'check' : 'error_outline';
    },
    getColor(completed) {
      return completed ? 'success' : 'error';
    },
  },
};

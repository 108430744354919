<template>
  <div>
    <v-row>
      <v-col >
        <h4>Default Address Configuration</h4>
      </v-col>
    </v-row>

    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
      <ul>
        <li v-for="(error, index) in getErrors()" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <v-row class="my-2">
      <template v-if="!isDefaultRadiusSet">
        <v-col cols="12">
            <v-btn
              color="success"
              outlined
              x-small
              @click="addDefaultRadius"
            >{{
                $t('addressSearchConfigurationForm.addDefaultRadiusButton')
              }}
            </v-btn>
        </v-col>
      </template>
      <template v-if="isDefaultRadiusSet">
        <v-col cols="12" class="form-part">
          <validated-component
            v-model="bookingChannel.addressSearchConfiguration.radiusConfiguration.defaultRadius"
            :validator="$v.bookingChannel.addressSearchConfiguration.radiusConfiguration.defaultRadius">
            <v-select
              v-model="bookingChannel.addressSearchConfiguration.radiusConfiguration.defaultRadius"
              :label="$t('addressSearchConfigurationForm.defaultRadius')"
              outlined
              :items="radiusOptions"
              hide-details
            >
            </v-select>
          </validated-component>
          <v-col cols="12">
            <network-polygon-map
              v-if="this.pageSettings.isMapEnabledForDefaultRadiusRadius"
              :latitude="bookingChannel.location.latitude"
              :longitude="bookingChannel.location.longitude"
              :radius="bookingChannel.addressSearchConfiguration.radiusConfiguration.defaultRadius"
            ></network-polygon-map>
            <v-row v-else class="mb-4">
              <v-col cols="12" class="px-0">
                <v-btn
                  @click="enableNetworkMapForDefaultRadius"
                  color="primary">
                  {{ $t('addressSearchConfigurationForm.showMapButtonForDefaultRadius') }}
                </v-btn>
              </v-col>
              <p><br/><br/></p>
            </v-row>
          </v-col>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="error"
            outlined
            x-small
            @click="removeDefaultRadius"
          >{{
              $t('addressSearchConfigurationForm.removeDefaultRadiusButton')
            }}
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { decimal } from 'vuelidate/lib/validators';
import { isInList } from '@/validation/validationMethods';
import NetworkPolygonMap from '@/components/fleetManagement/NetworkPolygonMap.vue';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';

export default {
  name: 'AddressSearchConfigurationForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  components: {
    NetworkPolygonMap,
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
      pageSettings: {
        isMapEnabledForDefaultRadiusRadius: false,
      },
      radiusOptions: [
        {
          text: '50 km',
          value: 50000,
        },
        {
          text: '60 km',
          value: 60000,
        },
        {
          text: '70 km',
          value: 70000,
        },
        {
          text: '80 km',
          value: 80000,
        },
        {
          text: '100 km',
          value: 100000,
        },
        {
          text: '120 km',
          value: 120000,
        },
        {
          text: '140 km',
          value: 140000,
        },
      ],

      addressSearchConfigurationFormDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('addressSearchConfiguration'),
    };
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
    isDefaultRadiusSet() {
      return this.bookingChannel?.addressSearchConfiguration?.radiusConfiguration?.defaultRadius;
    },
  },
  created() {
    this.$v.$touch();
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.getErrors().length) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    getErrors() {
      return this.addressSearchConfigurationFormDefinition.getErrors({ bookingChannel: this.bookingChannel });
    },
    addDefaultRadius() {
      this.$set(this.bookingChannel, 'addressSearchConfiguration', {
        ...this.bookingChannel.addressSearchConfiguration,
        radiusConfiguration: {
          ...this.bookingChannel?.addressSearchConfiguration?.radiusConfiguration,
          defaultRadius: 50,
        },
      });
    },
    removeDefaultRadius() {
      this.bookingChannel.addressSearchConfiguration.radiusConfiguration.defaultRadius = null;
    },
    enableNetworkMapForDefaultRadius() {
      this.pageSettings.isMapEnabledForDefaultRadiusRadius = true;
    },
  },
  validations() {
    return {
      bookingChannel: {
        addressSearchConfiguration: {
          radiusConfiguration: {
            defaultRadius: {
              decimal,
              isInList: isInList(this.radiusOptions.map(item => item.value)),
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

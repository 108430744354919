<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
      <ul>
        <li v-for="(error, index) in getErrors()" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>
    <v-row>
      <v-col cols="12">
        <validated-component
          v-model="bookingChannel.features"
          :validator="$v.bookingChannel.features">
          <v-jsoneditor
            :plus="false"
            height="400px"
            v-model="bookingChannel.features">
          </v-jsoneditor>
        </validated-component>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';

export default {
  name: 'FeaturesForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
      featuresFormDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('features'),
    };
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      if (this.getErrors().length) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    getErrors() {
      return this.featuresFormDefinition.getErrors({ bookingChannel: this.bookingChannel });
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });
    },
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  validations() {
    return {
      bookingChannel: {
        features: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <onboarding-step :step="step">
    <v-row>
      <v-col cools="7">
        <app-svg-welcome
          :primary="primary"
          :primaryFont="primaryFont"
        ></app-svg-welcome>
      </v-col>
      <v-col cols="5">
        <div class="color-container">
          <span class="color-bold">{{ $t('appPreview.primary') }}</span>
          <span class="color-value">{{ primary }}</span>
          <v-swatches
            v-model="primary"
            show-fallback
            fallback-input-type="color"
            popover-x="left"
          ></v-swatches>
          <span class="undo" @click="() => resetColor('primary')"><v-icon>clear</v-icon></span>
        </div>
        <div>
          <span class="color-bold">{{ $t('appPreview.primaryFont') }}</span>
          <span class="color-value">{{ primaryFont }}</span>
          <v-swatches
            v-model="primaryFont"
            show-fallback
            fallback-input-type="color"
            popover-x="left"
          ></v-swatches>
          <span class="undo" @click="() => resetColor('primary')"><v-icon>clear</v-icon></span>
        </div>
      </v-col>
    </v-row>
  </onboarding-step>
</template>

<script>
import VSwatches from 'vue-swatches';
import AppSvgWelcome from './AppSVGWelcome.vue';
import OnboardingStep from '../OnboardingStep.vue';
import OnboardingStepMixin from '../../../../mixins/onboardingStep.mixin';

export default {
  name: 'AppPreviewStep',
  components: {
    AppSvgWelcome,
    VSwatches,
    OnboardingStep,
  },
  mixins: [OnboardingStepMixin],
  data: () => ({
    primary: '',
    primaryFont: '',
  }),
  computed: {
    colorScheme() {
      if (this.statePart && this.statePart.values && this.statePart.values.colorScheme) {
        return this.statePart.values.colorScheme;
      }
      return {};
    },
  },
  beforeMount() {
    this.primary = `${this.colorScheme.primary}`;
    this.primaryFont = `${this.colorScheme.primaryFont}`;
  },
  methods: {
    resetColor(color) {
      this[`${color}`] = `${this.colorScheme.colorScheme[`${color}`]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-bold {
  font-weight: 700;
}

.color-value {

}

.color-container {
  margin-bottom: 15px;
}

.undo {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
}

.vue-swatches {
  vertical-align: middle;
  margin-left: 10px;
}
</style>

<template>
  <onboarding-step :step="step" v-if="statePart" color="primary">
    <loader :is-loading="featuresInProgress" color="primary">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-list-item v-for="(detail, index) in statePart.details" :key="index">
              <v-list-item-icon>
                <v-icon :color="getColor(detail)">{{ getIcon(detail) }}</v-icon>
              </v-list-item-icon>
              <span>{{ detail }}</span>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-jsoneditor
              :plus="false"
              height="400px"
              @error="onError"
              @input="onInput"
              v-model="updateData">
            </v-jsoneditor>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 alert-changes">
            <v-alert
              v-show="changesNotSaved"
              icon="error_outline"
              text
              dense
              color="orange"
              transition="slide-y-transition"
            >
              {{ $t('onboarding.changesNotSaved') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn
              block
              large
              :disabled="disabled"
              class="header-button"
              color="primary"
              @click="saveConnectorData">
              {{ $t(`save`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </loader>
  </onboarding-step>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';
import OnboardingStep from './OnboardingStep.vue';
import BookingChannelStepMixin from '../../../mixins/bookingChannelStep.mixin';

export default {
  name: 'features-step',
  components: { OnboardingStep },
  mixins: [
    // order is important
    OnboardingStepMixin,
    BookingChannelStepMixin,
  ],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
    humanReadableId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.updateData = _.cloneDeep(this.statePart.values);
  },
  watch: {
    statePart(newStatePart, oldStatePart) {
      if ((newStatePart !== oldStatePart && !this.dirty)) {
        this.updateData = _.cloneDeep(newStatePart.values);
      }
    },
  },
  data() {
    return {
      disabled: false,
      updateData: {},
      dirty: false,
    };
  },
  computed: {
    ...mapGetters({
      featuresInProgress: 'onboarding/featuresInProgress',
    }),
    changesNotSaved() {
      return this.dirty && !_.isEqual(this.statePart.values, this.updateData);
    },
  },
  methods: {
    async saveConnectorData() {
      await this.$store.dispatch('onboarding/onboardFeatures', {
        bookingChannelId: this.bookingChannelId,
        features: this.updateData,
        humanReadableId: this.humanReadableId,
      });
      this.dirty = false;
    },
    onError() {
      this.disabled = true;
    },
    onInput() {
      this.dirty = true;
      this.disabled = false;
    },
  },
};
</script>

<style scoped>
  .block_content {
    width: 100%;
  }
  .alert-changes {
    min-height: 80px;
  }
</style>

<style>
  .f-confirm {
    color: #fff;
    background: #000000 !important;
  }
</style>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      hide-default-footer
      :multi-sort="false"
      @page-count="pageCount = $event"
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :footer-props="{showFirstLastPage: true}"
      class="ic-table elevation-0 ic-table--heavy-headers"
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="center">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
                Fleets
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-btn dark style="margin-right: 1rem;" class="header-list-button" color="red" v-if="selectedFleets.length > 0" @click="displayConfirmDialog = true">
                  Remove
                </v-btn>
                <v-btn
                  style="margin-right: 1rem;"
                  color="success"
                  class="header-list-button"
                  @click="addFleetClick"
                >
                  <span class="hidden-sm-and-down">Add Fleet</span>
                  <v-icon class="hidden-md-and-up">$icons_system_plus</v-icon>
                </v-btn>
                <v-text-field
                  v-model="dynamicSearch"
                  append-icon="$icons_system_search"
                  :label="'Search'"
                  single-line
                  clearable
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="font-weight-black black--text">{{ header.text }}</span>
      </template>
      <template v-slot:item.id="{ item }">
        <string-copy-tooltip :text="item.id"></string-copy-tooltip>
      </template>
      <template v-slot:item.actionViewFleet="{ item }">
        <v-btn small color="primary" @click="viewFleetClick(item)">
          View
        </v-btn>
      </template>
      <template v-slot:item.actionSelectFleet="{ item }">
        <v-checkbox
          v-model="selectedFleets"
          :value="item.id"
        ></v-checkbox>
      </template>
    </v-data-table>
    <div class="text-center pt-8">
      <v-pagination v-model="page" :length="pageCount" total-visible="10"></v-pagination>
    </div>
    <v-dialog
      max-width="500"
      v-if="displayConfirmDialog"
      :value="true"
      persistent
    >
      <v-card class="mx-auto">
        <v-card-title>
          <span class="headline">Confirm</span>
        </v-card-title>
        <v-card-text style="height: 100%; overflow-y: hidden;">
          <v-row>
            <v-col>
              <h3>Remove the fleet(s) from the network ?</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="displayConfirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red" dark @click="removeFleets">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import debounce from 'debounce';
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';

export default {
  name: 'NetworkFleetsList',
  components: {
    StringCopyTooltip,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    viewFleetClick(fleet) {
      this.$emit('viewFleetClick', fleet);
    },
    addFleetClick() {
      this.$emit('addFleetClick');
    },
    async load() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$emit('loadData', {
        networkId: this.$route.params.id,
        page: page - 1,
        maxPerPage: itemsPerPage,
        dynamicSearch: this.dynamicSearch,
      });
    },
    removeFleets() {
      const removedFleets = [];
      this.selectedFleets.forEach((fleetId) => {
        removedFleets.push({ fleetId });
      });
      this.$emit('manageNetworkFleets', { removedFleets });
      this.debouncedLoad();
      this.selectedFleets = [];
      this.displayConfirmDialog = false;
    },
  },
  data() {
    return {
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: true,
      selectedFleetId: null,
      dynamicSearch: '',
      selectedFleets: [],
      displayConfirmDialog: false,
    };
  },
  created() {
    this.debouncedLoad = debounce(this.load, 500);
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
    dynamicSearch(v) {
      if (!v || v.length !== 1) {
        this.page = 1;
        this.debouncedLoad();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Human Readable ID',
          sortable: false,
          value: 'humanReadableId',
        },
        {
          text: 'Name',
          sortable: false,
          value: 'companyName',
        },
        {
          text: '',
          value: 'actionViewFleet',
          sortable: false,
          align: 'end',
        },
        {
          text: '',
          value: 'actionSelectFleet',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>

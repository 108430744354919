<template>
  <div>
    <div class="vehicle_icon_image" :class="{has_image: hasImageInVehicle()}">
      <v-btn v-if="hasImageInVehicle()" @click="removeVehicleIcon()" small color="error" class="vehicle_icon_delete_button">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-chip class="icon_type" outlined label>{{ type }}</v-chip>
      <v-avatar v-if="hasImageInVehicle()">
        <img :src="getImageSource()" :alt="vehicleTypeIcon.vehicleTitle">
      </v-avatar>
      <span v-else class="no_image_placeholder">{{ $t('common.noImage') }}</span>
    </div>
    <div v-if="hasMeta()" class="icon_meta" style="height: 72px">
      <div v-if="vehicleTypeIcon.dimensions">{{ $t('common.dimensions') }}: {{ vehicleTypeIcon.dimensions }}</div>
      <div v-if="vehicleTypeIcon.fileSize">{{ $t('common.fileSize') }}: {{ (vehicleTypeIcon.fileSize / 1000).toFixed(1) }} kB</div>
      <div v-if="vehicleTypeIcon.imageUrl"><string-copy-tooltip truncate :text="`${vehicleTypeIcon.imageUrl}?v=${new Date().getTime()}`" :additional-styles="getIconUrlStyles()"></string-copy-tooltip></div>
    </div>
    <div v-else style="height: 72px; padding-top: 5px;">
      <div class="skeleton_loader" style="width: 80%"></div>
      <div class="skeleton_loader" style="width: 40%"></div>
      <div class="skeleton_loader" style="width: 60%"></div>
    </div>
    <v-btn block text color="info" @click="showDialogueToUploadVehicleIcon()">{{ $t('common.uploadIcon') }}</v-btn>

    <input type="file" ref="uploadVehicleIconInput" class="d-none" accept="image/png, image/gif, image/jpeg">
  </div>
</template>

<script>
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';
import FileUploadUtil from '@/util/FileUploadUtil';
import _ from 'lodash';

export default {
  props: {
    vehicleTypeIcon: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    StringCopyTooltip,
  },
  mounted() {
    this.$refs.uploadVehicleIconInput.addEventListener('change', this.onVehicleIconUploaded.bind(this));
  },
  data() {
    return {

    };
  },
  methods: {
    showDialogueToUploadVehicleIcon() {
      this.$refs.uploadVehicleIconInput.click();
    },
    getIconUrlStyles() {
      return {
        maxWidth: '100%',
        color: 'inherit',
      };
    },
    getImageSource() {
      if (this.vehicleTypeIcon.isDirty && this.vehicleTypeIcon.imageBase64Encoded) {
        return this.vehicleTypeIcon.imageBase64Encoded;
      }
      return `${this.vehicleTypeIcon.imageUrl}?v=${new Date().getTime()}`;
    },
    hasImageInVehicle() {
      return this.vehicleTypeIcon.imageBase64Encoded || this.vehicleTypeIcon.imageUrl;
    },
    removeVehicleIcon() {
      this.$emit('update:vehicleTypeIcon', {
        dimensions: null,
        fileSize: null,
        fileType: this.vehicleTypeIcon.fileType,
        imageUrl: null,
        imageBase64Encoded: null,
        vehicleType: this.vehicleTypeIcon.vehicleType,
        vehicleTitle: this.vehicleTypeIcon.vehicleTitle,
        isRemovedFromDispatch: false,
        isDirty: true,
      });
    },
    // eslint-disable-next-line consistent-return
    async onVehicleIconUploaded(event) {
      const file = event.target.files[0];
      if (!file) {
        return null;
      }
      const {
        imageBase64Encoded, width, height, fileType, fileSize,
      } = await FileUploadUtil.getMetadataOfUploadedImage(file);
      this.$refs.uploadVehicleIconInput.value = null;

      const isValid = await this.validateUploadedImage({ fileSize, fileType });
      if (!isValid) {
        return null;
      }

      this.$emit('update:vehicleTypeIcon', {
        dimensions: `${width}x${height}`,
        fileSize,
        fileType,
        imageUrl: null,
        imageBase64Encoded,
        vehicleType: this.vehicleTypeIcon.vehicleType,
        vehicleTitle: this.vehicleTypeIcon.vehicleTitle,
        isRemovedFromDispatch: false,
        isDirty: true,
      });
    },
    async validateUploadedImage({ fileSize, fileType }) {
      if (fileSize <= 1024 * 1024 && _.includes(['png', 'jpeg', 'jpg'], fileType)) {
        return true;
      }
      await this.$store.dispatch(
        'globalMessages/addError',
        { message: 'fleetManagementForm.fleetBookingAppConfig.vehicleIcons.validationErrorMessage' },
        { root: true },
      );
      return false;
    },
    hasMeta() {
      return this.vehicleTypeIcon.dimensions || this.vehicleTypeIcon.fileSize || this.vehicleTypeIcon.imageUrl;
    },
  },
};
</script>

<style scoped>
.vehicle_icon_container .vehicle_icon_image{
  width: 100%;
  height: 128px;
  background: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #C5C5C5;
  position: relative;
  border-radius: 4px;
}
.vehicle_icon_container .vehicle_icon_image.has_image{
  background: #fff;
}
.vehicle_icon_container .vehicle_icon_image .no_image_placeholder{
  font-weight: bold;
  color: #BFBFBF;
}
.vehicle_icon_container .vehicle_icon_image img{
  border: 1px solid #dfdfdf;
}
.vehicle_icon_container .vehicle_icon_image .vehicle_icon_delete_button{
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}
.vehicle_icon_container .vehicle_icon_image:hover .vehicle_icon_delete_button{
  display: inline-block;
}
.vehicle_icon_container .vehicle_icon_image .icon_type{
  position: absolute;
  left: 5px;
  top: 5px;
}
.skeleton_loader{
  width: 100%;
  height: 10px;
  background: #EFEFEF;
  border: 1px dashed #C5C5C5;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>

import dayjs from 'dayjs';
import { getCodes } from 'country-list';
import { helpers } from 'vuelidate/lib/validators';
import { Semver } from '@/core/semver';

const locale = require('locale-codes');

export function nonZero() {
  return value => (value > 0) || (value < 0);
}

export function isInList(list) {
  return value => !helpers.req(value) || list.indexOf(value) >= 0;
}

export function isValidPaymentProvider(value) {
  if (!value) {
    return true;
  }
  return [
    'stripe',
    'stripe-ie',
    'stripe-uk',
    'stripe-us',
    'stripe-aus',
    'stripe-uk-test',
  ].includes(value);
}

export function isValidMarketingProvider(value) {
  if (!value) {
    return true;
  }
  return [
    'voucherify',
  ].includes(value);
}

export function isValidMarketingInstance(value) {
  if (!value) {
    return true;
  }
  return [
    'europe',
    'asia',
    'us',
  ].includes(value);
}

export function isValidExternalFee(value) {
  if (!value) {
    return true;
  }
  return [
    'javelin',
  ].includes(value);
}

export function isValidString(value) {
  if (!value) {
    return true;
  }
  return /^[A-zÀ-ÿ0-9\- ']*$/.test(value);
}

export function isValidDate(value) {
  if (!value) {
    return true;
  }
  return dayjs(value).isValid();
}

export function isValidLocale(value) {
  if (!value) {
    return true;
  }
  return !!(locale.getByTag(value));
}

export function isValidPhoneNumber(value) {
  if (!value) {
    return true;
  }

  return /^\+[0-9]{9,15}$/.test(value);
}

export function isBoolean(value) {
  if (!value) {
    return true;
  }
  return typeof value === 'boolean';
}

export function isHexadecimalColor(value) {
  if (!value) {
    return true;
  }
  return /^#([A-Fa-f0-9]{6})$/.test(value);
}

export function isHumanReadableId(value) {
  if (!value) { return true; }
  return /^[a-z0-9]+(-{1}[a-z0-9]+)*$/.test(value);
}

export function isValidPreauthType(value) {
  if (!value) { return true; }
  return ['FIXED', 'DYNAMIC'].includes(value);
}

export function isValidCountry(value) {
  if (!value) {
    return true;
  }
  return getCodes().includes(value);
}

export function isValidCurrency(value) {
  if (!value) {
    return true;
  }

  return /^AED|AFN|ALL|AMD|ANG|AOA|ARS|AUD|AWG|AZN|BAM|BBD|BDT|BGN|BHD|BIF|BMD|BND|BOB|BRL|BSD|BTN|BWP|BYR|BZD|CAD|CDF|CHF|CLP|CNY|COP|CRC|CUC|CUP|CVE|CZK|DJF|DKK|DOP|DZD|EGP|ERN|ETB|EUR|FJD|FKP|GBP|GEL|GGP|GHS|GIP|GMD|GNF|GTQ|GYD|HKD|HNL|HRK|HTG|HUF|IDR|ILS|IMP|INR|IQD|IRR|ISK|JEP|JMD|JOD|JPY|KES|KGS|KHR|KMF|KPW|KRW|KWD|KYD|KZT|LAK|LBP|LKR|LRD|LSL|LYD|MAD|MDL|MGA|MKD|MMK|MNT|MOP|MRO|MUR|MVR|MWK|MXN|MYR|MZN|NAD|NGN|NIO|NOK|NPR|NZD|OMR|PAB|PEN|PGK|PHP|PKR|PLN|PYG|QAR|RON|RSD|RUB|RWF|SAR|SBD|SCR|SDG|SEK|SGD|SHP|SLL|SOS|SPL|SRD|STD|SVC|SYP|SZL|THB|TJS|TMT|TND|TOP|TRY|TTD|TVD|TWD|TZS|UAH|UGX|USD|UYU|UZS|VEF|VND|VUV|WST|XAF|XCD|XDR|XOF|XPF|YER|ZAR|ZMW|ZWD$/.test(value);
}

export function isValidSemver(value) {
  if (!value) {
    return true;
  }

  return Semver.isValid(value);
}
export function isArray(value) {
  if (!value) {
    return false;
  }
  return Array.isArray(value);
}

import _ from 'lodash';

export default {
  methods: {
    isCompletedButInconsistent(statePart, bookingChannelPart) {
      const notAllowedMessageFilterer = _.map(
        statePart.details,
        detail => this.isInconsistent(detail),
      );
      const { length } = _.compact(notAllowedMessageFilterer);
      return length && bookingChannelPart.created;
    },
    isInconsistent(detail) {
      return detail.includes('is not allowed');
    },
    getIcon(detail) {
      return this.isInconsistent(detail) ? 'help_outline' : 'error_outline';
    },
    getColor(detail) {
      return this.isInconsistent(detail) ? 'warning' : 'error';
    },
  },
};

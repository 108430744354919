import {
  minLength, maxLength, required, email, decimal, numeric,
} from 'vuelidate/lib/validators';

import { isBoolean, isHexadecimalColor, isHumanReadableId } from '../../validation/validationMethods';

export default {
  customerData: {
    id: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      required,
    },
    name: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    humanReadableId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      isHumanReadableId,
    },
    currency: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    primaryColor: {
      isHexadecimalColor,
    },
    primaryDarkColor: {
      isHexadecimalColor,
    },
    secondaryColor: {
      isHexadecimalColor,
    },
    primaryFontColor: {
      isHexadecimalColor,
    },
    latitude: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    longitude: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    defaultCountry: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    defaultTipValue: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    defaultTipBase: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    preAuthAmount: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      numeric,
    },
    iosBundleId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    androidApplicationId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    currencySymbol: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    storeUrlIos: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    errorColor: {
      isHexadecimalColor,
    },
    logoFormat: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    communicationLanguage: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    iosProjectVersion: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    iosMarketingVersion: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    androidVersionCode: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    androidVersionName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    applePayEnabled: {
      isBoolean,
    },
    googlePayEnabled: {
      isBoolean,
    },
    percentageMaxAmount: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    currencyMaxAmount: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    cashEnabled: {
      isBoolean,
    },
    prebookingEnabled: {
      isBoolean,
    },
    businessLoginEnabled: {
      isBoolean,
    },
    personalLoginEnabled: {
      isBoolean,
    },
    firstInstallPromptEnabled: {
      isBoolean,
    },
    loginLogoBackgroundColor: {
      isHexadecimalColor,
    },
    googleMapsKey: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    teamId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    favicons: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    'ms-icons': {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    supportPhoneNumber: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    dispatchName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    androidAssets: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    iosAssets: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    figmaExample: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    stripeAdmin: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    callDriverDirectPhoneNumber: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    appIconBackgroundColor: {
      isHexadecimalColor,
    },
    appleAccountName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    googleAccountName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    businessAdminFullName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    businessAdminEmail: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    customerConsoleAdminFullName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    customerConsoleAdminEmail: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    companyId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    salesTaxInfo: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    paymentType: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    isGooglePayInProduction: {
      isBoolean,
    },
    loginActionBackgroundColor: {
      isHexadecimalColor,
    },
  },
  importData: {
    name: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    currency: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    latitude: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
      required,
    },
    longitude: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
      required,
    },
    defaultCountry: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    defaultTipValue: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      decimal,
    },
    defaultTipBase: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    iosBundleId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    androidApplicationId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    currencySymbol: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    storeUrlIos: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    communicationLanguage: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    teamId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    supportPhoneNumber: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    stripeAdmin: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    callDriverDirectPhoneNumber: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    appleAccountName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    googleAccountName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    businessAdminFullName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    businessAdminEmail: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    customerConsoleAdminFullName: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    customerConsoleAdminEmail: {
      minLength: minLength(1),
      maxLength: maxLength(255),
      email,
    },
    companyId: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    salesTaxInfo: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    paymentType: {
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    promoCodeEnabled: {
      isBoolean,
    },
  },
};

<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError && getErrors().length > 0" class="form-error-alert">
      The form is invalid. Please correct the errors.
      <ul>
        <li v-for="(error, index) in getErrors()" :key="index">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <v-row class="d-flex my-2 justify-space-between" >
      <v-card width="100%">
        <v-card-title>Android</v-card-title>
        <v-card-text>
          <v-radio-group v-model="firebaseAnalyticsConfig.android.configType">
            <v-radio
              label="Use default configuration file"
              value="default"
            ></v-radio>
            <v-radio
              label="Use custom configuration file"
              value="custom"
            ></v-radio>
          </v-radio-group>

          <div v-if="showCustomConfigFileUploadedMessageForAndroid">
            <v-alert
              dense
              text
              type="success"
            >
              A custom configuration file is already is use for this booking channel. If you want to replace it, please upload a new one.
            </v-alert>
          </div>
          <div v-if="firebaseAnalyticsConfig.android.configType === 'custom'">
            Upload a new configuration file
            <v-file-input
              v-model="firebaseAnalyticsConfig.android.newFile"
              outlined
            ></v-file-input>
          </div>
        </v-card-text>
      </v-card>

      <v-card width="100%" class="mt-4">
        <v-card-title>iOS</v-card-title>
        <v-card-text>
          <v-radio-group v-model="firebaseAnalyticsConfig.ios.configType">
            <v-radio
              label="Use default configuration file"
              value="default"
            ></v-radio>
            <v-radio
              label="Use custom configuration file"
              value="custom"
            ></v-radio>
          </v-radio-group>

          <div v-if="showCustomConfigFileUploadedMessageForIos">
            <v-alert
              dense
              text
              type="success"
            >
              A custom configuration file is already is use for this booking channel. If you want to replace it, please upload a new one.
            </v-alert>
          </div>
          <div v-if="firebaseAnalyticsConfig.ios.configType === 'custom'">
            Upload a new configuration file
            <v-file-input
              v-model="firebaseAnalyticsConfig.ios.newFile"
              outlined
            ></v-file-input>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="onSaveClick"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';
import FileUploadUtil from '@/util/FileUploadUtil';

const CONFIG_TYPE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

export default {
  name: 'FirebaseAnalyticsConfigForm',
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDisplayFormError: false,
      formDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('firebaseAnalyticsConfig'),
      firebaseAnalyticsConfig: {
        android: {
          configType: CONFIG_TYPE.DEFAULT,
          hasUploadedFile: false,
          newFile: null,
        },
        ios: {
          configType: CONFIG_TYPE.DEFAULT,
          hasUploadedFile: false,
          newFile: null,
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    hasErrors() {
      return !!this.getErrors().length;
    },
    showCustomConfigFileUploadedMessageForAndroid() {
      return this.firebaseAnalyticsConfig.android.configType === CONFIG_TYPE.CUSTOM
        && this.firebaseAnalyticsConfig.android.hasUploadedFile;
    },
    showCustomConfigFileUploadedMessageForIos() {
      return this.firebaseAnalyticsConfig.ios.configType === CONFIG_TYPE.CUSTOM
        && this.firebaseAnalyticsConfig.ios.hasUploadedFile;
    },
    bookingChannel() {
      return this.remoteState.bookingChannel;
    },
  },
  methods: {
    init() {
      const hasCustomUploadedFileAndroid = _.get(this.bookingChannel, 'firebaseAnalyticsConfig.android.isDefault', true) === false;
      this.firebaseAnalyticsConfig.android.configType = hasCustomUploadedFileAndroid ? CONFIG_TYPE.CUSTOM : CONFIG_TYPE.DEFAULT;
      this.firebaseAnalyticsConfig.android.hasUploadedFile = hasCustomUploadedFileAndroid;
      this.firebaseAnalyticsConfig.android.newFile = null;

      const hasCustomUploadedFileIos = _.get(this.bookingChannel, 'firebaseAnalyticsConfig.ios.isDefault', true) === false;
      this.firebaseAnalyticsConfig.ios.configType = hasCustomUploadedFileIos ? CONFIG_TYPE.CUSTOM : CONFIG_TYPE.DEFAULT;
      this.firebaseAnalyticsConfig.ios.hasUploadedFile = hasCustomUploadedFileIos;
      this.firebaseAnalyticsConfig.ios.newFile = null;
    },
    async onSaveClick() {
      this.isDisplayFormError = false;
      if (this.getErrors().length) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    getErrors() {
      return this.formDefinition.getErrors({ firebaseAnalyticsConfigState: this.firebaseAnalyticsConfig });
    },
    async save() {
      const firebaseAnalyticsConfigPayload = {};
      if (this.firebaseAnalyticsConfig.android.configType === CONFIG_TYPE.CUSTOM) {
        if (this.firebaseAnalyticsConfig.android.newFile) {
          firebaseAnalyticsConfigPayload.android = {
            isDefault: false,
            configFileContentBase64: await FileUploadUtil.getBase64EncodedStringFromFile(this.firebaseAnalyticsConfig.android.newFile),
          };
        }
      } else {
        firebaseAnalyticsConfigPayload.android = {
          isDefault: true,
        };
      }

      if (this.firebaseAnalyticsConfig.ios.configType === CONFIG_TYPE.CUSTOM) {
        if (this.firebaseAnalyticsConfig.ios.newFile) {
          firebaseAnalyticsConfigPayload.ios = {
            isDefault: false,
            configFileContentBase64: await FileUploadUtil.getBase64EncodedStringFromFile(this.firebaseAnalyticsConfig.ios.newFile),
          };
        }
      } else {
        firebaseAnalyticsConfigPayload.ios = {
          isDefault: true,
        };
      }
      await this.$store.dispatch('bookingChannelView/onboardFirebaseAnalyticsConfig', {
        bookingChannelId: this.bookingChannel.id,
        firebaseAnalyticsConfigPayload,
      });

      this.init();
    },
  },
};
</script>

<style scoped>

</style>

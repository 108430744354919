var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('payment-connector-dialog',{attrs:{"fleetId":_vm.selectedFleetId,"paymentConnectors":_vm.selectedPaymentConnectors,"showDialog":_vm.isShowingPaymentConnectorDialog},on:{"close":_vm.closePaymentConnectorDialog}}),_c('v-data-table',{staticClass:"ic-table elevation-0 ic-table--heavy-headers",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"loading":_vm.isLoading,"hide-default-footer":"","multi-sort":false,"page":_vm.page,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"footer-props":{showFirstLastPage: true}},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"white header-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","dense":"","justify":"center"}},[_c('v-col',{staticClass:"v-toolbar__title",attrs:{"cols":"12","lg":"8"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Fleets ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"header-list-button",staticStyle:{"margin-right":"1rem"},attrs:{"color":"success"},on:{"click":_vm.createClick}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Create")]),_c('v-icon',{staticClass:"hidden-md-and-up"},[_vm._v("$icons_system_plus")])],1),_c('v-text-field',{attrs:{"append-icon":"$icons_system_search","label":'Search',"single-line":"","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.dynamicSearch),callback:function ($$v) {_vm.dynamicSearch=$$v},expression:"dynamicSearch"}})],1)])],1)],1),_c('div',{staticClass:"header-separator"})]},proxy:true},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-black black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('string-copy-tooltip',{attrs:{"truncate":"","text":item.id}})]}},{key:"item.dispatchFleetUuid",fn:function(ref){
var item = ref.item;
return [(item.dispatchFleetUuid)?_c('string-copy-tooltip',{attrs:{"truncate":"","text":item.dispatchFleetUuid}}):_vm._e()]}},{key:"item.dispatchFleetIx",fn:function(ref){
var item = ref.item;
return [(item.dispatchFleetIx)?_c('string-copy-tooltip',{attrs:{"truncate":"","text":item.dispatchFleetIx}}):_vm._e()]}},{key:"item.paymentAccountOnboarding",fn:function(ref){
var item = ref.item;
return [(!_vm.hasOnboardingStatus(item.connectors))?_c('span',[_vm._v("N/A")]):_vm._e(),(_vm.hasOnboardingStatus(item.connectors) && !_vm.hasPendingPaymentOnboardings(item.connectors))?_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.evaluatePaymentAccounts(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-check")]),_vm._v(_vm._s(_vm.$t('paymentConnector.evaluate'))+" ")],1):_vm._e(),(_vm.hasOnboardingStatus(item.connectors) && _vm.hasPendingPaymentOnboardings(item.connectors))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.evaluatePaymentAccounts(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-clock")]),_vm._v(_vm._s(_vm.$t('paymentConnector.evaluate'))+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.nbPendingMigrations(item.connectors))+" "+_vm._s(_vm.$t('paymentConnector.pending'))+" ")]):_vm._e()]}},{key:"item.webhooks",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.webhooksClick(item)}}},[_vm._v(" Webhooks ")])]}},{key:"item.actionViewFleet",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewFleetClick(item)}}},[_vm._v(" View ")])]}},{key:"item.actionViewFleetJson",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewFleetJsonClick(item)}}},[_vm._v(" JSON ")])]}}])}),_c('div',{staticClass:"text-center pt-8"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
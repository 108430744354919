<template>
  <v-container>
    <v-row
      justify="center">
      <v-col class="col-4">
        <autocomplete
          ref="autocomplete-humanReadableId"
          :label="$t('onboarding.bookingChannel')"
          :loading='listBookingChannelInProgress'
          :items='bookingChannelList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('builds.autocomplete')"
          @searchAutocomplete='listBookingChannels'
        ></autocomplete>
      </v-col>
      <v-col  class="col-2">
        <v-btn
          class="header-button"
          block
          large
          :disabled="isLoading || !humanReadableId"
          :loading="configurationStateCheckInProgress"
          @click="checkConfiguration"
          color="primary">
          {{ $t(`onboarding.check`) }}
        </v-btn>
      </v-col>
        <v-col  class="col-2">
          <v-btn
            class="header-button"
            block
            large
            :disabled="isLoading || !humanReadableId"
            :loading="configurationStateOnboardingInProgress"
            @click="onboardConfiguration"
            color="primary">
            {{ $t(`onboarding.onboard`) }}
          </v-btn>
        </v-col>
        <v-col class="col-4">
          <v-snackbar
            bottom
            right
            color="green lighten-4"
            :timeout="0"
            v-model="openInProgressState"
          >
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
            <span class="green--text in-progress">
              {{ $t('onboarding.inProgress') }}
            </span>
          </v-snackbar>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col
        flat
        class="col-8">
        <loader color="primary" :is-loading="isLoading">
          <component
            @set-next="setNext"
            @set-previous="setPrevious"
            :humanReadableId="humanReadableId"
            :bookingChannelId="bookingChannelId"
            :is="selectedStep.componentName"
            :statePart="selectedStatePart"
            :type="selectedStep.type"
            :step="selectedStep">
          </component>
        </loader>
      </v-col>
      <v-col
        flat
        class="col-4">
          <onboarding-timeline
            :disable="!configurationState"
            @step-selection="setSelection"
            :steps="onboardingSteps"
            :configurationState="configurationState"
          ></onboarding-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingTimeline from './OnboardingTimeline.vue';
import BookingChannelStep from './steps/BookingChannelStep.vue';
import OidcClientsStep from './steps/OidcClientsStep.vue';
import FleetAssociationStep from './steps/FleetAssociationStep.vue';
import ConnectorStep from './steps/ConnectorStep.vue';
import NotificationSetupStep from './steps/notification/NotificationSetupStep.vue';
import BusinessSolutionStep from './steps/BusinessSolutionStep.vue';
import ConsoleAdminStep from './steps/ConsoleAdminStep.vue';
import FeaturesStep from './steps/FeaturesStep.vue';
import AppPreviewStep from './steps/appPreview/AppPreviewStep.vue';
import AppSigningStep from './steps/appSigning/AppSigningStep.vue';
import WaitingTimesStep from './steps/WaitingTimesStep.vue';
import PaymentMethodsStep from './steps/PaymentMethodsStep.vue';

export default {
  components: {
    OnboardingTimeline,
    BookingChannelStep,
    OidcClientsStep,
    FleetAssociationStep,
    ConnectorStep,
    NotificationSetupStep,
    BusinessSolutionStep,
    ConsoleAdminStep,
    FeaturesStep,
    AppPreviewStep,
    AppSigningStep,
    WaitingTimesStep,
    PaymentMethodsStep,
  },
  name: 'onboarding',
  created() {
    this.$store.dispatch('onboarding/resetState');
  },
  data() {
    return {
      searchHumanReadableId: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingChannelList: 'bookingChannels/list',
      listBookingChannelInProgress: 'bookingChannels/listBookingChannelInProgress',
      configurationState: 'onboarding/configurationState',
      configurationStateCheckInProgress: 'onboarding/configurationStateCheckInProgress',
      configurationStateOnboardingInProgress: 'onboarding/configurationStateOnboardingInProgress',
      onboardingSteps: 'onboardingSteps/onboardingSteps',
      silentCheckInProgress: 'onboarding/silentCheckInProgress',
    }),
    isLoading() {
      return !!(this.configurationStateCheckInProgress
        || this.configurationStateOnboardingInProgress);
    },
    selectedStatePart() {
      if (this.configurationState) {
        return this.configurationState[this.selectedStep.stateKey];
      }
      return null;
    },
    selectedStep() {
      return this.onboardingSteps.find(step => step.isSelected);
    },
    bookingChannelId() {
      if (this.configurationState) {
        return this.configurationState.bookingChannel.id;
      }
      return null;
    },
    humanReadableId() {
      if (this.searchHumanReadableId) {
        return this.searchHumanReadableId;
      }
      return null;
    },
    openInProgressState() {
      return !!this.silentCheckInProgress;
    },
  },
  methods: {
    listBookingChannels(dynamicSearch) {
      this.searchHumanReadableId = dynamicSearch;
      this.$store.dispatch('bookingChannels/list', dynamicSearch);
    },
    checkConfiguration() {
      this.$store.dispatch('onboarding/check', { humanReadableId: this.humanReadableId });
    },
    onboardConfiguration() {
      this.$store.dispatch('onboarding/onboard', { humanReadableId: this.humanReadableId });
    },
    setSelection(selectedStep) {
      this.$store.dispatch('onboardingSteps/setSelection', { selectedStep });
    },
    setNext() {
      this.$store.dispatch('onboardingSteps/setNext');
    },
    setPrevious() {
      this.$store.dispatch('onboardingSteps/setPrevious');
    },
  },
};
</script>

<style scoped lang="scss">
  .header-button {
    min-height: 55px;
  }
  .stepper {
    min-width: 100% !important;
  }
  .in-progress {
    padding-left: 20px;
  }
</style>

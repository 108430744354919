<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>
    <v-row>
      <v-col cols="12">
        <validated-component
          v-model="firebaseWebbookerAnalytics.apiKey"
          :validator="$v.firebaseWebbookerAnalytics.apiKey">
          <v-text-field
            v-model="firebaseWebbookerAnalytics.apiKey"
            :label="$t('firebaseWebbookerAnalyticsForm.apiKey')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="firebaseWebbookerAnalytics.projectId"
          :validator="$v.firebaseWebbookerAnalytics.projectId">
          <v-text-field
            v-model="firebaseWebbookerAnalytics.projectId"
            :label="$t('firebaseWebbookerAnalyticsForm.projectId')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="firebaseWebbookerAnalytics.appId"
          :validator="$v.firebaseWebbookerAnalytics.appId">
          <v-text-field
            v-model="firebaseWebbookerAnalytics.appId"
            :label="$t('firebaseWebbookerAnalyticsForm.appId')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
        <validated-component
          v-model="firebaseWebbookerAnalytics.messagingSenderId"
          :validator="$v.firebaseWebbookerAnalytics.messagingSenderId">
          <v-text-field
            v-model="firebaseWebbookerAnalytics.messagingSenderId"
            :label="$t('firebaseWebbookerAnalyticsForm.messagingSenderId')"
            hide-details
            outlined
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn class="mt-2" color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';

export default {
  name: 'firebaseWebbookerAnalyticsForm',
  components: {
  },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.$v.$touch();
  },
  mounted() {
    if (this.bookingChannel.firebaseWebbookerAnalytics) {
      this.firebaseWebbookerAnalytics = this.bookingChannel.firebaseWebbookerAnalytics;
    }
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      formDefinition: bookingChannelFormResolver.getFormDefinitionFromCode('firebaseWebbookerAnalytics'),
      firebaseWebbookerAnalytics: {
        apiKey: null,
        projectId: null,
        appId: null,
        messagingSenderId: null,
      },
      isDisplayFormError: false,
    };
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/onboardFirebaseWebbookerAnalytics', {
        bookingChannelId: this.bookingChannel.id,
        firebaseWebbookerAnalyticsPayload: this.firebaseWebbookerAnalytics,
      });
    },
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty && !this.$v.$anyError;
    },
  },
  validations() {
    return {
      firebaseWebbookerAnalytics: {
        apiKey: {
          minLength: minLength(1),
          maxLength: maxLength(255),
          required,
        },
        projectId: {
          minLength: minLength(1),
          maxLength: maxLength(255),
          required,
        },
        appId: {
          minLength: minLength(1),
          maxLength: maxLength(255),
          required,
        },
        messagingSenderId: {
          minLength: minLength(1),
          maxLength: maxLength(255),
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
</style>

<template>
  <v-row>
    <h2>{{ $t('geoConnectorForm.backendConnector') }}
      <v-btn
        color="primary"
        @click="addConnector"
        :disabled="$v.updateData.$invalid"
      >
        {{ $t('geoConnectorForm.addConnector') }}
      </v-btn>
    </h2>
    <v-col v-for="(connector, index) in updateData.driverConfigurations" :key="`geoConnector${index}`" cols="12">
      <p>{{ $t('geoConnectorForm.connector', { index: index+1 }) }} <v-icon v-if="updateData.driverConfigurations.length > 1" color="info" class="delete-driver" @click="removeDriver(index)">delete</v-icon></p>
      <validated-component
          v-model="connector.driverName"
          :validator="$v.updateData.driverConfigurations.$each.$iter[index].drivenName">
          <v-select
            v-model="connector.driverName"
            :label="$t('geoConnectorForm.name')"
            outlined
            :items="geoNames"
            @input="onInput"
            hide-details
          >
          </v-select>
        </validated-component>
        <validated-component
          v-model="connector.configuration.key"
          :validator="$v.updateData.driverConfigurations.$each.$iter[index].configuration.key">
          <v-text-field
            v-model="connector.configuration.key"
            :label="$t('geoConnectorForm.key')"
            hide-details
            @input="onInput"
            outlined
          ></v-text-field>
        </validated-component>
    </v-col>
    <v-divider></v-divider>
    <h2>{{ $t('geoConnectorForm.frontendConnector') }}</h2>
    <v-col cols="12">
      <validated-component
        v-model="updateData.name"
        :validator="$v.driverName">
        <v-select
          v-model="updateData.name"
          :label="$t('geoConnectorForm.name')"
          outlined
          :items="uiGeoNames"
          @input="onInput"
          hide-details
        >
        </v-select>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.accessToken"
        :validator="$v.updateData.uiConfiguration.accessToken">
          <v-text-field
            v-model="updateData.uiConfiguration.accessToken"
            :label="$t('geoConnectorForm.webKey')"
            @input="onInput"
            hide-details
            outlined
          ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.mapStyle"
        :validator="$v.updateData.uiConfiguration.mapStyle">
          <v-text-field
            v-model="updateData.uiConfiguration.mapStyle"
            :label="$t('geoConnectorForm.mapStyle')"
            @input="onInput"
            hide-details
            outlined
          ></v-text-field>
      </validated-component>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from 'lodash';
import geoConnectorValidation from '../../../validationDatas/geoConnectorValidation';

export default {
  name: 'geo-connector',
  validations() {
    return geoConnectorValidation;
  },
  data() {
    return {
      geoNames: ['googleMaps', 'ic2ICabbi'],
      uiGeoNames: ['googleMaps'],
    };
  },
  props: ['updateData'],
  methods: {
    onInput() {
      if (this.$v.$updateData.$invalid) {
        this.$emit('onError');
      } else {
        this.$emit('onInput');
        this.$emit('updateConnector', this.updateData);
      }
    },
    addConnector() {
      const clone = cloneDeep(this.updateData.driverConfigurations);
      clone.push(this.getEmptyConnector());
      this.updateData.driverConfigurations = clone;
      this.$forceUpdate();
    },
    getEmptyConnector() {
      return {
        driverName: '',
        configuration: {
          key: '',
        },
      };
    },
    removeDriver(index) {
      const clone = cloneDeep(this.updateData.driverConfigurations);
      clone.splice(index, 1);
      this.updateData.driverConfigurations = clone;
    },
  },
  created() {
    if (!this.updateData.uiConfiguration.mapStyle) {
      this.updateData.uiConfiguration.mapStyle = 'NA';
    }

    if (!this.updateData.driverConfigurations) {
      if (this.updateData.configuration) {
        this.updateData.driverConfigurations = [{
          driverName: this.updateData.configuration.name || this.updateData.name || '',
          configuration: this.updateData.configuration || { key: '' },
        }];
      }
      this.updateData = {
        name: this.updateData.name || '',
        uiConfiguration: this.updateData.uiConfiguration || {},
        driverConfigurations: this.updateData.driverConfigurations,
      };
    }

    this.$emit('updateConnector', this.updateData);
  },
};
</script>

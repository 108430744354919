// eslint-disable-next-line import/prefer-default-export
export const PaymentMethod = {
  CASH: 'cash',
  CREDIT_CARD: 'creditCard',
  APPLE_PAY: 'applePay',
  APPLE_PAY_GUEST: 'applePayGuest',
  GOOGLE_PAY: 'googlePayGuest',
  GOOGLE_PAY_GUEST: 'googlePayGuest',
  BUSINESS_SOLUTION: 'businessSolution',
  IC2_ACCOUNT: 'IC2Account',
  BUSINESS_ACCOUNT: 'businessAccount',
};

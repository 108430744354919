<template>
  <div>
    <template v-if="uiComponentName">
      <component
        v-bind:is="uiComponentName"
        v-bind:remoteState="remoteState"
      ></component>
    </template>
    <template v-else>
      No corresponding form component found
    </template>
  </div>
</template>

<script>
import AppPreviewForm from '@/components/bookingChannel/forms/AppPreviewForm.vue';
import AppBuildSettingsForm from '@/components/bookingChannel/forms/AppBuildSettingsForm.vue';
import AppSigningForm from '@/components/bookingChannel/forms/AppSigningForm.vue';
import BusinessSolutionForm from '@/components/bookingChannel/forms/BusinessSolutionForm.vue';
import ThemeForm from '@/components/bookingChannel/forms/ThemeForm.vue';
import CustomerConsoleForm from '@/components/bookingChannel/forms/CustomerConsoleForm.vue';
import FeaturesForm from '@/components/bookingChannel/forms/FeaturesForm.vue';
import AddressSearchConfigurationForm from '@/components/bookingChannel/forms/AddressSearchConfigurationForm.vue';
import FleetsForm from '@/components/bookingChannel/forms/FleetsForm.vue';
import GeoConnectorForm from '@/components/bookingChannel/forms/GeoConnectorForm.vue';
import MarketingConsoleForm from '@/components/bookingChannel/forms/MarketingConsoleForm.vue';
import NotificationForm from '@/components/bookingChannel/forms/NotificationForm.vue';
import OidcClientsForm from '@/components/bookingChannel/forms/OidcClientsForm.vue';
import PaymentConnectorForm from '@/components/bookingChannel/forms/PaymentConnectorForm.vue';
import UserConnectorForm from '@/components/bookingChannel/forms/UserConnectorForm.vue';
import WaitingTimesForm from '@/components/bookingChannel/forms/WaitingTimesForm.vue';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';
import ProfileSettingsForm from '@/components/bookingChannel/forms/ProfileSettingsForm.vue';
import FleetNetworksForm from '@/components/bookingChannel/forms/FleetNetworksForm.vue';
import AlertsConfigurationForm from '@/components/bookingChannel/forms/AlertsConfigurationForm.vue';
import FirebaseAnalyticsConfigForm from '@/components/bookingChannel/forms/FirebaseAnalyticsConfigForm.vue';
import FirebaseWebbookerAnalyticsForm from '@/components/bookingChannel/forms/FirebaseWebbookerAnalyticsForm.vue';

export default {
  name: 'FormResolver',
  components: {
    ProfileSettingsForm,
    AppPreviewForm,
    AppBuildSettingsForm,
    AppSigningForm,
    BusinessSolutionForm,
    ThemeForm,
    CustomerConsoleForm,
    FeaturesForm,
    AddressSearchConfigurationForm,
    FleetsForm,
    FleetNetworksForm,
    GeoConnectorForm,
    MarketingConsoleForm,
    NotificationForm,
    OidcClientsForm,
    PaymentConnectorForm,
    UserConnectorForm,
    WaitingTimesForm,
    AlertsConfigurationForm,
    FirebaseAnalyticsConfigForm,
    FirebaseWebbookerAnalyticsForm,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    remoteState: {
      type: Object,
      required: true,
    },
  },
  computed: {
    uiComponentName() {
      return bookingChannelFormResolver.getFormDefinitionFromCode(this.code).component;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <div class="color-container">
            <span class="color-bold">{{ $t('appPreview.primary') }}</span>
            <span class="color-value">{{ primary }}</span>
            <v-swatches
              v-model="primary"
              show-fallback
              fallback-input-type="color"
              popover-x="left"
            ></v-swatches>
            <span class="undo" @click="() => resetColor('primary')"><v-icon>clear</v-icon></span>
          </div>
          <div class="color-container">
            <span class="color-bold">{{ $t('appPreview.primaryFont') }}</span>
            <span class="color-value">{{ primaryFont }}</span>
            <v-swatches
              v-model="primaryFont"
              show-fallback
              fallback-input-type="color"
              popover-x="left"
            ></v-swatches>
            <span class="undo" @click="() => resetColor('primary')"><v-icon>clear</v-icon></span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" align="center">
        <app-svg-welcome
          :primary="primary"
          :primaryFont="primaryFont"
        ></app-svg-welcome>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches';
import AppSvgWelcome from '@/components/onboarding/steps/appPreview/AppSVGWelcome.vue';

export default {
  name: 'AppPreviewForm',
  components: {
    AppSvgWelcome,
    // eslint-disable-next-line vue/no-unused-components
    VSwatches,
  },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    primary: '',
    primaryFont: '',
  }),
  computed: {
    colorScheme() {
      return this.remoteState.bookingChannel.colorScheme;
    },
  },
  beforeMount() {
    this.primary = `${this.colorScheme.primary}`;
    this.primaryFont = `${this.colorScheme.primaryFont}`;
  },
  methods: {
    resetColor(color) {
      this[`${color}`] = `${this.colorScheme[`${color}`]}`;
    },
  },
};
</script>

<style scoped lang="scss">
  .color-bold {
    font-weight: 700;
  }

  .color-value {

  }

  .color-container {
    margin-right: 8px;
    margin-bottom: 15px;
  }

  .undo {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
  }

  .vue-swatches {
    vertical-align: middle;
    margin-left: 10px;
  }
</style>

<style lang="scss">
  .vue-swatches__trigger {
    border: 1px solid #222 !important;
  }
</style>

<template>
  <v-col cols='12'>
    <v-row>
      <v-col cols="12">
        <h4>{{ $t('onboarding.appSigningUpload') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <validated-component
          name="file"
          v-model="keystore.file"
          :validator="$v.keystore.file">
          <v-file-input
            v-model="keystore.file"
            :label="$t(`onboarding.appSigningKeystore`)"
            :placeholder="$t(`onboarding.appSigningKeystore`)"
            outlined
            hide-details
            no-filter
            show-size>
          </v-file-input>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <validated-component
          name="alias"
          v-model="keystore.alias"
          :validator="$v.keystore.alias">
          <v-text-field
            v-model="keystore.alias"
            :label="$t(`onboarding.appSigningKeystoreAlias`)"
            :placeholder="$t(`onboarding.appSigningKeystoreAlias`)"
            outlined
            hide-details
            no-filter>
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <validated-component
          name="storePassword"
          v-model="keystore.storePassword"
          :validator="$v.keystore.storePassword">
          <v-text-field
            v-model="keystore.storePassword"
            :label="$t(`onboarding.appSigningKeystoreStorePassword`)"
            :placeholder="$t(`onboarding.appSigningKeystoreStorePassword`)"
            outlined
            hide-details
            no-filter>
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <validated-component
          name="keyPassword"
          v-model="keystore.keyPassword"
          :validator="$v.keystore.keyPassword">
          <v-text-field
            v-model="keystore.keyPassword"
            :label="$t(`onboarding.appSigningKeystoreKeyPassword`)"
            :placeholder="$t(`onboarding.appSigningKeystoreKeyPassword`)"
            outlined
            hide-details
            no-filter>
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="4">
        <v-btn
          block
          large
          :disabled="$v.$invalid"
          class="header-button"
          color="primary"
          @click="uploadAndroidKeystore">
          {{ $t(`upload`) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import appSigningUploadValidation from '../../../validationDatas/appSigningUploadValidation';
import { encodeKeystore } from '../../../../services/appSigningService';

export default {
  name: 'app-signing-upload-form',
  validations() {
    return appSigningUploadValidation;
  },
  data() {
    return {
      keystore: {
        file: null,
        alias: '',
        storePassword: '',
        keyPassword: '',
      },
    };
  },
  methods: {
    async uploadAndroidKeystore() {
      const encodedKeystore = await encodeKeystore(this.keystore.file);
      this.$emit('upload-android-keystore', {
        file: encodedKeystore,
        alias: this.keystore.alias,
        storePassword: this.keystore.storePassword,
        keyPassword: this.keystore.keyPassword,
      });
    },
  },
};
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"ic-table elevation-1 ic-table--heavy-headers",attrs:{"headers":_vm.headers,"items":_vm.items,"multi-sort":false,"items-per-page":10,"footer-props":{showFirstLastPage: true},"sort-by":"startDate","sort-desc":true,"show-expand":"","single-expand":""},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"white header-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","dense":"","justify":"space-between"}},[_c('v-col',{staticClass:"v-toolbar__title",attrs:{"cols":"12","lg":"8"}},[_c('p',{staticClass:"font-weight-bold"})]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"header-list-button",staticStyle:{"margin-right":"1rem"},attrs:{"color":"success"},on:{"click":_vm.createClick}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Add Financial Setting")]),_c('v-icon',{staticClass:"hidden-md-and-up"},[_vm._v("$icons_system_plus")])],1)],1)])],1)],1),_c('div',{staticClass:"header-separator"})]},proxy:true},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.endDate)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background":"#EFEFEF"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-8"},[_c('v-data-table',{attrs:{"headers":_vm.bookingFeesHeaders,"items":item.fees.booking,"hide-default-footer":"","multi-sort":false,"must-sort":true,"footer-props":{ showFirstLastPage: true }}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
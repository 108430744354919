import { isNil, cloneDeep } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const BookingChannelUtil = {
  /**
   * Clean the booking channel model so that it
   * is ready for API submission.
   * @param bookingChannel
   * @returns {*}
   */
  cleanBookingChannel(bookingChannel) {
    const payload = cloneDeep(bookingChannel);

    // Ensure numerical values are not strings
    payload.location.latitude = !isNil(bookingChannel.location.latitude) ? parseFloat(bookingChannel.location.latitude) : null;
    payload.location.longitude = !isNil(bookingChannel.location.longitude) ? parseFloat(bookingChannel.location.longitude) : null;
    payload.defaultTip.defaultTipValue = !isNil(bookingChannel.defaultTip.defaultTipValue) ? parseFloat(bookingChannel.defaultTip.defaultTipValue) : null;
    payload.preAuthorization.amount = !isNil(bookingChannel.preAuthorization.amount) ? parseFloat(bookingChannel.preAuthorization.amount) : null;

    return payload;
  },
};

import {
  minLength, maxLength, required,
} from 'vuelidate/lib/validators';


export default {
  androidApp: {
    credentials: {
      minLength: minLength(1),
      maxLength: maxLength(500),
      required,
    },
  },
};

<template>
    <v-list-item link to="/logout">
      <v-list-item-content>
        <v-list-item-title>{{ $t('navigation.menu.logout')}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
  name: 'Logout',
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-app-bar clipped-left app height="50px" class="header-bar" style="max-height:50px; z-index: 99999">
      <v-icon @click.stop="drawer = !drawer" v-if="isMobile">mdi-menu</v-icon>
      <v-spacer v-if="isMobile"></v-spacer>
      <img src="/icabbi-logo.png" class="nav-logo" />
      <span class="font-weight-thin text-caption">
        V{{ appVersion }}
      </span>
      <v-spacer v-if="isMobile"></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      clipped
      app
      :permanent="!isMobile"
      width="210px"
      v-model="drawer"
      style="background-color: #f9f9f9;"
    >
      <v-list nav dense>
        <v-list-item link to="/booking-channels">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>mdi-puzzle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.bookingChannels") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/onboard" v-if="false">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>thumb_up_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.onboard") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/fleets">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>mdi-office-building-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.fleets") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/networks">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>link</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.networks") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/builds">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>build</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.build") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/customer-data" v-if="false">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.customerData") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/jot-forms">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.jotForms") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/financial-settings">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.financialSettings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/trackmytaxi">
          <v-list-item-icon class="navigation-link-icon">
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navigation.menu.trackmytaxi") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <user-profile-navigation :displayName="displayName" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as packageJson from '../../package.json';
import { isMobile } from '../mixins/responsive.mixins';
import UserProfileNavigation from './UserProfileNavigation.vue';

export default {
  mixins: [isMobile],
  components: { UserProfileNavigation },
  props: {
    displayName: {
      type: String,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    appVersion() {
      return packageJson.version;
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-list-item__title {
    text-align: left;
  }
  .navigation-drawer {
    .v-list {
      padding-left: 0px;
      padding-right: 0px;
      color: black;

    }

    .v-list-item__icon {
      padding-left: 7px;
      svg {
        color: black !important;
      }
    }
    .v-list-item::before {
      border-radius: 0;
    }
    .v-list-item:first-child {
      margin-top: 25px;
    }
  }

  .header-bar {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }
</style>

<style lang="scss">
  .nav-logo {
    height: 40px;
  }
  .navigation-drawer .v-navigation-drawer__border {
    display: none;
  }
  .navigation-link-icon {
    margin-right: 15px !important;
  }
</style>

<template>
<v-app :style="appStyle">
  <v-content>
    <v-container fluid fill-height class="ma-0 pa-0">
      <v-layout row wrap align-center>
        <v-flex xs12 sm6 md4 offset-sm3 offset-md4>
          <v-card color="black darken-2" class="white--text error-card">
            <v-img
              src="/img/icabbi-logo.png"
              contain
              aspect-ratio="1.778"
              class="image-logo"
            ></v-img>
            <v-card-title primary-title class="error-title">
              <div>
                <div class="headline">{{ title }}</div>
                <span>{{ subTitle }}</span>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</v-app>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    errorPage: String,
  },
  computed: {
    appStyle() {
      return `background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/img/${this.errorPage}.jpg'); background-size: cover;`; // eslint-disable-line max-len
    },
  },
};
</script>

<style lang="scss" scoped>

.error-card {
  max-height: 300px;
  padding-bottom: 5px;

  .error-title {
    padding-top: 0px;

    span {
      margin-top: 20px;
      display: block;
    }
  }

  .image-logo {
    max-width: 75px;
    margin: 0 16px;
    padding-top: 24px;
  }
}
</style>



export default {
  data() {
    return {
      buildStates: {
        running: {
          color: 'blue',
          icon: 'autorenew',
        },
        pending: {
          color: 'orange',
          icon: 'hourglass_empty',
        },
        success: {
          color: 'green',
          icon: 'check_circle_outline',
        },
        failed: {
          color: 'red',
          icon: 'highlight_off',
        },
        canceled: {
          color: 'primary',
          icon: 'help_outline',
        },
        skipped: {
          color: 'default',
          icon: 'not_interested',
        },
        created: {
          color: 'default',
          icon: 'not_interested',
        },
        manual: {
          color: 'primary',
          icon: 'pan_tool',
        },
      },
    };
  },
  methods: {
    async load() {
      this.$emit('loadData');
      if (this.selectedBuild) {
        this.loadBuildDetails(this.selectedBuild);
      }
    },
    getBuildColor(status) {
      const state = this.buildStates[status];
      return state ? state.color : 'primary';
    },
    getBuildIcon(status) {
      const state = this.buildStates[status];
      return state ? state.icon : 'help_outline';
    },
    loadBuildDetails(item) {
      this.$emit('loadBuildDetails', item);
    },
  },
};

<template>
  <div>
    <v-row v-if="doesBookingChannelHaveFleets">
      <v-col cols='12'>
        <v-row>
          <v-list
            align-start>
            <v-list-item
              align-start
            >
              <v-list-item-icon>
                <v-icon :color="getColor(consoleCheckState.completed)">
                  {{ getIcon(consoleCheckState.completed) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  align="start">
                  Customer Console
                </v-list-item-title>
                <v-list-item-subtitle
                  align="start"
                  v-if="!consoleCheckState.completed">
                  {{ $t(`onboarding.customerConsole.noUser`) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols='6'>
            <validated-component
              name="givenName"
              v-model="givenName"
              :validator="$v.givenName">
              <v-text-field
                v-model="givenName"
                :label="$t(`onboarding.givenName`)"
                :placeholder="$t('onboarding.givenName')"
                outlined
                hide-details
                no-filter>
              </v-text-field>
            </validated-component>
          </v-col>
          <v-col cols='6'>
            <validated-component
              name="familyName"
              v-model="familyName"
              :validator="$v.familyName">
              <v-text-field
                v-model="familyName"
                :label="$t(`onboarding.familyName`)"
                :placeholder="$t('onboarding.familyName')"
                outlined
                hide-details
                no-filter>
              </v-text-field>
            </validated-component>
          </v-col>
          <v-col cols='6'>
            <validated-component
              name="email"
              v-model="email"
              :validator="$v.email">
              <v-text-field
                v-model="email"
                :label="$t(`onboarding.email`)"
                :placeholder="$t('onboarding.email')"
                outlined
                hide-details
                no-filter>
              </v-text-field>
            </validated-component>
          </v-col>
          <v-col cols='6'>
            <validated-component
              name="communicationLanguage"
              v-model="communicationLanguage"
              :validator="$v.communicationLanguage">
              <v-select
                v-model="communicationLanguage"
                :label="$t(`onboarding.communicationLanguage`)"
                :placeholder="$t('onboarding.communicationLanguage')"
                outlined
                :items="communicationLanguages"
                hide-details
                no-filter>
              </v-select>
            </validated-component>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="4">
            <v-btn
              block
              large
              :disabled="$v.$invalid"
              class="header-button"
              color="primary"
              @click="createUser">
              {{ $t(`save`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          A fleet must be associated with the booking channel before console users can be created.
          Refer to the Fleet Associations section.
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  minLength, maxLength, required, email,
} from 'vuelidate/lib/validators';
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';

export default {
  name: 'ConsoleUserForm',
  mixins: [checkStateDisplayMethods],
  props: {
    consoleType: {
      required: true,
      type: String,
    },
    remoteState: {
      type: Object,
      required: true,
    },
    allowNoFleetBookingChannel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      communicationLanguages: [
        'fr',
        'en',
        'fi',
        'es',
        'sv',
      ],
      givenName: '',
      familyName: '',
      email: '',
      communicationLanguage: '',
    };
  },
  computed: {
    consoleCheckState() {
      return this.remoteState.checkState[this.consoleType];
    },
    doesBookingChannelHaveFleets() {
      const { fleets } = this.remoteState.bookingChannel;
      return (Array.isArray(fleets) && fleets.length) || this.allowNoFleetBookingChannel;
    },
  },
  methods: {
    async createUser() {
      this.$emit('createUser', {
        communicationLanguages: this.communicationLanguages,
        givenName: this.givenName,
        familyName: this.familyName,
        email: this.email,
        communicationLanguage: this.communicationLanguage,
      });
    },
  },
  validations() {
    return {
      email: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        email,
        required,
      },
      givenName: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      familyName: {
        minLength: minLength(1),
        maxLength: maxLength(255),
        required,
      },
      communicationLanguage: {
        required,
      },
    };
  },
};
</script>

<style scoped>

</style>

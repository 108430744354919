<template>
  <v-row>
    <v-col cols="12">
      <validated-component
        name="name"
        v-model="updateData.name"
        :validator="$v.updateData.name">
        <v-select
          v-model="updateData.name"
          :label="$t('userConnectorForm.name')"
          @input="onInput"
          outlined
          hide-details
          :items="userNames"
          no-filter>
        </v-select>
      </validated-component>
      <validated-component
        v-model="updateData.configuration.serverKey"
        :validator="$v.updateData.configuration.serverKey">
        <v-text-field
          v-model="updateData.configuration.serverKey"
          :label="$t('userConnectorForm.serverKey')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.apiKey"
        :validator="$v.updateData.uiConfiguration.apiKey">
        <v-text-field
          v-model="updateData.uiConfiguration.apiKey"
          :label="$t('userConnectorForm.apiKey')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.projectId"
        :validator="$v.updateData.uiConfiguration.projectId">
        <v-text-field
          v-model="updateData.uiConfiguration.projectId"
          :label="$t('userConnectorForm.projectId')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.authDomain"
        :validator="$v.updateData.uiConfiguration.authDomain">
        <v-text-field
          v-model="updateData.uiConfiguration.authDomain"
          :label="$t('userConnectorForm.authDomain')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.storageBucket"
        :validator="$v.updateData.uiConfiguration.storageBucket">
        <v-text-field
          v-model="updateData.uiConfiguration.storageBucket"
          :label="$t('userConnectorForm.storageBucket')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <validated-component
        v-model="updateData.uiConfiguration.messagingSenderId"
        :validator="$v.updateData.uiConfiguration.messagingSenderId">
        <v-text-field
          v-model="updateData.uiConfiguration.messagingSenderId"
          :label="$t('userConnectorForm.messagingSenderId')"
          @input="onInput"
          hide-details
          outlined
        ></v-text-field>
      </validated-component>
      <v-file-input
        v-model="updateData.configuration.serviceAccountFile"
        :label="$t('userConnectorForm.serviceAccount')"
        class="file-input-json"
        @change="updateServiceAccount"
        outlined
        hide-details
        accept=".json"
        >
      </v-file-input>
      <v-text-field
        v-if="displayServiceAccount"
        v-model="displayServiceAccount"
        readonly
        hide-details
        :label="$t('userConnectorForm.serviceAccountJson')"
        outlined
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import userConnectorValidation from '../../../validationDatas/userConnectorValidation';

export default {
  name: 'user-connector',
  validations() {
    return userConnectorValidation;
  },
  created() {},
  data() {
    return {
      userNames: ['firebase'],
    };
  },
  props: ['updateData'],
  methods: {
    onInput() {
      if (this.$v.$invalid) {
        this.$emit('onError');
      } else {
        this.$emit('onInput');
        this.$emit('updateConnector', this.getFormattedUpdateData());
      }
    },
    setServiceAccount(data) {
      this.updateData.configuration.serviceAccount = JSON.parse(data);
    },
    updateServiceAccount(data) {
      const reader = new FileReader();
      reader.onload = () => {
        const json = reader.result;
        this.setServiceAccount(json);
      };

      reader.readAsText(data);
    },
    getFormattedUpdateData() {
      return {
        name: this.updateData.name,
        configuration: {
          serviceAccount: this.updateData.configuration.serviceAccount,
          serverKey: this.updateData.configuration.serverKey,
        },
        uiConfiguration: {
          apiKey: this.updateData.uiConfiguration.apiKey,
          projectId: this.updateData.uiConfiguration.projectId,
          authDomain: this.updateData.uiConfiguration.authDomain,
          storageBucket: this.updateData.uiConfiguration.storageBucket,
          messagingSenderId: this.updateData.uiConfiguration.messagingSenderId,
        },
      };
    },
  },
  computed: {
    displayServiceAccount() {
      if (this.updateData.configuration.serviceAccount) {
        return JSON.stringify(this.updateData.configuration.serviceAccount);
      }

      return '';
    },
  },
};
</script>
<style>
  .file-input-json {
    margin-bottom: 30px !important;
  }
</style>

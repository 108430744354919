<template>
  <div class="app-build-management-view">
    <div class="topbar d-flex justify-space-between align-center">
      <div class="topbar__left">
        <h2>
          App Build Management
        </h2>
      </div>
      <div class="topbar__right">
        <v-select
          class="mb-0"
          v-model="selectedSubView"
          :items="subViewSelectOptions"
          item-text="display"
          item-value="code"
          label="Settings mode"
          return-object
          single-line
          hide-details
          outlined>
        </v-select>
      </div>
    </div>
    <template v-if="selectedSubView.code === 'globalSettingsView'">
      <global-app-build-management style="padding-top: 2rem;"></global-app-build-management>
    </template>
    <template v-if="selectedSubView.code === 'appBuildsView'">
      <build>
      </build>
    </template>
  </div>
</template>

<script>
import Build from '@/components/builds/Build.vue';
import GlobalAppBuildManagement from '@/components/builds/GlobalAppBuildManagement.vue';

const GLOBAL_SETTINGS_VIEW_CODE = 'globalSettingsView';
const APP_BUILDS_VIEW_CODE = 'appBuildsView';

export default {
  name: 'builds-view',
  components: {
    GlobalAppBuildManagement,
    Build,
  },
  data() {
    return {
      selectedSubView: null,
    };
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedSubView = this.subViewSelectOptions[1];
  },
  computed: {
    subViewSelectOptions() {
      return [{
        code: GLOBAL_SETTINGS_VIEW_CODE,
        display: 'Global Build Settings',
      }, {
        code: APP_BUILDS_VIEW_CODE,
        display: 'App Builds',
      }];
    },
  },
};
</script>

<style scoped>

</style>

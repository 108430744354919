<template>
  <v-container>
    <NetworkList @loadData="getNetworks" :is-loading="networksLoading" :total-items="totalNetworks" :items="networks" @viewNetworkFleetsClick="viewFleetList" @editNetworkClick="displayNetworkDialog($event)" @createNetworkClick="displayNetworkDialog($event)" >
    </NetworkList>
    <NetworkDialog :network="selectedNetwork" :display="isDisplayDialog" @onDismiss="closeNetworkDialog" @saveNetwork="saveNetwork($event)">
    </NetworkDialog>
  </v-container>
</template>

<script>
import NetworkList from '@/components/network/NetworkList.vue';
import NetworkDialog from '@/components/network/NetworkDialog.vue';

export default {
  name: 'network-list-view',
  data() {
    return {
      selectedNetwork: null,
      emptyNetwork: {
        name: null,
        paymentInstance: null,
      },
      networks: [],
      networksLoading: true,
      totalNetworks: 0,
      isDisplayDialog: false,
    };
  },
  components: {
    NetworkList,
    NetworkDialog,
  },
  methods: {
    closeNetworkDialog() {
      this.selectedNetwork = null;
      this.isDisplayDialog = false;
    },
    displayNetworkDialog(network) {
      this.selectedNetwork = (network) || this.emptyNetwork;
      this.isDisplayDialog = true;
    },
    async viewFleetList(network) {
      await this.$router.push(`/networks/${network.id}/fleets`);
    },
    async saveNetwork(networkData) {
      const storeAction = (!networkData.id) ? 'fleetNetworkApi/save' : 'fleetNetworkApi/update';
      const networkId = await this.$store.dispatch(storeAction, networkData);
      if (networkId) {
        this.isDisplayDialog = false;
        this.selectedNetwork = this.emptyNetwork;
      }
    },
    async getNetworks({ page, maxPerPage, dynamicSearch }) {
      this.networksLoading = true;
      const { fleetNetworks, totalItems } = await this.$store.dispatch('fleetNetworkApi/getFleetNetworks', {
        page, maxPerPage, dynamicSearch,
      });
      this.networks = fleetNetworks;
      this.totalNetworks = totalItems;
      this.networksLoading = false;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <alert-snackbar
      v-for="(success, index) in successes"
      :key="`alert-${index}`"
      :globalMessage="success"
      v-on:dismissed="onDismissed"
      type="success"
      :timeout="success.timeout"
    ></alert-snackbar>
  </div>
</template>

<script>
import { globalSuccessesComputed } from '../mixins/globalMessages.mixins';

export default {
  name: 'GlobalSuccesses',
  mixins: [globalSuccessesComputed],
  methods: {
    onDismissed(successes) {
      this.$store.dispatch('globalMessages/popSuccess', successes);
    },
  },
};
</script>

<style scoped>

</style>

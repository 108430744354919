var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('platform-toggle',{on:{"platformUpdate":_vm.setPlatform}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('autocomplete',{attrs:{"label":_vm.$t('builds.bookingChannel'),"loading":_vm.listBookingChannelInProgress,"items":_vm.bookingChannelList,"itemsName":"humanReadableId","outlined":"","hide-details":"","no-filter":"","placeholder":_vm.$t('builds.autocomplete')},on:{"searchAutocomplete":_vm.listBookingChannels,"updateModel":_vm.setBookingChannel}})],1),(_vm.platform === 'android')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[
              'production',
              'test' ],"outlined":"","hide-details":"","no-filter":""},model:{value:(_vm.paymentEnv),callback:function ($$v) {_vm.paymentEnv=$$v},expression:"paymentEnv"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[(_vm.platform === 'android')?_c('v-select',{attrs:{"items":[
              'latest',
              '4.2.0',
              '4.1.4',
              '4.1.5',
              '3.2.2' ],"outlined":"","hide-details":"","no-filter":""},model:{value:(_vm.buildVersion),callback:function ($$v) {_vm.buildVersion=$$v},expression:"buildVersion"}}):_vm._e(),(_vm.platform === 'ios')?_c('v-select',{attrs:{"items":[
              'latest',
              'spm',
              '4.1.5',
              '3.2.0' ],"outlined":"","hide-details":"","no-filter":""},model:{value:(_vm.buildVersion),callback:function ($$v) {_vm.buildVersion=$$v},expression:"buildVersion"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"header-button",attrs:{"color":"primary","block":"","large":"","loading":_vm.triggerBuildInProgress,"disabled":!_vm.bookingChannel || _vm.triggerBuildInProgress},on:{"click":_vm.triggerBuild}},[_vm._v(" "+_vm._s(_vm.$t('build'))+" ")])],1)],1),_c('hr'),_c('v-row',[_c('build-list',{attrs:{"builds":_vm.builds,"buildsLoading":_vm.listBuildsInProgress,"buildDetailsLoading":_vm.buildDetailsLoading,"buildDetails":_vm.buildDetails,"totalBuilds":_vm.builds.length},on:{"loadData":_vm.loadBuilds,"loadBuildDetails":_vm.loadBuildDetails}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
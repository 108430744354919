import {
  minLength, maxLength, required, decimal, numeric,
} from 'vuelidate/lib/validators';

import { isHexadecimalColor, isHumanReadableId } from '@/validation/validationMethods';

export default {
  bookingChannelData: {
    name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    humanReadableId: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
      isHumanReadableId,
    },
    currencySymbol: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    communicationLanguage: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    defaultCountry: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255),
    },
    colorScheme: {
      required,
      primary: { required, isHexadecimalColor },
      primaryDark: { required, isHexadecimalColor },
      primaryFont: { required, isHexadecimalColor },
      secondary: { required, isHexadecimalColor },
      error: { required, isHexadecimalColor },
      appIconBackground: { required, isHexadecimalColor },
      loginLogoBackground: { required, isHexadecimalColor },
      loginActionBackground: { required, isHexadecimalColor },
    },
    location: {
      required,
      latitude: {
        decimal,
        required,
      },
      longitude: {
        required,
        decimal,
      },
    },
    defaultTip: {
      required,
      defaultTipValue: {
        required,
        decimal,
      },
      defaultTipBase: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
    },
    preAuthorization: {
      amount: {
        required,
        numeric,
      },
      currency: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
    },
    allowedPaymentMethods: {
      required,
      minLength: minLength(1),
      $each: {
        type: {
          required,
          minLength: minLength(1),
        },
      },
    },
  },
};

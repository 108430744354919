<template>
  <div>
    <v-alert
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      The Allowed Payment methods will determine what payment methods the Passenger App & Webbooker will display to the user.
    </v-alert>
    <div class="payment-methods-form-group">
      <div class="payment-method-item" v-for="(paymentMethodItem) in paymentMethodGroups" :key="paymentMethodItem.display">
        <div class="payment-method-item--checkbox">
          <v-checkbox
            v-model="selectedPaymentMethodGroups"
            @change="onSelectChange"
            :value="paymentMethodItem"
            :disabled="selectedPaymentMethodGroups.length === 1 && isPaymentMethodSelected(paymentMethodItem.code)"
            color="green"
            hide-details
          ></v-checkbox>
        </div>
        <div class="payment-method-item--info">
          <h3>{{ paymentMethodItem.title }}</h3>
          <p class="ma-0">
            {{ paymentMethodItem.description }}
          </p>
        </div>
      </div>
    </div>
    <v-alert
      border="top"
      colored-border
      type="error"
      elevation="2"
      v-if="showPaymentMethodsAlert"
    >
      Changing payment methods will necessitate re building the apps
    </v-alert>
  </div>
</template>

<script>
import _ from 'lodash';
import { PaymentMethod } from '@/core/paymentMethod';

export default {
  name: 'payment-methods-form',
  props: ['value'],
  data() {
    return {
      selectedPaymentMethods: [],
      selectedPaymentMethodGroups: [],
      paymentMethodGroups: [
        {
          title: 'Cash',
          code: 'cash',
          description: 'Allow passengers to pay in cash.',
          types: [{
            type: PaymentMethod.CASH,
          }],
        },
        {
          title: 'Card',
          code: 'card',
          description: 'Allow passengers to pay by card.',
          types: [{
            type: PaymentMethod.CREDIT_CARD,
          }],
        },
        {
          title: 'Apple Pay',
          code: 'applePay',
          description: 'Allow passengers using the Passenger App to use the native Apple payment service to pay for bookings.',
          types: [{
            type: 'applePay',
          }],
        },
        {
          title: 'Google Pay',
          code: 'googlePay',
          description: 'Allow passengers using the Passenger App to use the native Google payment service to pay for bookings.',
          types: [{
            type: 'googlePay',
          }],
        },
        {
          title: 'Track and Pay',
          code: 'trackAndPay',
          description: 'Allow passengers using Track My Taxi product to pay with their native (mobile) Apple Pay or Google Pay services.',
          types: [{
            type: PaymentMethod.APPLE_PAY_GUEST,
          }, {
            type: PaymentMethod.GOOGLE_PAY_GUEST,
          }],
        },
        {
          title: 'Business Accounts',
          code: 'businessAccounts',
          description: 'Allow passengers to make use of their employer\'s business accounts to pay for bookings. Enabling this option will allow business account logins in the Passenger App.',
          types: [{
            type: PaymentMethod.BUSINESS_ACCOUNT,
          }, {
            type: PaymentMethod.IC2_ACCOUNT,
          }],
        },
      ],
      allowedPaymentMethods: [],
      showPaymentMethodsAlert: false,
    };
  },
  created() {
    this.preselectExistingPaymentMethods();
  },
  watch: {
    value() {
      this.preselectExistingPaymentMethods();
    },
  },
  methods: {
    onSelectChange() {
      this.showPaymentMethodsAlert = true;
      const paymentMethods = this.selectedPaymentMethodGroups.reduce((acc, item) => [...acc, ...item.types], []);
      // We must be careful to maintain the same array reference
      // so that `Vuelidate` can keep track of the changes.
      // As a result we "mutate" the `selectedPaymentMethods`.
      this.selectedPaymentMethods.length = 0;
      this.selectedPaymentMethods = paymentMethods;
      this.$emit('input', this.selectedPaymentMethods);
      this.$emit('inputChange', this.selectedPaymentMethods);
    },
    /**
     * Pre-select existing payment methods and disable them.
     */
    preselectExistingPaymentMethods() {
      this.paymentMethods = this.value;
      if (!Array.isArray(this.paymentMethods)) {
        this.paymentMethods = [];
        return;
      }

      const selectedGroupsIdx = {};
      // eslint-disable-next-line no-unused-vars
      this.paymentMethodGroups.forEach((group) => {
        this.paymentMethods.forEach((paymentMethod) => {
          const matchingType = _.find(group.types, { type: paymentMethod.type });
          if (matchingType) {
            selectedGroupsIdx[group.code] = group;
          }
        });
      });

      this.selectedPaymentMethodGroups = [];
      Object.keys(selectedGroupsIdx).forEach((code) => {
        this.selectedPaymentMethodGroups.push(selectedGroupsIdx[code]);
      });
    },
    isPaymentMethodSelected(code) {
      const group = _.find(this.selectedPaymentMethodGroups, { code });
      return !!(group);
    },
  },
};
</script>

<style scoped lang="scss">
  .payment-methods-form-group {
    .payment-method-item {
      color: #000000;
      border-radius: 4px;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
      padding: 1.2rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .payment-method-item--checkbox {
        padding-right: 1.2rem;

        .v-input--selection-controls {
          margin: 0;

          .v-input--selection-controls__input {
            margin: 0 !important;
          }
        }
      }
    }
  }
</style>

<template>
  <v-col cols='12'>
    <v-row>
      <v-col cols="12">
        <h4>{{ $t('onboarding.appSigningGeneration') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>{{ $t('onboarding.appSigningGenerationExplanation') }}</p>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="4">
        <v-btn
          block
          large
          class="header-button"
          color="primary"
          @click="generateAndroidKeystore">
          {{ $t(`generate`) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'app-signing-generate-form',
  methods: {
    generateAndroidKeystore() {
      this.$emit('generate-android-keystore');
    },
  },
};
</script>

<style scoped>

</style>

<template>
<div>
  <console-user-form console-type="customerConsole" :remote-state="remoteState" @createUser="createUser"></console-user-form>
</div>
</template>

<script>
import ConsoleUserForm from './ConsoleUserForm.vue';

export default {
  name: 'CustomerConsoleForm',
  components: { ConsoleUserForm },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async createUser(user) {
      await this.$store.dispatch('bookingChannelView/showLoader');

      try {
        await this.$store.dispatch('onboarding/createConsoleAdmin', {
          ...user,
          bookingChannelId: this.remoteState.bookingChannel.id,
          product: 'customer',
          throwFail: true,
        });
      } catch (e) {
        await this.$store.dispatch('bookingChannelView/hideLoader');
        throw e;
      }

      await this.$store.dispatch('bookingChannelView/refreshRemoteState', {
        bookingChannelId: this.remoteState.bookingChannel.id,
      });
    },
  },
};
</script>

<style scoped>

</style>

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';

const defaultState = {
  onboardingApiServiceClient: null,
  gitlabApiServiceClient: null,
  appSigningInProgress: false,
};

const getters = {
  appSigningInProgress: state => state.appSigningInProgress,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
  gitlabApiServiceClient: (state) => {
    if (state.gitlabApiServiceClient === null) {
      state.gitlabApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_GITLAB_API_BASE_URL,
        timeout: 30000,
        headers: { 'PRIVATE-TOKEN': process.env.VUE_APP_GITLAB_API_TOKEN },
      });
    }
    return state.gitlabApiServiceClient;
  },
};

const actions = {
  async uploadAndroidKeystore(context, { bookingChannelId, appSigning, throwFail = false }) {
    try {
      context.commit('setAppSigningInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/appsigning', { bookingChannelId, appSigning });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.appSigningUploadSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.appSigningUploadError', error },
        { root: true },
      );

      if (throwFail === true) {
        throw error;
      }
    } finally {
      context.commit('setAppSigningInProgress', false);
    }
  },
  async generateAndroidKeystore(context, { humanReadableId, throwFail = false }) {
    try {
      context.commit('setAppSigningInProgress', true);
      await context.getters.gitlabApiServiceClient
        .post(
          `/projects/${process.env.VUE_APP_GITLAB_ANDROID_PROJECT_ID}/pipeline`,
          {
            ref: process.env.VUE_APP_GITLAB_BUILD_REFERENCE,
            variables: [
              { value: humanReadableId, key: 'HUMAN_READABLE_ID' },
              { value: 'true', key: 'ONBOARDING' },
            ],
          },
        );
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.appSigningGenerationSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.appSigningGenerationError', error },
        { root: true },
      );
      if (throwFail === true) {
        throw error;
      }
    } finally {
      context.commit('setAppSigningInProgress', false);
    }
  },
};

const mutations = {
  setAppSigningInProgress(state, appSigningInProgress) {
    state.appSigningInProgress = appSigningInProgress;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <payment-connector-dialog
      :fleetId="selectedFleetId"
      :paymentConnectors="selectedPaymentConnectors"
      :showDialog="isShowingPaymentConnectorDialog"
      @close="closePaymentConnectorDialog"
    />

    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :loading="isLoading"
      hide-default-footer
      :multi-sort="false"
      @page-count="pageCount = $event"
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :footer-props="{showFirstLastPage: true}"
      class="ic-table elevation-0 ic-table--heavy-headers"
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="center">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
                Fleets
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-btn
                  style="margin-right: 1rem;"
                  color="success"
                  class="header-list-button"
                  @click="createClick"
                >
                  <span class="hidden-sm-and-down">Create</span>
                  <v-icon class="hidden-md-and-up">$icons_system_plus</v-icon>
                </v-btn>
                <v-text-field
                  v-model="dynamicSearch"
                  append-icon="$icons_system_search"
                  :label="'Search'"
                  single-line
                  clearable
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="font-weight-black black--text">{{ header.text }}</span>
      </template>
      <template v-slot:item.id="{ item }">
        <string-copy-tooltip truncate :text="item.id"></string-copy-tooltip>
      </template>
      <template v-slot:item.dispatchFleetUuid="{ item }">
        <string-copy-tooltip v-if="item.dispatchFleetUuid" truncate
                             :text="item.dispatchFleetUuid"></string-copy-tooltip>
      </template>
      <template v-slot:item.dispatchFleetIx="{ item }">
        <string-copy-tooltip truncate v-if="item.dispatchFleetIx" :text="item.dispatchFleetIx"></string-copy-tooltip>
      </template>
      <template v-slot:item.paymentAccountOnboarding="{ item }">
        <span v-if="!hasOnboardingStatus(item.connectors)">N/A</span>
        <v-btn v-if="hasOnboardingStatus(item.connectors) && !hasPendingPaymentOnboardings(item.connectors)"
               small color="success"
               @click="evaluatePaymentAccounts(item)">
          <v-icon left>mdi-file-check</v-icon>{{ $t('paymentConnector.evaluate') }}
        </v-btn>

        <v-tooltip bottom v-if="hasOnboardingStatus(item.connectors) && hasPendingPaymentOnboardings(item.connectors)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small color="warning" @click="evaluatePaymentAccounts(item)">
              <v-icon left>mdi-file-clock</v-icon>{{ $t('paymentConnector.evaluate') }}
            </v-btn>
          </template>
          {{ nbPendingMigrations(item.connectors) }} {{ $t('paymentConnector.pending') }}
        </v-tooltip>
      </template>
      <template v-slot:item.webhooks="{ item }">
        <v-btn small color="primary" @click="webhooksClick(item)">
          Webhooks
        </v-btn>
      </template>
      <template v-slot:item.actionViewFleet="{ item }">
        <v-btn small color="primary" @click="viewFleetClick(item)">
          View
        </v-btn>
      </template>
      <template v-slot:item.actionViewFleetJson="{ item }">
        <v-btn small color="primary" @click="viewFleetJsonClick(item)">
          JSON
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-8">
      <v-pagination v-model="page" :length="pageCount" total-visible="10"></v-pagination>
    </div>
  </div>
</template>

<script>

import debounce from 'debounce';
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';
import PaymentConnectorDialog from '@/components/paymentConnector/PaymentConnectorDialog.vue';
import { PaymentConnectorStatus } from '@/core/paymentConnectorStatus';
import _ from 'lodash';

export default {
  name: 'FleetList',
  components: {
    StringCopyTooltip,
    PaymentConnectorDialog,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },
  methods: {
    viewFleetClick(fleet) {
      this.$emit('viewFleetClick', fleet);
    },
    viewFleetJsonClick(fleet) {
      this.$emit('viewFleetJsonClick', fleet);
    },
    createClick() {
      this.$emit('viewNewFleetForm');
    },
    webhooksClick(fleet) {
      this.$emit('webhooksClick', fleet.id);
    },
    async load() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$emit('loadData', {
        page: page - 1,
        dynamicSearch: this.dynamicSearch,
        maxPerPage: itemsPerPage,
      });
    },
    closePaymentConnectorDialog({ reload }) {
      this.isShowingPaymentConnectorDialog = false;
      this.selectedFleetId = null;
      this.selectedPaymentConnectors = null;
      if (reload) {
        this.load();
      }
    },
    hasPendingPaymentOnboardings(connectors) {
      const paymentConnectors = _.get(connectors, 'payment');
      if (!paymentConnectors) return false;
      return _.reduce(paymentConnectors, (result, connector) => {
        if ([PaymentConnectorStatus.PENDING].includes(connector.status)) {
          return true;
        }
        return result;
      }, false);
    },
    hasOnboardingStatus(connectors) {
      const paymentConnectors = _.get(connectors, 'payment');
      return _.reduce(paymentConnectors, (result, connector) => {
        if ([PaymentConnectorStatus.COMPLETED, PaymentConnectorStatus.PENDING].includes(connector.status)) {
          return true;
        }
        return result;
      }, false);
    },
    nbPendingMigrations(connectors) {
      let nbPendingMigrations = 0;
      const paymentConnectors = _.get(connectors, 'payment');
      if (!paymentConnectors) return false;
      paymentConnectors.forEach((connector) => {
        const connectorStatus = _.get(connector, 'status');
        if (connectorStatus === PaymentConnectorStatus.PENDING) nbPendingMigrations += 1;
      });
      return nbPendingMigrations;
    },
    evaluatePaymentAccounts(fleet) {
      const paymentConnectors = _.get(fleet, 'connectors.payment');
      if (!paymentConnectors || !paymentConnectors.length) {
        return;
      }
      this.selectedFleetId = fleet.id;
      this.isShowingPaymentConnectorDialog = true;
    },
  },
  data() {
    return {
      dynamicSearch: '',
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: true,
      isShowingPaymentConnectorDialog: false,
      selectedFleetId: null,
      selectedPaymentConnectors: [],
    };
  },
  created() {
    this.debouncedLoad = debounce(this.load, 500);
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
    totalItems() {
      this.options.pageCount = Math.round(this.totalItems / this.itemsPerPage) + 1;
    },
    dynamicSearch(v) {
      if (!v || v.length !== 1) {
        this.page = 1;
        this.debouncedLoad();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Human Readable ID',
          sortable: false,
          value: 'humanReadableId',
        },
        {
          text: 'Name',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Dispatch Client ID',
          sortable: false,
          value: 'clientId',
        },
        {
          text: 'Dispatch UUID',
          value: 'dispatchFleetUuid',
        },
        {
          text: 'Dispatch Fleet IX',
          value: 'dispatchFleetIx',
        },
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Timezone',
          value: 'timezone',
        },
        {
          text: 'Data Region',
          value: 'dataRegion',
        },
        {
          text: 'Payment Account Onboarding',
          value: 'paymentAccountOnboarding',
        },
        {
          text: '',
          value: 'webhooks',
          sortable: false,
          align: 'end',
        },
        {
          text: '',
          value: 'actionViewFleet',
          sortable: false,
          align: 'end',
        },
        {
          text: '',
          value: 'actionViewFleetJson',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>

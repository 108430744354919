<template>
  <v-container>
    <v-row
      class="buildDetails"
      justify="space-between"
      v-if="!buildDetailsLoading">
      <v-chip
        v-for="(buildDetail, index) in buildDetails"
        :key="index"
        dark :color="getBuildColor(buildDetail.status)">
        <v-icon>{{ getBuildIcon(buildDetail.status) }}</v-icon>
        <span class="status">{{ buildDetail.name }}</span>
      </v-chip>
    </v-row>
    <v-row
      class="buildDetails"
      justify="center"
      v-if="buildDetailsLoading">
      <v-progress-circular
        size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import BuildMixin from '../../mixins/build.mixin';

export default {
  name: 'BuildDetails',
  mixins: [BuildMixin],
  props: {
    buildDetailsLoading: {
      type: Boolean,
      required: true,
    },
    buildDetails: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
  .buildDetails {
    margin: 20px 40px;
  }
  .status {
    margin-left: 5px;
  }
</style>

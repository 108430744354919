var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"ic-table elevation-0 ic-table--heavy-headers",attrs:{"headers":_vm.headers,"items":_vm.listData.data,"loading":_vm.isLoading,"items-per-page":5000,"hide-default-footer":"","sort-by":['createdAt', 'jotForm.businessName'],"sort-desc":[true, false],"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{staticClass:"white header-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","dense":"","justify":"space-between"}},[_c('v-col',{staticClass:"v-toolbar__title",attrs:{"cols":"12","lg":"8"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Jot Forms ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"append-icon":"$icons_system_search","label":'Search',"single-line":"","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"error header-list-button",on:{"click":_vm.closeClick}},[_vm._v(" Close ")])],1)])],1)],1),_c('div',{staticClass:"header-separator"})]},proxy:true},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-black black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('string-copy-tooltip',{attrs:{"truncate":"","text":item.id}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.importClick(item)}}},[_vm._v(" Import ")])]}}])}),_c('div',{staticClass:"text-center pt-8"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","disabled":!_vm.cursors.length},on:{"click":function($event){return _vm.loadPrev()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-skip-previous")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary","disabled":!_vm.listData.cursor},on:{"click":function($event){return _vm.loadNext()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-skip-next")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-select
      v-model="selectedValue"
      :items="managedFeesEnum"
      @change="updateSelection"
      :label="$t('fleetManagementForm.externalFees')"
      multiple
      chips
      outlined
      hide-details
      no-filter
    ></v-select>
  </div>
</template>

<script>

export default {
  name: 'managed-fees-form',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.preSelect();
  },
  data() {
    return {
      managedFeesEnum: ['javelin'],
      selectedValue: [],
    };
  },
  watch: {
    value() {
      this.preSelect();
    },
  },
  methods: {
    preSelect() {
      this.selectedValue = [...this.value];
    },
    updateSelection() {
      this.$emit('input', this.selectedValue);
      this.$emit('inputChange', this.selectedValue);
    },
  },
};
</script>

<template>
  <financial-settings></financial-settings>
</template>

<script>
import FinancialSettings from '@/components/financialSettings/FinancialSettings.vue';

export default {
  name: 'FleetBchFinancialSettingsView',
  components: { FinancialSettings },
};
</script>

<style scoped>

</style>

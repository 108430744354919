import * as _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export class HiDash {
  static zeroIfNil(value) {
    return _.isNil(value) ? 0 : value;
  }

  static isGreaterThanZero(value) {
    return value > 0;
  }

  static secondsToMinutes(seconds) {
    if (seconds === null) {
      return null;
    }

    if (seconds === undefined) {
      return null;
    }

    return seconds * 60;
  }
}

<template>
  <v-row>
    <v-col cols="12" class="py-14 px-8">

      <!-- Vehicle Icon Section Start -->
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <span class="headline">{{ $t('fleetManagementForm.fleetBookingAppConfig.vehicleIcons.label') }}</span>
            </v-card-title>

            <!--Pre Loader when vehicleIcons is empty-->
            <v-card-text v-if="vehicleIcons.length === 0">
              <v-row>
                <v-col v-for="i in 4" :key="`vehicle_icon_skeleton_loop${i}`" xs="12" sm="6" lg="4" xl="3">
                  <v-skeleton-loader
                    type="image,article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card-text>

            <!--Vehicle Icons-->
            <v-card-text v-else>
              <!-- Start: Show alert for unsaved changes-->
              <v-row v-if="hasUnsavedChanges">
                <v-col xs="12" md="6" class="py-0">
                  <v-alert
                    icon="mdi-cloud-alert"
                    dense
                    type="warning"
                    class="my-0"
                  >
                    <span v-html="$t('fleetManagementForm.fleetBookingAppConfig.vehicleIcons.unsavedChanges')"></span>
                  </v-alert>
                </v-col>
              </v-row>
              <!-- End: Show alert for unsaved changes-->

              <!-- Start: icons graphic guidelines -->
              <v-row>
                <v-col cols="12">
                  <vehicle-icon-guidelines></vehicle-icon-guidelines>
                </v-col>
              </v-row>
              <!-- End: icons graphic guidelines -->

              <v-row v-if="defaultVehicleIcon">
                <v-col xs="12" sm="6" lg="4" xl="3">
                  <vehicle-type :vehicle-type="defaultVehicleIcon"></vehicle-type>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="vehicleIcon in nonDefaultVehicleIcons" :key="vehicleIcon.vehicleType" xs="12" sm="6" lg="4" xl="3">
                  <vehicle-type :vehicle-type="vehicleIcon" v-if="!vehicleIcon.isRemovedFromDispatch"></vehicle-type>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="saveVehicleIcons">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>

        </v-col>
      </v-row>
      <!-- Vehicle Icon Section End -->

    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import VehicleType from '@/components/fleetManagement/FleetBookingAppConfig/VehicleType.vue';
import VehicleIconGuidelines from '@/components/fleetManagement/FleetBookingAppConfig/VehicleIconGuidelines.vue';

const DEFAULT_VEHICLE_TYPE_KEY = 'pax_default_override';

export default {
  name: 'fleet-booking-app-config',
  props: {
    fleet: {
      type: Object,
      required: true,
    },
  },
  components: {
    VehicleType,
    VehicleIconGuidelines,
  },
  data() {
    return {
      dispatchVehicleIcons: [],
      vehicleIcons: [],
    };
  },
  async mounted() {
    this.registerAlertForUnsavedChanges();

    /**
     * Get vehicle icons from dispatch
     */
    await this.initDispatchVehicleIcons();
    /**
     * Merge vehicle icons from dispatch with stored fleet vehicle icons
     */
    await this.initVehicleIcons();
  },
  methods: {
    async initVehicleIcons() {
      const storedVehicleTypeListIconsWithKeys = _.get(this.fleet, 'fleetBookingAppConfig.dispatchVehicleTypeListIcons.icons', {});
      const storedVehicleTypeMapIconsWithKeys = _.get(this.fleet, 'fleetBookingAppConfig.dispatchVehicleTypeMapIcons.icons', {});

      const storedVehicleTypeListIcons = _.flatMap(storedVehicleTypeListIconsWithKeys);
      const storedVehicleTypeMapIcons = _.flatMap(storedVehicleTypeMapIconsWithKeys);

      /**
       * merge dispatchVehicleTypes with vehicle types stored in DB. Then get all vehicle types ['R4', 'R8', 'S' ....]
       */
      const vehicleTypeKeys = _.chain(_.flatMap(this.dispatchVehicleIcons))
        .concat(storedVehicleTypeListIcons)
        .concat(storedVehicleTypeMapIcons)
        .map(vehicleIcon => vehicleIcon.vehicleType || vehicleIcon.key)
        /**
         * Attach pax_default_override key to the list of vehicle types (if missing / not stored in DB)
         * Since we need to always display it in the form
         */
        .concat(DEFAULT_VEHICLE_TYPE_KEY)
        .uniq()
        .value();

      /**
       * Prepare the vehicleIcons array. This will be used to display the vehicle icons in the form
       * Vehicle types stored in DB but not in the dispatch will be marked as removedFromDispatch and also isDirty property will be set to true
       * Because we need to send these vehicle types to the backend to remove them from the DB
       */
      this.vehicleIcons = _.map(vehicleTypeKeys, (vehicleTypeKey) => {
        const storedVehicleTypeListIcon = _.find(storedVehicleTypeListIcons, { vehicleType: vehicleTypeKey }) || null;
        const storedVehicleTypeMapIcon = _.find(storedVehicleTypeMapIcons, { vehicleType: vehicleTypeKey }) || null;

        const vehicleTitle = vehicleTypeKey === DEFAULT_VEHICLE_TYPE_KEY ? this.$t('fleetManagementForm.fleetBookingAppConfig.vehicleIcons.defaultVehicleIconLabel') : _.get(this.dispatchVehicleIcons, `${vehicleTypeKey}.title`, null);
        let isRemovedFromDispatch;
        if (vehicleTypeKey === DEFAULT_VEHICLE_TYPE_KEY) {
          isRemovedFromDispatch = false;
        } else {
          isRemovedFromDispatch = !_.get(this.dispatchVehicleIcons, `${vehicleTypeKey}`);
        }
        return {
          /**
           * vehicleType, vehicleTitle and isRemovedFromDispatch are used to display the vehicle icon in the form.
           * These properties are redundant but are helpful so that we don't have to do dig into the listIcon and mapIcon object to get the values
           */
          vehicleType: vehicleTypeKey,
          vehicleTitle,
          isRemovedFromDispatch,
          listIcon: {
            ...this.getNullVehicleIcon(),
            ...(storedVehicleTypeListIcon || {}),
            vehicleType: vehicleTypeKey,
            vehicleTitle,
            isRemovedFromDispatch,
            isDirty: Boolean(isRemovedFromDispatch && storedVehicleTypeListIcon),
          },
          mapIcon: {
            ...this.getNullVehicleIcon(),
            ...(storedVehicleTypeMapIcon || {}),
            vehicleType: vehicleTypeKey,
            vehicleTitle,
            isRemovedFromDispatch,
            isDirty: Boolean(isRemovedFromDispatch && storedVehicleTypeMapIcon),
          },
        };
      });
    },
    async initDispatchVehicleIcons() {
      this.dispatchVehicleIcons = await this.$store.dispatch('fleets/getDispatchVehicleIcons', { fleetId: this.fleet.id });
    },
    /**
     * We will send the listIcon and mapIcon in separate API requests to the backend because of max payload size limit
     */
    async saveVehicleIcons() {
      const listIcons = _.chain(this.vehicleIcons)
        .filter(vehicleIcon => vehicleIcon.listIcon.isDirty)
        .map((vehicleIcon) => {
          let { listIcon } = vehicleIcon;
          if (vehicleIcon.listIcon.isRemovedFromDispatch) {
            listIcon = {
              ...vehicleIcon.listIcon,
              dimensions: null,
              fileSize: null,
              imageUrl: null,
              imageBase64Encoded: null,
            };
          }
          return _.omit(listIcon, ['isDirty', 'isRemovedFromDispatch', 'imageUrl', 'vehicleTitle']);
        })
        .value();
      const mapIcons = _.chain(this.vehicleIcons)
        .filter(vehicleIcon => vehicleIcon.mapIcon.isDirty)
        .map((vehicleIcon) => {
          let { mapIcon } = vehicleIcon;
          if (vehicleIcon.mapIcon.isRemovedFromDispatch) {
            mapIcon = {
              ...vehicleIcon.mapIcon,
              dimensions: null,
              fileSize: null,
              imageUrl: null,
              imageBase64Encoded: null,
            };
          }
          return _.omit(mapIcon, ['isDirty', 'isRemovedFromDispatch', 'imageUrl', 'vehicleTitle']);
        })
        .value();

      const updatedFleetBookingAppConfig = await this.$store.dispatch('fleets/updateVehicleIconsInFleetBookingAppConfig', {
        fleet: this.fleet,
        listIcons,
        mapIcons,
      });
      if (updatedFleetBookingAppConfig) {
        this.fleet.fleetBookingAppConfig = updatedFleetBookingAppConfig;
      }
    },
    getNullVehicleIcon() {
      return {
        dimensions: null,
        fileSize: null,
        fileType: null,
        imageUrl: null,
        imageBase64Encoded: null,
        vehicleType: null,
        vehicleTitle: null,
        /**
         * Only set true when vehicle type is present in the DB but not preset in dispatch
         */
        isRemovedFromDispatch: false,
        /**
         * It will be set to true if user interacts with this icon only (upload / delete)
         * Also it will be set to true if the icon is removed from dispatch (isRemovedFromDispatch=true)
         * Only this vehicle icon will be sent to the server if isDirty is true
         */
        isDirty: false,
      };
    },
    registerAlertForUnsavedChanges() {
      window.onbeforeunload = () => {
        if (this.$route.name === 'fleet-management-by-id' && this.hasUnsavedChanges) {
          return 'unsaved changes';
        }
        return null;
      };
    },
  },
  computed: {
    defaultVehicleIcon() {
      return _.find(this.vehicleIcons, vehicleIcon => vehicleIcon.vehicleType === DEFAULT_VEHICLE_TYPE_KEY);
    },
    nonDefaultVehicleIcons() {
      return _.filter(this.vehicleIcons, vehicleIcon => vehicleIcon.vehicleType !== DEFAULT_VEHICLE_TYPE_KEY);
    },
    hasUnsavedChanges() {
      return _.some(this.vehicleIcons, vehicleIcon => vehicleIcon.listIcon.isDirty || vehicleIcon.mapIcon.isDirty);
    },
  },
};
</script>

<style scoped>

</style>

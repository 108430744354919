<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="totalItems"
      :loading="isLoading"
      hide-default-footer
      :multi-sort="false"
      @page-count="pageCount = $event"
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :footer-props="{showFirstLastPage: true}"
      class="ic-table elevation-0 ic-table--heavy-headers"
    >
      <template v-slot:top>
        <v-container fluid class="white header-container">
          <v-row align="center" dense justify="space-between">
            <v-col cols="12" lg="8" class="v-toolbar__title">
              <p class="font-weight-bold">
                Booking Channels
              </p>
            </v-col>

            <v-col cols="12" lg="4">
              <div class="d-flex justify-end">
                <v-btn
                  style="margin-right: 1rem;"
                  color="success"
                  class="header-list-button"
                  @click="createClick"
                >
                  <span class="hidden-sm-and-down">Create</span>
                  <v-icon class="hidden-md-and-up">$icons_system_plus</v-icon>
                </v-btn>
                <v-text-field
                    v-model="dynamicSearch"
                    append-icon="$icons_system_search"
                    :label="'Search'"
                    single-line
                    clearable
                    dense
                    outlined
                    hide-details
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="header-separator"></div>
      </template>
      <template v-slot:item.id="{ item }">
        <string-copy-tooltip truncate :text="item.id"></string-copy-tooltip>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="font-weight-black black--text">{{ header.text }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn small color="primary" @click="viewClick(item)">
          View
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-8">
      <v-pagination v-model="page" :length="pageCount" total-visible="10"></v-pagination>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import environment from '@/environment';
import StringCopyTooltip from '@/components/StringCopyTooltip.vue';

export default {
  name: 'BookingChannelList',
  components: { StringCopyTooltip },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },
  methods: {
    shouldShowCreateButton() {
      return !environment.isDeploymentEnvProduction();
    },
    createClick() {
      this.$emit('onCreateClick');
    },
    viewClick(bookingChannel) {
      this.$emit('viewClick', bookingChannel);
    },
    async load() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$emit('loadData', {
        page: page - 1,
        dynamicSearch: this.dynamicSearch,
        maxPerPage: itemsPerPage,
      });
    },
  },
  data() {
    return {
      dynamicSearch: '',
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: true,
    };
  },
  created() {
    this.debouncedLoad = debounce(this.load, 500);
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
    totalItems() {
      this.options.pageCount = Math.round(this.totalItems / this.itemsPerPage) + 1;
    },
    dynamicSearch(v) {
      if (!v || v.length !== 1) {
        this.page = 1;
        this.debouncedLoad();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Human Readable ID',
          sortable: false,
          value: 'humanReadableId',
        },
        {
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Default Country',
          align: 'left',
          sortable: false,
          value: 'defaultCountry',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'end',
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>

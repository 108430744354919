<template>
  <v-dialog
    max-width="750"
    v-if="display"
    :value="true"
    scrollable
    persistent
  >
    <v-card class="mx-auto">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ fleet.companyName }} - <span :class="statusClass">{{ webhooksStatusComplete }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text style="height: 100%; overflow-y: hidden;">
        <div class="webhooks-container" v-if="webhooksSupported">
          <p v-for="(event, index) in events" v-bind:key="index">{{ event.event }}
            <span v-if="event.status === 'exists'" class="green--text">
              <v-icon color="success">
                check
              </v-icon>
              exists
            </span>
            <span v-else class="red--text">
              <v-icon color="error">
                error_outline
              </v-icon>
              missing
            </span>
          </p>
        </div>
        <div class="webhooks-container" v-if="!webhooksSupported">
          <p>Webhooks are not supported</p>
        </div>
      </v-card-text>
      <v-card-actions v-if="webhooksSupported">
        <v-btn small color="primary" @click="onboardWebhooks()" :loading="creatingWebhooks">
          Onboard Webhooks
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FleetWebhooksDialog',
  data: () => ({
    creatingWebhooks: false,
  }),
  props: {
    fleet: {
      required: true,
    },
    display: {
      required: true,
    },
  },
  methods: {
    dismiss() {
      this.$emit('onDismiss');
    },
    async onboardWebhooks() {
      this.creatingWebhooks = true;
      const saved = await this.$store.dispatch('fleets/createWebhooks', { fleetId: this.fleet.id });
      this.creatingWebhooks = false;
      if (saved) {
        this.$emit('onDismiss');
      }
    },
  },
  computed: {
    events() {
      return _.get(this.fleet, 'webhooks.events', []);
    },
    webhooksStatusComplete() {
      if (_.get(this.fleet, 'webhooks.supported') === false) {
        return 'UNSUPPORTED';
      }
      const events = _.get(this.fleet, 'webhooks.events', []);
      const existingWebhooks = events.filter(event => event.status === 'exists');
      return events.length === existingWebhooks.length ? 'COMPLETE' : 'INCOMPLETE';
    },
    statusClass() {
      return this.webhooksStatusComplete === 'COMPLETE' ? 'green--text' : 'red--text';
    },
    webhooksSupported() {
      return _.get(this.fleet, 'webhooks.supported') === true;
    },
  },
};
</script>

<style scoped lang="scss">
.json-preview {
  padding: 1.2rem;
  margin: 1.2rem 0;
  background: #f9f9f9;
  border-radius: 4px;
  max-height: 100%;
  overflow-y: scroll;
  pre {
    font-weight: 500;
  }
}
.webhooks-container {
  margin: 20px 10px;
}
</style>

import { render, staticRenderFns } from "./JotForm.vue?vue&type=template&id=25a91e3c&scoped=true&"
import script from "./JotForm.vue?vue&type=script&lang=js&"
export * from "./JotForm.vue?vue&type=script&lang=js&"
import style0 from "./JotForm.vue?vue&type=style&index=0&id=25a91e3c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a91e3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDivider,VRow,VTextField})

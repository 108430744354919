import { isNil, cloneDeep } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const AppConfigUtil = {
  /**
   * Clean the appConfig model so that it
   * is ready for API submission.
   * @param appConfig
   * @returns {*}
   */
  cleanAppConfig(appConfig) {
    const payload = cloneDeep(appConfig);

    // Ensure numerical values are not strings
    payload.iosProjectVersion = !isNil(appConfig.iosProjectVersion) ? parseInt(appConfig.iosProjectVersion, 10) : null;
    payload.androidVersionCode = !isNil(appConfig.androidVersionCode) ? parseInt(appConfig.androidVersionCode, 10) : null;

    payload.percentageMaxAmount = !isNil(appConfig.percentageMaxAmount) ? parseInt(appConfig.percentageMaxAmount, 10) : null;
    payload.currencyMaxAmount = !isNil(appConfig.currencyMaxAmount) ? parseInt(appConfig.currencyMaxAmount, 10) : null;

    return payload;
  },
};

<template>
  <v-dialog v-if="display" :value="true" persistent scrollable width="80%" content-class="">
    <v-card class="mx-auto">
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          Create a Booking Channel
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="displayJotList">
            Import Jot Form
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 create-booking-channel-card-body" id="create-booking-channel-card-text">
        <div style="display: none;" id="scroll-top-cursor"></div>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="green"
        ></v-progress-linear>
        <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
          The form is invalid. Please correct the errors.
        </v-alert>
        <create-booking-channel-form v-if="currentView === viewsMap.form" :booking-channel-seed="bookingChannelSeed" :jot-form="selectedJotForm" @formChanged="onFormChanged($event)"></create-booking-channel-form>
        <customer-jot-list v-if="currentView === viewsMap.jotList" @importForm="preFillForm($event)" @loadData="loadJotList($event)" @close="displayForm" :is-loading="jotListLoading" :list-data="jotListData"></customer-jot-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dismiss">Cancel</v-btn>
        <v-btn color="success" @click="onSaveClick">Save</v-btn>
      </v-card-actions>
      <v-overlay :value="isLoading" z-index="1" opacity="0.1">
        <v-progress-circular
          indeterminate
          size="64"
          color="blue"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { JotFormMapper } from '@/core/jotFormMapper';
import CreateBookingChannelForm from './CreateBookingChannelForm.vue';
import CustomerJotList from '../customerData/CustomerJotList.vue';

export default {
  name: 'CreateBookingChannelDialog',
  props: {
    display: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CreateBookingChannelForm,
    CustomerJotList,
  },
  data() {
    return {
      isLoading: false,
      viewsMap: {
        jotList: 'JOT_LIST',
        form: 'FORM',
      },
      currentView: null,
      bookingChannelSeed: {},
      bookingChannel: null,
      selectedJotForm: null,
      jotListLoading: true,
      jotListItems: [],
      totalJotListItems: 0,
      jotListData: {
        data: [],
      },
      $vForm: null,
      canSaveForm: false,
      isDisplayFormError: false,
    };
  },
  created() {
    this.currentView = this.viewsMap.form;
  },
  methods: {
    displayJotList() {
      this.currentView = this.viewsMap.jotList;
    },
    displayForm() {
      this.currentView = this.viewsMap.form;
    },
    dismiss() {
      this.$emit('onDismiss');
    },
    async loadJotList({ cursor }) {
      this.jotListLoading = true;
      // eslint-disable-next-line no-unused-vars
      const data = await this.$store.dispatch('customerData/getJotFormList', { cursor });
      this.jotListData = data;
      this.jotListLoading = false;
    },
    // eslint-disable-next-line no-unused-vars
    preFillForm(jotForm) {
      this.selectedJotForm = jotForm;
      const jotFormMapped = JotFormMapper.mapToBookingChannel(jotForm);
      this.bookingChannelSeed = {
        ...jotFormMapped,
      };
      this.displayForm();
      window.setTimeout(() => {
        this.$vuetify.goTo('#scroll-top-cursor', { container: '.create-booking-channel-card-body' });
      }, 100);
    },
    // eslint-disable-next-line no-unused-vars
    onFormChanged({ bookingChannel, $v }) {
      this.bookingChannel = bookingChannel;
      this.$vForm = $v;
    },
    async onSaveClick() {
      this.isDisplayFormError = false;

      if (this.$vForm) {
        this.$vForm.$touch();
        if (this.$vForm.$invalid) {
          this.displayFormError();
        } else {
          await this.submit();
        }
      } else {
        this.displayFormError();
      }
    },
    displayFormError() {
      this.isDisplayFormError = true;
      window.setTimeout(() => {
        this.$vuetify.goTo('.form-error-alert', { container: '.create-booking-channel-card-body' });
      }, 100);
    },
    async submit() {
      this.isLoading = true;
      const payload = {
        ...this.bookingChannel,
        ...(this.selectedJotForm && { customerFormId: this.selectedJotForm.id }),
      };

      // eslint-disable-next-line no-unused-vars
      const createdBookingChannel = await this.$store.dispatch('bookingChannels/createBookingChannel', payload);
      this.isLoading = false;
      this.$store.dispatch(
        'globalMessages/addSuccess',
        { message: 'bookingChannel.createBookingChannelSuccess' },
        { root: true },
      );
      this.dismiss();
    },
  },
};
</script>

<style scoped>

</style>

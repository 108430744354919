<template>
  <v-autocomplete
    class="autocomplete-input"
    v-model="model"
    :items="items"
    :item-text="itemsName"
    return-object
    :placeholder="placeholder"
    outlined
    hide-details
    no-filter
    :disabled="disabled"
    :loading="loading"
    :search-input.sync="search"
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    itemsName: { required: true, type: String },
    label: { required: true, type: String },
    loading: { required: true, type: Boolean },
    items: { required: true, type: Array },
    disabled: { required: false, default: false },
    placeholder: { required: false, type: String },
  },
  data() {
    return {
      search: null,
      model: null,
    };
  },
  watch: {
    search(val) {
      if (!val) {
        return this.querySelections();
      }
      return val && val.length >= 3 && this.querySelections(val);
    },
    model(val) {
      this.$emit('updateModel', Object.assign({}, val));
    },
  },
  methods: {
    querySelections(value) {
      this.$emit('searchAutocomplete', value);
    },
  },
};
</script>

<style scoped lang="scss">
.autocomplete-input >>> .v-input__icon--prepend-inner {
  width: 20px;
  height: 20px;
}
</style>

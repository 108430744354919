<template>
  <v-icon v-if="!totalErrors" color="green">mdi-check-circle</v-icon>
  <v-icon v-else-if="warning" color="#eed202">mdi-alert-box</v-icon>
  <v-icon v-else color="error">mdi-alert-circle</v-icon>
</template>

<script>
export default {
  name: 'BookingChannelMenuStatus',
  props: {
    form: {
      type: Object,
      required: true,
    },
    remoteState: {
      type: Object,
      required: true,
    },
    warning: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    getErrors() {
      return this.form.getErrors(this.remoteState);
    },
  },
  computed: {
    totalErrors() {
      return this.getErrors().length;
    },
  },
};
</script>

<style scoped>

</style>

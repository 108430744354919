<template>
  <div>
    <alert-snackbar
      v-for="(error, index) in errors"
      :key="`alert-${index}`"
      :globalMessage="error"
      v-on:dismissed="onDismissed"
      type="error"
    ></alert-snackbar>
  </div>
</template>

<script>
import { globalErrorsComputed } from '../mixins/globalMessages.mixins';

export default {
  name: 'GlobalErrors',
  mixins: [globalErrorsComputed],
  methods: {
    onDismissed(errors) {
      this.$store.dispatch('globalMessages/popError', errors);
    },
  },
};
</script>

<style scoped>

</style>

import {
  minLength, required,
} from 'vuelidate/lib/validators';


export default {
  iosLiveActivity: {
    p8TeamId: {
      minLength: minLength(1),
      required,
    },
    p8AuthKeyId: {
      minLength: minLength(1),
      required,
    },
  },
};

<template>
  <loader :is-loading="loading" color="primary">
    <v-flex>
      <v-alert
        :value="invalidCallback"
        type="error"
      >
        {{ $t('authentication.error') }}
      </v-alert>
    </v-flex>
  </loader>
</template>
<script>
import { Authentication } from '@icabbi/vue-authentication';
import { mapGetters } from 'vuex';

export default {
  name: 'OAuthCallbackView',
  async mounted() {
    if (this.isValidCallback(this.$route.query)) {
      this.loading = true;
      await Authentication(this.$store)
        .exchangeCodeForToken(
          this.$route.query.code,
          this.loginContext,
          this.oidcClientId,
          this.authCallbackUrl,
        );
      this.$router.push(this.loginContext.to.path);
      this.$store.dispatch('authentication/clearLoginContext');
      this.loading = false;
    } else {
      await this.$store.dispatch('globalMessages/setAuthError', { message: this.getError(this.$route.query) });
      this.$router.push('/');
    }
  },
  data() {
    return {
      invalidCallback: false,
      loading: false,
      oidcClientId: process.env.VUE_APP_CLIENT_ID,
      authCallbackUrl: process.env.VUE_APP_AUTH_CALLBACK_URL,
    };
  },
  computed: {
    ...mapGetters({
      loginContext: 'authentication/loginContext',
      token: 'authentication/token',
    }),
  },
  methods: {
    isValidCallback(query) {
      return query.state
          && this.loginContext
          && this.loginContext.state
          && !query.error
          && query.state === this.loginContext.state;
    },
    getError(query) {
      if (query.error) {
        return query.error_description;
      }
      if (!this.loginContext || !this.loginContext.state) {
        return this.$t('authentication.invalidContext');
      }
      if (!query.state || !(query.state === this.loginContext.state)) {
        return this.$t('authentication.invalidState');
      }
      return this.$t('authentication.error');
    },
  },
};
</script>

<style scoped>
</style>

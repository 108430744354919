<template>
  <v-container>
    <v-row>
      <v-col cols='4'>
        <platform-toggle
          @platformUpdate="setPlatform">
        </platform-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='4'>
        <autocomplete
          :label="$t('builds.bookingChannel')"
          :loading='listBookingChannelInProgress'
          :items='bookingChannelList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('builds.autocomplete')"
          @searchAutocomplete='listBookingChannels'
          @updateModel='setBookingChannel'
        ></autocomplete>
      </v-col>
      <v-col cols='2' v-if="platform === 'android'">
        <v-select
          v-model="paymentEnv"
          :items="[
                'production',
                'test',
              ]"
          outlined
          hide-details
          no-filter
        ></v-select>
      </v-col>
      <v-col cols='2'>
        <v-select
          v-if="platform === 'android'"
          v-model="buildVersion"
          :items="[
                'latest',
                '4.2.0',
                '4.1.4',
                '4.1.5',
                '3.2.2',
              ]"
          outlined
          hide-details
          no-filter
        ></v-select>
        <v-select
          v-if="platform === 'ios'"
          v-model="buildVersion"
          :items="[
                'latest',
                'spm',
                '4.1.5',
                '3.2.0',
              ]"
          outlined
          hide-details
          no-filter
        ></v-select>
      </v-col>
      <v-col cols='2'>
        <v-btn
          color="primary"
          class="header-button"
          block
          large
          :loading="triggerBuildInProgress"
          :disabled="!bookingChannel || triggerBuildInProgress"
          @click="triggerBuild">
          {{ $t('build') }}
        </v-btn>
      </v-col>
    </v-row>
    <hr/>
    <v-row>
      <build-list
        @loadData="loadBuilds"
        @loadBuildDetails="loadBuildDetails"
        :builds="builds"
        :buildsLoading="listBuildsInProgress"
        :buildDetailsLoading='buildDetailsLoading'
        :buildDetails='buildDetails'
        :totalBuilds="builds.length"
      ></build-list>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BuildList from './BuildList.vue';
import PlatformToggle from '../elements/PlatformToggle.vue';

export default {
  name: 'build',
  components: {
    PlatformToggle,
    BuildList,
  },
  data() {
    return {
      platformOption: 0,
      bookingChannel: null,
      platform: null,
      paymentEnv: 'production',
      buildVersion: 'latest',
    };
  },
  computed: {
    ...mapGetters({
      bookingChannelList: 'bookingChannels/list',
      listBookingChannelInProgress: 'bookingChannels/listBookingChannelInProgress',
      triggerBuildInProgress: 'builds/triggerBuildInProgress',
      builds: 'builds/list',
      listBuildsInProgress: 'builds/listBuildsInProgress',
      buildDetails: 'builds/buildDetails',
      buildDetailsLoading: 'builds/buildDetailsLoading',
    }),
  },
  methods: {
    setBookingChannel(bookingChannel) {
      this.bookingChannel = bookingChannel;
    },
    listBookingChannels(dynamicSearch) {
      this.$store.dispatch('bookingChannels/list', dynamicSearch);
    },
    async triggerBuild() {
      await this.$store.dispatch('builds/trigger', {
        bookingChannel: this.bookingChannel,
        platform: this.platform,
        paymentEnv: this.paymentEnv,
        buildVersion: this.buildVersion,
      });
      await this.$store.dispatch('builds/list', { platform: this.platform });
    },
    loadBuilds() {
      this.$store.dispatch('builds/list', { platform: this.platform });
    },
    loadBuildDetails(build) {
      this.$store.dispatch('builds/loadBuildDetails', { platform: this.platform, build });
    },
    setPlatform(platform) {
      this.platform = platform;
      this.loadBuilds();
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

<template>
  <v-btn-toggle
    exclusive
    mandatory
    v-model='platformOption'>
    <v-btn><v-img width="10px" src="/apple.png"></v-img></v-btn>
    <v-btn><v-icon>android</v-icon></v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'PlatformToggle',
  watch: {
    platformOption(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitPlatform();
      }
    },
  },
  created() {
    this.emitPlatform(this.platform);
  },
  data() {
    return {
      platformOption: 0,
    };
  },
  computed: {
    platform() {
      return this.platformOption === 1 ? 'android' : 'ios';
    },
  },
  methods: {
    emitPlatform() {
      this.$emit('platformUpdate', this.platform);
    },
  },
};
</script>

<style scoped>

</style>

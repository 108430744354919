import _ from 'lodash';

const defaultState = {
  selectedStepIndex: 0,
  onboardingSteps: [
    {
      name: 'bookingChannel',
      label: 'onboarding.bookingChannel',
      componentName: 'booking-channel-step',
      icon: 'build',
      isDone: true,
      isSelected: true,
      stateKey: 'bookingChannel',
      isFirst: true,
    },
    {
      name: 'oidcClients',
      label: 'onboarding.oidcClients',
      componentName: 'oidc-clients-step',
      icon: 'phonelink',
      stateKey: 'oidcClients',
    },
    {
      name: 'fleetAssociation',
      label: 'onboarding.fleetAssociation.title',
      componentName: 'fleet-association-step',
      icon: 'note_add',
      stateKey: 'bookingChannel',
    },
    {
      name: 'webhooks',
      label: 'onboarding.webhooks',
      componentName: 'webhooks-step',
      icon: 'assignment_return',
      stateKey: 'webhooks',
    },
    {
      name: 'geoConnector',
      label: 'onboarding.geoConnector',
      componentName: 'connector-step',
      icon: 'pin_drop',
      type: 'geo',
      stateKey: 'geoConnector',
    },
    {
      name: 'paymentConnector',
      label: 'onboarding.paymentConnector',
      componentName: 'connector-step',
      icon: 'credit_card',
      type: 'payment',
      stateKey: 'paymentConnector',
    },
    {
      name: 'userConnector',
      label: 'onboarding.userConnector',
      componentName: 'connector-step',
      icon: 'account_circle',
      type: 'user',
      stateKey: 'userConnector',
    },
    {
      name: 'notification',
      label: 'onboarding.notification',
      componentName: 'notification-setup-step',
      icon: 'message',
      type: 'notification',
      stateKey: 'pushNotification',
    },
    {
      name: 'businessSolution',
      label: 'onboarding.businessSolution.step',
      componentName: 'business-solution-step',
      icon: 'business',
      stateKey: 'businessSolution',
    },
    {
      name: 'customerConsole',
      label: 'onboarding.customerConsole.step',
      componentName: 'console-admin-step',
      icon: 'trending_up',
      stateKey: 'customerConsole',
      product: 'customer',
    },
    {
      name: 'marketingConsole',
      label: 'onboarding.marketingConsole.step',
      componentName: 'console-admin-step',
      icon: 'timeline',
      stateKey: 'marketingConsole',
      product: 'marketing',
    },
    {
      name: 'features',
      label: 'onboarding.features',
      componentName: 'features-step',
      icon: 'flag',
      stateKey: 'features',
    },
    {
      name: 'appSigning',
      label: 'onboarding.appSigning',
      componentName: 'app-signing-step',
      icon: 'phonelink_lock',
      stateKey: 'appSigning',
    },
    {
      name: 'appPreview',
      label: 'onboarding.appPreview',
      componentName: 'appPreview-step',
      icon: 'stay_current_portrait',
      stateKey: 'appPreview',
    },
    {
      name: 'waitingTimes',
      label: 'onboarding.waitingTime.title',
      componentName: 'waitingTimes-step',
      icon: 'hourglass_full',
    },
    {
      name: 'paymentMethods',
      label: 'onboarding.paymentMethods.title',
      componentName: 'payment-methods-step',
      icon: 'account_balance_wallet',
      isLast: true,
      stateKey: 'allowedPaymentMethods',
    },
  ],
};

const getters = {
  onboardingSteps: state => state.onboardingSteps,
};

const actions = {
  setSelection(context, { selectedStep }) {
    context.commit('setSelection', selectedStep);
  },
  setNext({ state, commit }) {
    commit('setSelection', state.onboardingSteps[state.selectedStepIndex + 1]);
  },
  setPrevious({ state, commit }) {
    commit('setSelection', state.onboardingSteps[state.selectedStepIndex - 1]);
  },
};

const mutations = {
  setSelection(state, selectedStep) {
    let afterDone = false;
    state.onboardingSteps = _.map(state.onboardingSteps, (step, index) => {
      const isDone = !afterDone;
      const isSelected = (step.name === selectedStep.name);
      if (isSelected) {
        state.selectedStepIndex = index;
        afterDone = true;
      }
      return Object.assign({}, step, { isDone, isSelected });
    });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';
import { BookingChannelUtil } from '@/util/bookingChannelUtil';
import _ from 'lodash';

const defaultState = {
  list: [],
  listBookingChannelInProgress: false,
  onboardingApiServiceClient: null,
};

const getters = {
  list: state => state.list,
  listBookingChannelInProgress: state => state.listBookingChannelInProgress,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async getBookingChannelById(context, id) {
    try {
      const { data, headers } = await context.getters.onboardingApiServiceClient(context)
        .get(`/booking-channels/${id}/internal`);

      const useGoogleAddressSearch = _.get(data,
        'addressSearchConfiguration.useGoogleAddressSearch', true);
      const addressSearchConfiguration = {
        ...data.addressSearchConfiguration,
        useGoogleAddressSearch,
      };
      data.addressSearchConfiguration = addressSearchConfiguration;

      return {
        bookingChannel: data,
        etag: headers.ETag,
      };
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.listBookingChannelError', error },
        { root: true },
      );
      throw error;
    }
  },
  async getBookingChannels(context, { dynamicSearch, page, maxPerPage }) {
    const params = {
      page,
      maxPerPage,
      dynamicSearch,
      context: 'internal',
    };
    try {
      const { data, headers } = await context.getters.onboardingApiServiceClient(context)
        .get('/booking-channels', {
          params,
        });
      return {
        bookingChannels: data,
        totalBookingChannels: parseInt(headers['x-total-count'], 0),
      };
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.listBookingChannelError', error },
        { root: true },
      );
      throw error;
    }
  },
  async createBookingChannel(context, payload) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .post('/booking-channel', BookingChannelUtil.cleanBookingChannel(payload));
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.createBookingChannelError', error },
        { root: true },
      );
      throw error;
    }
  },
  async updateBookingChannel(context, { payload }) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .put('/booking-channel', payload);
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.updateBookingChannelError', error },
        { root: true },
      );
      throw error;
    }
  },
  async list(context, dynamicSearch) {
    try {
      context.commit('setListBookingChannelInProgress', true);
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/booking-channels?dynamicSearch=${dynamicSearch}&page=0&context=services`);
      context.commit('setBookingChannelList', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.listBookingChannelError', error },
        { root: true },
      );
    } finally {
      context.commit('setListBookingChannelInProgress', false);
    }
  },

  async listByFleet(context, fleetId) {
    try {
      context.commit('setListBookingChannelInProgress', true);
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/booking-channels?fleetId=${fleetId}&page=0&context=services`);
      context.commit('setBookingChannelList', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.listBookingChannelError', error },
        { root: true },
      );
    } finally {
      context.commit('setListBookingChannelInProgress', false);
    }
  },
  async updateBookingChannelIosLiveActivity(context, { payload }) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .put('/booking-channel/ios-live-activity', payload);
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.updateBookingChannelError', error },
        { root: true },
      );
      throw error;
    }
  },
};

const mutations = {
  setBookingChannelList(state, bookingChannels) {
    state.list = bookingChannels;
  },
  setListBookingChannelInProgress(state, listBookingChannelInProgress) {
    state.listBookingChannelInProgress = listBookingChannelInProgress;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

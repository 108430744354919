<template>
  <div>
    <v-dialog :value="value" @input="$emit('input')" persistent width="90%">
      <v-card>
        <v-card-title>
          <span class="headline">Сustomer data</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Property</th>
                  <th class="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, name) in customer" :key="name">
                  <td>{{$t(`customerDataForm.${name}`)}}</td>
                  <td>
                    <span v-if="name==='logoUrl'">
                      <v-img width="80" contain="true" :src="value"/>
                    </span>
                    <span v-else>{{value}}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click.native="$emit('input')">
            {{$t('customerDataForm.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'customer-data-get-item',
  props: ['value', 'customer'],
};
</script>

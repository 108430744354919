<template>
  <v-dialog
    max-width="500"
    v-if="display"
    :value="true"
    persistent
  >
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">{{ (network && network.id) ? 'Edit' : 'Create' }}</span>
      </v-card-title>
      <v-card-text style="height: 100%; overflow-y: hidden;">
        <v-row>
          <v-col>
            <validated-component
              name="name"
              v-model="network.name"
              :validator="$v.network.name">
              <v-text-field
                v-model="network.name"
                :label="$t('networkDialog.name')"
                :placeholder="$t('networkDialog.name')"
                outlined
                hide-details
                no-filter
              ></v-text-field>
            </validated-component>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validated-component
              name="paymentInstance"
              v-model="network.paymentInstance"
              :validator="$v.network.paymentInstance">
              <v-select
                v-model="network.paymentInstance"
                :items="paymentInstances"
                :label="$t(`networkDialog.paymentInstance`)"
                :placeholder="$t('networkDialog.paymentInstance')"
                outlined
                no-filter
                hide-details
                :readonly="(network.id) ? true : false"
              ></v-select>
            </validated-component>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="dismiss">
          Close
        </v-btn>
        <v-btn color="success" @click="save" :disabled="$v.$invalid">
          {{ (network && network.id) ? 'Edit' : 'Create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import networkValidation from '../validationDatas/networkValidation';

export default {
  name: 'NetworkDialog',
  data() {
    return {
      paymentInstances: ['uk', 'canada', 'ireland', 'us', 'aus'],
    };
  },
  props: {
    network: {
      required: false,
    },
    display: {
      required: true,
    },
  },
  methods: {
    dismiss() {
      this.$emit('onDismiss');
    },
    save() {
      this.$emit('saveNetwork', this.network);
    },
  },
  validations() {
    return networkValidation;
  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <v-container>
    <Onboarding/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Onboarding from '@/components/onboarding/Onboarding.vue';

export default {
  name: 'onboarding-view',
  components: {
    Onboarding,
  },
};
</script>

import axios from 'axios';
import {
  pickBy, isNil, isNumber, isEmpty, isBoolean,
} from 'lodash';
import { Authentication } from '@icabbi/vue-authentication';

const mapPayload = (data) => {
  const payload = Object.assign({}, data);

  payload.longitude = !isNil(data.longitude) ? parseFloat(data.longitude) : null;
  payload.latitude = !isNil(data.latitude) ? parseFloat(data.latitude) : null;
  payload.defaultTipValue = !isNil(data.defaultTipValue) ? parseFloat(data.defaultTipValue) : null;
  payload.preAuthAmount = !isNil(data.preAuthAmount) ? parseInt(data.preAuthAmount, 10) : null;
  payload.iosProjectVersion = !isNil(data.iosProjectVersion) ? parseInt(data.iosProjectVersion, 10) : null;
  payload.androidVersionCode = !isNil(data.androidVersionCode) ? parseInt(data.androidVersionCode, 10) : null;
  payload.percentageMaxAmount = !isNil(data.percentageMaxAmount) ? parseInt(data.percentageMaxAmount, 10) : null;
  payload.currencyMaxAmount = !isNil(data.currencyMaxAmount) ? parseInt(data.currencyMaxAmount, 10) : null;

  payload.applePayEnabled = !isNil(data.applePayEnabled) ? Boolean(data.applePayEnabled) : null;
  payload.googlePayEnabled = !isNil(data.googlePayEnabled) ? Boolean(data.googlePayEnabled) : null;
  payload.cashEnabled = !isNil(data.cashEnabled) ? Boolean(data.cashEnabled) : null;
  payload.prebookingEnabled = !isNil(data.prebookingEnabled) ? Boolean(data.prebookingEnabled) : null;
  payload.businessLoginEnabled = !isNil(data.businessLoginEnabled) ? Boolean(data.businessLoginEnabled) : null;
  payload.personalLoginEnabled = !isNil(data.personalLoginEnabled) ? Boolean(data.personalLoginEnabled) : null;
  payload.firstInstallPromptEnabled = !isNil(data.firstInstallPromptEnabled) ? Boolean(data.firstInstallPromptEnabled) : null;
  payload.isGooglePayInProduction = !isNil(data.isGooglePayInProduction) ? Boolean(data.isGooglePayInProduction) : null;
  payload.promoCodeEnabled = !isNil(data.promoCodeEnabled) ? Boolean(data.promoCodeEnabled) : null;

  return pickBy(payload, val => isNumber(val) || isBoolean(val) || !isEmpty(val));
};

const defaultState = {
  list: [],
  customer: false,
  customerDataApiServiceClient: null,
  customerDataListingInProgress: false,
  importData: {},
};

const getters = {
  customers: state => state.list.data,
  count: state => state.list.count,
  total: state => state.list.total,
  cursor: state => state.list.cursor,
  customer: state => state.customer,
  importData: state => state.importData,
  customerDataListingInProgress: state => state.customerDataListingInProgress,
  customerDataApiServiceClient: (
    state,
    contextGetters,
    rootState,
    rootGetters,
  ) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.customerDataApiServiceClient === null) {
      state.customerDataApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.customerDataApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.customerDataApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.customerDataApiServiceClient;
  },
};

const actions = {
  async getJotFormList(context, { cursor }) {
    try {
      const { data } = await context.getters.customerDataApiServiceClient(context).get(`/customer-data?limit=5000&cursor=${cursor || ''}`);
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.listError', error },
        { root: true },
      );
      throw error;
    }
  },
  async list(context, { cursor, humanReadableId }) {
    try {
      context.commit('setCustomerDataListingInProgress', true);
      const { data } = await context.getters.customerDataApiServiceClient(context).get(`/customer-data?limit=5000&cursor=${cursor || ''}&humanReadableId=${humanReadableId || ''}`);
      context.commit('setCustomerList', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.listError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', false);
    }
  },
  async item(context, id) {
    try {
      context.commit('setCustomerDataListingInProgress', true);
      const { data } = await context.getters.customerDataApiServiceClient(context).get(`/customer-data/${id}`);
      context.commit('setCustomer', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.listError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', false);
    }
  },
  async getById(context, id) {
    const { data } = await context.getters.customerDataApiServiceClient(context).get(`/customer-data/${id}`);
    return data;
  },
  async getByIdMapped(context, id) {
    const { data } = await context.getters.customerDataApiServiceClient(context).get(`/customer-data/${id}/mapped`);
    return data;
  },
  async save(context, data) {
    try {
      context.commit('setCustomerDataListingInProgress', true);
      const formData = new FormData();
      const payload = mapPayload(data);
      Object.keys(payload).forEach(key => formData.append(key, payload[key]));
      await context.getters.customerDataApiServiceClient(context).post(
        '/customer-data',
        formData,
        {
          headers: { 'Content-type': 'multipart/form-data' },
        },
      );
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'customerData.createCustomerSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.createCustomerError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', true);
    }
  },
  async edit(context, data) {
    try {
      context.commit('setCustomerDataListingInProgress', true);
      const formData = new FormData();
      const payload = mapPayload(data);

      Object.keys(payload).forEach(key => formData.append(key, payload[key]));
      await context.getters.customerDataApiServiceClient(context).put(
        `/customer-data/${data.id}`,
        formData,
        {
          headers: { 'Content-type': 'multipart/form-data' },
        },
      );
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'customerData.updateCustomerSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.updateCustomerError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', false);
    }
  },
  async import(context, { id, importData }) {
    try {
      context.commit('setCustomerDataListingInProgress', true);
      const payload = mapPayload(importData);
      await context.getters.customerDataApiServiceClient(context).patch(`/customer-data/${id}/import`, payload);
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'customerData.updateCustomerSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.updateCustomerError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', false);
    }
  },
  async getImportData(context, id) {
    try {
      context.commit('setImportData', {});
      context.commit('setCustomerDataListingInProgress', true);
      const { data } = await context.getters.customerDataApiServiceClient(context).get(
        `/customer-data/${id}/import`,
      );
      context.commit('setImportData', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'customerData.getImportDataError', error },
        { root: true },
      );
    } finally {
      context.commit('setCustomerDataListingInProgress', false);
    }
  },
  // TODO uncomment later
  // async delete(context, id) {
  //  try {
  //    await context.getters.customerDataApiServiceClient(context).delete(`/customer-data/${id}`);
  //    context.dispatch(
  //      'globalMessages/addSuccess',
  //      { message: 'customerData.deleteCustomerSuccess' },
  //      { root: true },
  //    );
  //  } catch (error) {
  //    context.dispatch(
  //      'globalMessages/addError',
  //      { message: 'customerData.deleteCustomerError', error },
  //      { root: true },
  //    );
  //  }
  // },
};

const mutations = {
  setCustomerList(state, data) {
    state.list = data;
  },
  setCustomer(state, data) {
    state.customer = data;
  },
  setCustomerDataListingInProgress(state, inProgress) {
    state.customerDataListingInProgress = inProgress;
  },
  setImportData(state, data) {
    state.importData = data;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VJsoneditor from 'v-jsoneditor';
import VuelidateErrorExtractor from 'vuelidate-error-extractor';
import VueHeadful from 'vue-headful';
import Clipboard from 'v-clipboard';
import '@icabbi/vue-components';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import { Icon } from 'leaflet';
import JsonViewer from 'vue-json-viewer';
import {
  LMap, LTileLayer, LMarker, LCircle,
} from 'vue2-leaflet';

import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import '@icabbi/vue-components/dist/vue-components.css';
import 'vue-swatches/dist/vue-swatches.css';
import App from './App.vue';
import router from './router';
import store from './store';
import i18nAttributes from './i18nAttributes';

import Autocomplete from './components/elements/Autocomplete.vue';
import LargeButton from './components/elements/LargeButton.vue';
import ErrorPage from './components/elements/ErrorPage.vue';
import AlertSnackbar from './components/elements/AlertSnackbar.vue';

import 'leaflet/dist/leaflet.css';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl; // eslint-disable-line

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'), // eslint-disable-line
  iconUrl: require('leaflet/dist/images/marker-icon.png'), // eslint-disable-line
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'), // eslint-disable-line
});

Vue.use(VueMoment, { moment });
Vue.use(Clipboard);
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: 'validations',
  i18nAttributes,
});
Vue.use(JsonViewer);
Vue.use(VJsoneditor);
Vue.config.productionTip = false;

Vue.component('vue-headful', VueHeadful);
Vue.component('large-button', LargeButton);
Vue.component('error-page', ErrorPage);
Vue.component('alert-snackbar', AlertSnackbar);
Vue.component('autocomplete', Autocomplete);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');

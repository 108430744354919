<template>
  <user-step
    :step="step"
    :statePart="statePart"
    :in-progress="businessSolutionInProgress"
    @createUser="createBookingChannelAdmin">
  </user-step>
</template>

<script>
import { mapGetters } from 'vuex';
import UserStep from './UserStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'business-solution-step',
  components: {
    UserStep,
  },
  mixins: [OnboardingStepMixin],
  props: {
    bookingChannelId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      businessSolutionInProgress: 'onboarding/businessSolutionInProgress',
    }),
  },
  methods: {
    async createBookingChannelAdmin(user) {
      await this.$store.dispatch('onboarding/createBookingChannelAdmin', {
        ...user,
        bookingChannelId: this.bookingChannelId,
      });
      this.$emit('component-updated');
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>

<template>
  <v-container>
    <financial-settings-create
      :visible="showModal"
      @created="updateComponent"
      @close="closeModal"
    ></financial-settings-create>
    <v-row>
      <v-col>
        <v-label>{{ $t('financialSettings.fleet' )}}</v-label>
        <autocomplete
          :label="$t('financialSettings.fleet')"
          :loading='listFleetInProgress'
          :items='fleetList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('fleetManagementForm.autocomplete')"
          @searchAutocomplete='listFleets'
          @updateModel='setFleet'
        ></autocomplete>
      </v-col>
      <v-col>
        <v-label>{{ $t('financialSettings.bookingChannel') }}</v-label>
        <autocomplete
          :label="$t('financialSettings.bookingChannel')"
          :loading='listBookingChannelInProgress'
          :items='bookingChannelList'
          itemsName='humanReadableId'
          outlined
          hide-details
          no-filter
          :placeholder="$t('fleetManagementForm.autocomplete')"
          @searchAutocomplete='bookingChannelList'
          @updateModel='setBookingChannel'
          :disabled='!fleet'
        ></autocomplete>
      </v-col>
    </v-row>
    <hr/>
    <v-row>
      <v-col cols="11">
      </v-col>
      <v-col cols="1">
        <v-btn
          color="primary"
          @click="add"
          :disabled='!bookingChannel'
        >
          <v-icon>addchart</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row :if="financeData">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="financeData"
          hide-default-footer
          :multi-sort="false"
          item-key="id"
          show-expand
          single-expand
          :must-sort="true"
          :footer-props="{ showFirstLastPage: true }"
        >
          <template v-slot:item.startDate="{ item }">
            <span>{{ formatDate(item.startDate) }}</span>
          </template>
          <template v-slot:item.endDate="{ item }">
            <span>{{ formatDate(item.endDate) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-data-table
                :headers="bookingFeesHeaders"
                :items="item.fees.booking"
                hide-default-footer
                :multi-sort="false"
                :must-sort="true"
                :footer-props="{ showFirstLastPage: true }"
              >
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from '../../plugins/dayjs';

import FinancialSettingsCreate from './FinancialSettingsCreate.vue';

export default {
  name: 'financial-settings',
  components: { FinancialSettingsCreate },
  mounted() {
    this.$store.dispatch('fleets/list', '');
  },
  data() {
    return {
      fleet: null,
      bookingChannel: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      fleetList: 'fleets/list',
      bookingChannelList: 'bookingChannels/list',
      listFleetInProgress: 'fleets/listFleetInProgress',
      listBookingChannelInProgress: 'bookingChannels/listBookingChannelInProgress',
      selectedConfigurations: 'fleets/selectedFleetBookingChannelConfig',
    }),
    financeData() {
      if (!this.bookingChannel) {
        return [];
      }

      if (this.selectedConfigurations && this.selectedConfigurations.bookingChannelConfig) {
        let id = 0;
        return this.selectedConfigurations.bookingChannelConfig.finance.map(entry => ({
          companyName: this.selectedConfigurations.companyName,
          humanReadableId: this.selectedConfigurations.humanReadableId,
          country: this.bookingChannel.defaultCountry.toUpperCase(),
          currency: this.selectedConfigurations.currency,
          ...entry,
          // eslint-disable-next-line no-plusplus
          id: id++,
        }));
      }
      return [];
    },
    bookingFeesHeaders() {
      return [
        {
          text: this.$t('financialSettings.from'),
          align: 'left',
          sortable: true,
          value: 'minimumCount',
        },
        {
          text: this.$t('financialSettings.to'),
          align: 'left',
          sortable: true,
          value: 'maximumCount',
        },
        {
          text: this.$t('financialSettings.cost'),
          align: 'left',
          sortable: true,
          value: 'amount',
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t('financialSettings.company'),
          align: 'left',
          sortable: true,
          value: 'companyName',
        },
        {
          text: this.$t('financialSettings.country'),
          align: 'left',
          sortable: true,
          value: 'country',
        },
        {
          text: this.$t('financialSettings.uid'),
          align: 'left',
          sortable: true,
          value: 'uid',
        },
        {
          text: this.$t('financialSettings.fleetName'),
          align: 'left',
          sortable: true,
          value: 'humanReadableId',
        },
        {
          text: this.$t('financialSettings.currency'),
          align: 'left',
          sortable: true,
          value: 'currency',
        },
        {
          text: this.$t('financialSettings.startDate'),
          align: 'left',
          sortable: true,
          value: 'startDate',
        },
        {
          text: this.$t('financialSettings.endDate'),
          align: 'left',
          sortable: true,
          value: 'endDate',
        },
        {
          text: this.$t('financialSettings.businessBookingAmount'),
          align: 'left',
          sortable: true,
          value: 'fees.businessBookingAmount',
        },
        { text: '', value: 'data-table-expand' },
      ];
    },
  },
  watch: {
    fleet(val) {
      this.$store.dispatch('fleets/resetFleetWithBookingChannelConfig');
      this.$store.dispatch('bookingChannels/listByFleet', val.id);
    },
    bookingChannel(val) {
      this.$store.dispatch('fleets/getFleetWithBookingChannelConfig', { fleetId: this.fleet.id, bookingChannelId: val.id });
    },
  },
  methods: {
    listFleets(dynamicSearch) {
      this.$store.dispatch('fleets/list', dynamicSearch);
    },
    setFleet(fleet) {
      this.fleet = fleet;
    },
    setBookingChannel(bookingChannel) {
      this.bookingChannel = bookingChannel;
    },
    formatDate(date) {
      if (!date) return null;
      return dayjs(date).utc().format('YYYY-MM-DD HH:mm');
    },
    add() {
      this.showModal = true;
    },
    async updateComponent(settings) {
      const data = {
        ...settings,
      };

      if (settings.startDate) {
        data.startDate = `${data.startDate}T00:00:00Z`;
      }

      if (settings.endDate) {
        data.endDate = `${data.endDate}T11:59:59Z`;
      } else {
        delete data.endDate;
      }

      data.fees.businessBookingAmount = parseFloat(data.fees.businessBookingAmount);

      const bookings = settings.fees.booking || [];
      data.fees.booking = bookings.map((booking) => {
        const mapped = {
          ...booking,
        };

        mapped.minimumCount = parseInt(mapped.minimumCount, 10);

        if (!mapped.maximumCount) {
          delete mapped.maximumCount;
        } else {
          mapped.maximumCount = parseInt(mapped.maximumCount, 10);
        }

        mapped.amount = parseFloat(mapped.amount);

        return mapped;
      });

      await this.$store.dispatch('fleets/saveSettings', { fleetId: this.fleet.id, bookingChannelId: this.bookingChannel.id, settings: data });

      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>

</style>

import dayjs from 'dayjs';

import {
  minLength, maxLength, required, decimal, minValue,
} from 'vuelidate/lib/validators';

import { isValidString, isValidDate } from '../../validation/validationMethods';

export default {
  settings: {
    startDate: {
      isValidDate,
      required,
    },
    endDate: {
      isAfter: (endDate, settings) => {
        if (!endDate) return true;

        const start = dayjs(settings.startDate);
        const end = dayjs(settings.endDate);

        return end.isAfter(start);
      },
    },
    fees: {
      businessBookingAmount: {
        minValue: minValue(0),
        decimal,
        required,
      },
    },
    uid: {
      minLength: minLength(1),
      maxLength: maxLength(50),
      isValidString,
      required,
    },
  },
};

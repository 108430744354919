<template>
  <v-container>
    <h3>{{ $t(step.label) }}</h3>
    <hr class="title-divider"/>
    <v-row class="step-content">
      <v-col cols="12">
        <slot></slot>
      </v-col>
    </v-row>
    <v-row
      :class="{
      'justify-space-between': !step.isFirst && !step.isLast,
      'justify-end': step.isFirst
    }">
      <v-btn v-if="!step.isFirst" color="default" @click="emitPrevious">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <v-btn v-if="!step.isLast" color="default" @click="emitNext">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>
<style scoped>
</style>
<script>

export default {
  name: 'OnboardingStep',
  props: {
    step: {
      required: true,
      type: Object,
    },
  },
  computed: {
    stepIcon() {
      return !this.step.inconsistent ? '$complete' : 'mdi-help';
    },
    stepColor() {
      return !this.step.inconsistent ? 'success' : 'warning';
    },
    isComplete() {
      return this.step.completed || this.step.inconsistent;
    },
  },
  methods: {
    emitNext() {
      this.$parent.$emit('set-next');
    },
    emitPrevious() {
      this.$parent.$emit('set-previous');
    },
  },
};
</script>

<style scoped>
  .title-divider {
    margin: 10px 0 20px;
    border: 0;
    height: 0;
    border-top: 3px solid black;
  }
  .step-content {
    min-height: 300px;
  }
</style>
